import { apiBase } from '../lib'
import { request } from '../lib/fetch'
import { ExtractionStructure, Extraction } from '../typings/app/types'
import { ListStructuresResponseJson, Structure } from '../typings/api/types'
import { filterIsDefined } from '../util'

async function fetchStructures(
  jobId: string,
  accessToken: string
): Promise<{ response?: ExtractionStructure[]; error?: Error }> {
  const path = `${apiBase}/job/${jobId}/structure`

  try {
    const { response, body, error } = await request<ListStructuresResponseJson>(
      path,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )

    if (response.ok) {
      const structures: ExtractionStructure[] =
        body?.structures?.map((s: Structure) => {
          return {
            ...s,
            internalId: `${jobId}:::${s.structureId}`,
            deleted: false,
            deletePending: false,
          }
        }) || []
      if (structures.length) {
        return { response: structures, error: undefined }
      }
    }

    if (error) {
      throw error
    }

    return { response: [], error: undefined }
  } catch (error) {
    return { response: undefined, error: error }
  }
}

export async function deleteStructure(
  accessToken: string,
  jobId: string,
  structureId: string
): Promise<{ response: boolean; error: Error | null }> {
  const path = `${apiBase}/job/${jobId}/structure/${structureId}`

  try {
    const { response, error } = await request<any>(path, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${accessToken}` },
    })

    return { response: response.ok, error: error }
  } catch (err) {
    return { response: false, error: err }
  }
}

function jobIdForStructure(structure: ExtractionStructure) {
  return structure.internalId.split(':::')[0]
}

function jobIdForStructureId(internalStructureId: string) {
  return internalStructureId.split(':::')[0]
}

function structureForId(
  internalStructureId: string,
  extractions: Extraction[]
): ExtractionStructure | undefined {
  const jobId = jobIdForStructureId(internalStructureId)
  const extraction = extractions.find((x) => x.jobId === jobId)

  if (!extraction) return undefined
  return extraction.structures?.find(
    (x) => x.internalId === internalStructureId
  )
}

function structuresForIds(
  internalStructureIds: string[],
  extractions: Extraction[]
): ExtractionStructure[] {
  return internalStructureIds
    .map((x) => structureForId(x, extractions))
    .filter(filterIsDefined)
}

export { fetchStructures, jobIdForStructure, structureForId, structuresForIds }
