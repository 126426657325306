import React, { useState, useRef, useEffect } from 'react'
import { useDebounce } from 'use-debounce'

import { ReactComponent as IconMagnifier } from '../../assets/static/images/icon-magnifier.svg'
import { ReactComponent as IconCross } from '../../assets/static/images/icon-cross-no-color.svg'

import './style.scss'

interface Props {
  className?: string
  onChange?: (search?: string) => void
}

const Searchbar: React.FC<Props> = ({ onChange, className = '' }) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)

  const [search] = useDebounce(searchTerm, 100, { maxWait: 100 })

  useEffect(() => {
    onChange && onChange(search || undefined)
  }, [search, onChange])

  return (
    <div
      className={`searchbar px-3 py-2 rounded-md border-solid border-transparent border transition duration-150 ease-in
       ${className}`}
      onClick={() => {
        inputRef.current?.focus()
      }}
    >
      <div className="flex items-center w-full h-full text-sm text-rich-blue-darkest">
        <IconMagnifier className="mr-3"></IconMagnifier>
        <input
          type="text"
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
          className="w-full bg-transparent placeholder-rich-blue-darkest focus:outline-none focus:placeholder-transparent"
          placeholder="Search..."
          ref={inputRef}
        ></input>
        {searchTerm && (
          <button
            className="flex items-center justify-center h-8 -my-4 opacity-75 focus:outline-none text-rich-blue-darkest hover:opacity-100"
            title="Clear Search"
            onClick={() => setSearchTerm('')}
          >
            <IconCross
              className="stroke-current"
              style={{ height: '0.8rem' }}
            ></IconCross>
          </button>
        )}
      </div>
    </div>
  )
}

export default Searchbar
