import React, { useState } from 'react'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

import {
  Extraction,
  ExtractionState,
  ExtractionStructure,
} from '../../../../typings/app/types'

import { ExtractionGridSectionHeader } from './ExtractionGridSectionHeader'

import { ExtractionGridItem } from './ExtractionGridItem'
import ExtractionGridItemProgress from './ExtractionGridItemProgress'
import {
  filterExtractionByTerm,
  filterStructureByTerm,
} from '../../../../services/serach'

interface Props {
  extraction: Extraction
  selectedStructures: ExtractionStructure[]
  selectedIncompleteExtraction?: boolean
  selectionChanged: (strucutres: ExtractionStructure[]) => void
  incompleteExtractionSelectionChanged: (selected: boolean) => void
  onStructureClick: (structure: ExtractionStructure, fileName: string) => void
  initialNumberOfStructures: number
  searchTerm?: string
  className?: string
}
const ExtractionGridItemContainer: React.FC<Props> = ({
  extraction,
  selectedStructures,
  selectedIncompleteExtraction,
  selectionChanged,
  incompleteExtractionSelectionChanged,
  onStructureClick,
  initialNumberOfStructures,
  searchTerm,
  className,
  ...props
}) => {
  const [showAllStructures, setShowAllStructures] = useState(false)
  const [totalNumberOfStructures, setTotalNumberOfStructures] = useState(0)

  const generateItems = () => {
    function generateClass(index: number) {
      return `w-full duration-500 delay-500 md:w-1/2 lg:w-1/3 xl:w-1/4 transition-bounds ${
        index < initialNumberOfStructures || showAllStructures ? '' : 'hidden'
      }`
    }

    const items: JSX.Element[] = []

    const filteredStructures = extraction.structures?.filter(
      (structure) =>
        filterStructureByTerm(structure, searchTerm) ||
        filterExtractionByTerm(extraction, searchTerm, true)
    )

    filteredStructures?.map((structure, index) => {
      const item = (
        <ExtractionGridItem
          key={`${extraction.jobId}--${structure.structureId}`}
          className={generateClass(items.length)}
          fileName={extraction.job?.file.name || ''}
          structure={structure}
          structureSequence={{
            index:
              (extraction.structures
                ?.filter((x) => x.page === structure.page)
                .findIndex((x) => x === structure) || 0) + 1,
            of:
              extraction.structures?.filter((x) => x.page === structure.page)
                .length || 0,
          }}
          selected={selectedStructures.includes(structure)}
          selectionChanged={(selected: boolean) => {
            if (selected) {
              selectionChanged([...selectedStructures, structure])
            } else {
              selectionChanged(
                selectedStructures.filter((x) => x !== structure)
              )
            }
          }}
          onClick={() =>
            onStructureClick(structure, extraction.job?.file.name || '')
          }
        ></ExtractionGridItem>
      )
      items.push(item)
    })

    // Hide pending items while a search is pending / active
    const pendingItems =
      !searchTerm && extraction.state !== ExtractionState.completed
        ? (extraction.job?.structures.total || 0) -
          (extraction.job?.structures.processed || 0)
        : 0

    for (let i = 0; i < pendingItems; i++) {
      const item = (
        <ExtractionGridItemProgress
          key={`${extraction.jobId}__${items.length + 1}_${
            extraction.job?.structures.total || 0
          }`}
          structureSequence={{
            index: items.length + 1,
            of: extraction.job?.structures.total || 0,
          }}
          className={generateClass(items.length)}
        ></ExtractionGridItemProgress>
      )

      items.push(item)
    }

    if (
      (filteredStructures?.length || 0) + pendingItems !==
      totalNumberOfStructures
    ) {
      setTotalNumberOfStructures(
        (filteredStructures?.length || 0) + pendingItems
      )
    }

    return items
  }

  return (
    <div {...props} data-test-id={extraction.jobId} className={`${className}`}>
      <ExtractionGridSectionHeader
        className="sticky top-0 left-0 z-10 h-12 px-8 -mx-4 bg-sensitive-grey"
        extraction={extraction}
        selected={
          (extraction.structures &&
            extraction.structures.length &&
            extraction.structures?.every((x) =>
              selectedStructures.includes(x)
            )) ||
          selectedIncompleteExtraction ||
          false
        }
        selectionChanged={(selected: boolean) => {
          if (extraction.structures && extraction.structures.length) {
            if (selected) {
              const updatedList = new Set([
                ...selectedStructures,
                ...(extraction.structures || []),
              ])

              selectionChanged([...updatedList])
            } else {
              selectionChanged(
                selectedStructures.filter(
                  (x) => !extraction.structures?.includes(x)
                )
              )
            }
          } else {
            incompleteExtractionSelectionChanged(selected)
          }
        }}
      ></ExtractionGridSectionHeader>
      <SlideDown
        className="overflow-visible duration-300"
        transitionOnAppear={true}
      >
        <div className="flex flex-wrap justify-start w-full">
          {generateItems()}
        </div>
      </SlideDown>
      <MoreStructuresButton
        className="pt-8 pb-8"
        initialNumberOfItems={initialNumberOfStructures}
        totalNumberOfItems={totalNumberOfStructures}
        isShowing={showAllStructures}
        onClick={() => setShowAllStructures(!showAllStructures)}
      ></MoreStructuresButton>
      <div className="pb-8"></div>
    </div>
  )
}

interface MoreStructuresButtonProps {
  initialNumberOfItems: number
  totalNumberOfItems: number
  isShowing: boolean
  onClick?: () => void
  className?: string
}

const MoreStructuresButton: React.FC<MoreStructuresButtonProps> = ({
  initialNumberOfItems,
  totalNumberOfItems,
  isShowing,
  onClick,
  className,
}) => {
  const diff = totalNumberOfItems - initialNumberOfItems

  return (
    <div className={`px-4 ${diff <= 0 ? 'hidden' : ''} ${className}`}>
      <button
        className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark active:text-vibrant-cyan-darker focus:outline-none"
        onClick={onClick}
      >
        {isShowing ? 'Hide ' + diff : 'Show ' + diff + ' more'} structure
        {diff > 1 ? 's' : ''}
      </button>
    </div>
  )
}

export { ExtractionGridItemContainer }
