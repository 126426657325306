import { useState, useEffect } from 'react'
import { Breakpoint } from '../../util'

export const mqMatches = (breakpoint: Breakpoint) => {
  const query = `(min-width: ${breakpoint}px)`
  const mediaQueryList: MediaQueryList = window.matchMedia(query)

  return Boolean(mediaQueryList.matches)
}

export const useMediaQuery = (
  breakpoint: Breakpoint
): [boolean, () => void] => {
  const query = `(min-width: ${breakpoint}px)`
  const mediaQueryList: MediaQueryList = window.matchMedia(query)

  const [match, setMatch] = useState<boolean>(mediaQueryList.matches)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    const onChange = () => {
      setMatch(Boolean(mediaQueryList.matches))
    }

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', onChange)
    } else {
      mediaQueryList.addListener(onChange)
    }

    setMatch(mediaQueryList.matches)

    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', onChange)
      } else {
        mediaQueryList.removeListener(onChange)
      }
    }
  }, [breakpoint, mediaQueryList])

  const refresh = () => {
    setMatch(mediaQueryList.matches)
  }

  return [match, refresh]
}
