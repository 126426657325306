import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../button/Button'

import { ReactComponent as IconPlus } from '../../../assets/static/images/icon-plus.svg'

interface Props {
  className?: string
}

const ProgressButton: React.FC<Props> = ({ className = '' }) => {
  return (
    <Link to="/upload">
      <Button
        className={`text-white bg-rich-blue hover:bg-rich-blue-darker active:bg-rich-blue-darkest ${className}`}
        icon={<IconPlus></IconPlus>}
      >
        <div className="">{`Upload more`}</div>
      </Button>
    </Link>
  )
}

export default ProgressButton
