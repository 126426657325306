import React from 'react'
import { LightBox } from '@liquid-design/liquid-design-react'
import { Logo } from '../logo'
import SurveyCloseButton from './components/SurveyCloseButton'
import { Form } from './forms/Form'

import './style.scss'
import { SurveyResponse } from '../../typings/app/types'

interface Props {
  open: boolean
  formName: string
  surveyId: string
  onSubmit: (surveyResponse: SurveyResponse) => void
  onClose: () => void
  className?: string
}

const SurveyForm: React.FC<Props> = ({
  open,
  formName,
  surveyId,
  onSubmit,
  onClose,
  className = '',
  ...props
}) => {
  return (
    <LightBox
      overlayAuxClassName="survey__form"
      reactModalProps={{ shouldCloseOnOverlayClick: false }}
      open={open}
      onClose={onClose}
      style={{
        maxWidth: '550px',
        width: '550px',
        maxHeight: 'calc(100vh - 40px)',
      }}
    >
      <div
        {...props}
        className={`pt-16 text-rich-blue-darkest max-h-full ${className}`}
      >
        <div className="absolute top-0 left-0 z-10 flex items-center w-full h-16 p-4 bg-rich-blue-darkest">
          <Logo className="text-white" showBeta={false}></Logo>
          <div className="absolute right-0">
            <SurveyCloseButton
              data-test="survey-form-button-close"
              onClick={onClose}
            ></SurveyCloseButton>
          </div>
        </div>
        <div
          className="max-h-full px-12 -mx-12 overflow-x-hidden overflow-y-auto"
          style={{ minHeight: '400px' }}
        >
          <Form
            formName={formName}
            surveyId={surveyId}
            onSubmit={onSubmit}
          ></Form>
        </div>
      </div>
    </LightBox>
  )
}

export default SurveyForm
