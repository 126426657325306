import React, { useState, useEffect } from 'react'
import { Checkbox } from '@liquid-design/liquid-design-react'
import { SurveyResponse } from '../../../typings/app/types'
import { LiquidButton, LoaderButton } from '../../buttons'
import { Link } from '../../../pages/Legal/components'

import {
  SurveyMultiSelect,
  SurveyMultiSelectOption,
  SurveyTextBox,
  SurveyValueSelect,
  SurveyValueSelectNPSOptions,
  SurveyValueSelectOption,
  SurveyIntro,
  SurveyIntroResult,
  SurveyRadioSelectOption,
  SurveyRadioSelect,
} from '../components'

import { FormProps } from './Form'

const availableFeatureRequestOptions: SurveyMultiSelectOption[] = [
  {
    id: 'features-enter-patent-number',
    label: 'Enter patent number instead of uploading documents',
    selected: false,
  },
  {
    id: 'features-model-updates-notification',
    label: 'Be notified of new Lewis Neural Network versions',
    selected: false,
  },
  {
    id: 'features-save-filters',
    label: 'Create and save filters to search database',
    selected: false,
  },
  {
    id: 'features-structure-highlight',
    label:
      'See a structure query highlighted as a colored substructure in search results',
    selected: false,
  },
  {
    id: 'features-edit-structures',
    label: 'Edit wrong entries or extractions using a chemical editor',
    selected: false,
  },
  {
    id: 'features-email-notification',
    label: 'Email confirmation in case extraction takes longer than 1 minute',
    selected: false,
  },
  {
    id: 'features-metadata',
    label: 'Metadata for extracted chemical structures',
    selected: false,
  },
]

const availableChallengeOptions: SurveyRadioSelectOption[] = [
  {
    id: 'challenge-effort-manually',
    label:
      'High effort to search manually for chemical structures in patents / journals',
  },
  {
    id: 'challenge-effort-unindexed',
    label:
      'High effort to find chemical structures in unindexed research papers / patents',
  },
  {
    id: 'challenge-discover',
    label:
      "Discover structures in patents that aren't explicitly visualized (Markush formula)",
  },
  {
    id: 'challenge-other',
    label: 'Other',
  },
]

const timeSavedOptions: SurveyValueSelectOption[] = [
  { value: 'hours-saved-0', label: '0h' },
  { value: 'hours-saved-1', label: '1-5h' },
  { value: 'hours-saved-2', label: '6-15h' },
  { value: 'hours-saved-3', label: '16-30h' },
  { value: 'hours-saved-4', label: '> 30h' },
]

const Survey1: React.FC<FormProps> = ({ sending, onSubmit }) => {
  const [introResults, setIntroResults] = useState<
    SurveyIntroResult | undefined
  >()
  const [nps, setNps] = useState<SurveyValueSelectOption | undefined>()
  const [recommendationReason, setRecommendationReason] = useState('')
  const [featureOptions, setFeatureOptions] = useState(
    availableFeatureRequestOptions
  )

  const [selectedChallengeOption, setSelectedChallengeOption] = useState<
    SurveyRadioSelectOption | undefined
  >()
  const [challengeOtherText, setChallengeOtherText] = useState<string>('')

  const [hoursSaved, setHoursSaved] = useState<
    SurveyValueSelectOption | undefined
  >()

  const [consentGiven, setConsentGiven] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)

  const [timeBegan, setTimeBegan] = useState<Date | undefined>()

  useEffect(() => {
    setTimeBegan(new Date())
  }, [])

  useEffect(() => {
    const valid =
      !introResults?.roleOption.id.includes('undefined') &&
      !introResults?.fieldOption.id.includes('undefined') &&
      !introResults?.goalOption.id.includes('undefined') &&
      Boolean(selectedChallengeOption) &&
      Boolean(nps) &&
      consentGiven

    setIsValid(valid)
  }, [introResults, nps, consentGiven, selectedChallengeOption])

  function handleSubmit() {
    const intro = [
      { name: 'role', value: introResults?.roleOption.id },
      { name: 'field', value: introResults?.fieldOption.id },
      { name: 'goal', value: introResults?.goalOption.id },
    ]

    let challenge = [{ name: 'challenge', value: selectedChallengeOption?.id }]
    if (selectedChallengeOption?.id.includes('other')) {
      challenge = [
        ...challenge,
        { name: 'challenge-other', value: challengeOtherText },
      ]
    }

    const response: SurveyResponse = {
      timeSpent: timeBegan
        ? Math.floor(Math.abs((+new Date() - +timeBegan) / 1000))
        : 0,
      values: [
        ...intro,
        ...challenge,
        { name: 'nps', value: nps ? nps.value : -1 },
        { name: 'recommendation-reason', value: recommendationReason },
        { name: 'hours-saved', value: hoursSaved?.value || -1 },
        {
          name: 'features',
          value: featureOptions.filter((x) => x.selected).map((x) => x.id),
        },
      ],
    }

    console.log('Survey', response)
    onSubmit(response)
  }

  return (
    <>
      <SurveyIntro
        className="mb-8"
        onChange={(results) => setIntroResults(results)}
      ></SurveyIntro>
      <SurveyRadioSelect
        className="w-full mb-8 "
        title="What is your major challenge? *"
        options={availableChallengeOptions}
        selectedOption={selectedChallengeOption}
        allowOtherText={true}
        otherText={challengeOtherText}
        onChange={(option) => {
          setSelectedChallengeOption(option)
        }}
        onOtherTextChange={(value) => setChallengeOtherText(value)}
      ></SurveyRadioSelect>
      <SurveyValueSelect
        className="w-full mb-12"
        title="How likely is it that you would recommend Lewis to a friend or colleague? *"
        options={SurveyValueSelectNPSOptions}
        selectedOption={nps}
        onChange={(value) => {
          setNps(value)
        }}
      ></SurveyValueSelect>
      <SurveyTextBox
        className="w-full mb-8"
        title="What are the reasons for your recommendation?"
        onChange={(value) => setRecommendationReason(value)}
        value={recommendationReason}
      ></SurveyTextBox>
      <SurveyValueSelect
        className="w-full mb-16"
        title="How much time (in hours) are you saving with Lewis per week? *"
        options={timeSavedOptions}
        selectedOption={hoursSaved}
        onChange={(value) => {
          setHoursSaved(value)
        }}
      ></SurveyValueSelect>
      <SurveyMultiSelect
        className="mb-12"
        title="Would you like Lewis to offer any of these functionalities?"
        options={featureOptions}
        onChange={(update) => {
          setFeatureOptions([...update])
        }}
      ></SurveyMultiSelect>
      <div className="flex justify-center mb-8">
        <div className="flex items-center">
          <Checkbox
            isChecked={consentGiven}
            onChange={() => setConsentGiven(!consentGiven)}
          ></Checkbox>
        </div>
        <div
          className="pl-2 text-sm font-extrabold cursor-pointer text-rich-blue-darkest"
          onClick={() => setConsentGiven(!consentGiven)}
        >
          I’m okay with Lewis team using this information to understand how
          Lewis should be developed (analytics purpose){' '}
          <Link to="/privacy" target="_blank">
            Privacy&nbsp;Policy
          </Link>
          &nbsp;*
        </div>
      </div>
      {isValid ? (
        <LoaderButton
          loading={sending}
          className="w-full mb-8 text-white bg-vibrant-cyan hover:bg-vibrant-cyan-dark active:bg-vibrant-cyan-darker"
          onClick={() => {
            if (sending) {
              return
            }

            handleSubmit()
          }}
        >
          Submit Survey
        </LoaderButton>
      ) : (
        <LiquidButton
          disabled={true}
          className="w-full mb-8"
          title="Please fill all mandatory (*) fields"
        >
          Submit Survey
        </LiquidButton>
      )}
      <div className="w-full text-sm font-extrabold">* mandatory</div>
    </>
  )
}

export default Survey1
