import React, { useState } from 'react'
import { Dropdown } from '@liquid-design/liquid-design-react'
import { Button } from '../../../components/buttons'

import { ReactComponent as DownloadIcon } from '../../../assets/static/images/icon-download.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/static/images/icon-bin.svg'

import {
  ExportOption,
  exportOptions,
  ExportType,
  exportTypeFromString,
} from '../../../services/export'

import './style.scss'
import { Loader } from '../../../components/loader'

interface Props {
  active: boolean
  exportPending: boolean
  onDelete?: () => void
  onExport?: (type: ExportType) => void
  className?: string
}

const Hoverbar: React.FC<Props> = ({
  active,
  exportPending,
  onDelete,
  onExport,
  className = '',
}) => {
  const [selectedExportOption, setSelectedExportOption] = useState<string>(
    exportOptions[0].id
  )

  const activeClassName = active
    ? 'opacity-100 scale-100 pointer-events-auto'
    : 'opacity-0 scale-75 pointer-events-none'

  return (
    <div
      className={`absolute flex items-center justify-center bottom-0 mb-12 w-full pointer-events-none z-30 ${className}`}
    >
      <div className="px-4">
        <div
          data-test="hoverbar"
          className={`hoverbar flex items-stretch bg-white rounded-md shadow-xl transform ${activeClassName}`}
        >
          <Button
            data-test="hoverbar-button-delete"
            className={`hoverbar__button-delete bg-white text-rich-red rounded-none rounded-l-md hover:bg-rich-red hover:text-white active:bg-rich-red-dark focus:outline-none`}
            icon={<DeleteIcon></DeleteIcon>}
            onClick={onDelete}
          >
            <div>Delete selected</div>
          </Button>
          <Button
            data-test="hoverbar-button-export"
            className={`bg-vibrant-cyan text-white rounded-none hover:bg-vibrant-cyan-dark active:bg-vibrant-cyan-darker focus:outline-none${
              exportPending ? ' pointer-events-none' : ''
            }`}
            icon={
              <DownloadIcon
                className={`${exportPending ? 'opacity-0' : 'opacity-100'}`}
              />
            }
            onClick={() => {
              onExport && onExport(exportTypeFromString(selectedExportOption))
            }}
          >
            <div className="relative">
              <div
                className={`absolute flex items-center justify-center top-0 left-0 w-full h-full ${
                  exportPending ? 'visible' : 'hidden'
                }`}
              >
                <div style={{ transform: 'translateX(-0.75rem)' }}>
                  <Loader className="" size="small" loading={true} />
                </div>
              </div>
              <div className={`${exportPending ? 'opacity-0' : 'opacity-100'}`}>
                Export Selection
              </div>
            </div>
          </Button>
          <Dropdown
            className=""
            label=""
            options={exportOptions}
            value={selectedExportOption}
            onSubmit={(value: ExportOption): void => {
              setSelectedExportOption(value.id)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Hoverbar
