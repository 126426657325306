import React, { createContext, useContext, useState } from 'react'
import { Notifications } from '@liquid-design/liquid-design-react'

export const NotificationContext = createContext<{
  info: (text: string) => void
  error: (text: string) => void
  reminder: (text: string) => void
}>({
  info: (text: string) => {
    throw new Error('Invalid execution')
  },
  error: (text: string) => {
    throw new Error('Invalid execution')
  },
  reminder: (text: string) => {
    throw new Error('Invalid execution')
  },
})

interface Props {
  children?: React.ReactNode
}

export const NotificationProvider: React.FC<Props> = ({ children }) => {
  const [notificationRef, setNotificationRef] = useState<any | null>()

  function info(text: string) {
    notificationRef?.addNotification({ text: text })
  }

  function error(text: string) {
    notificationRef?.addNotification({ text: text, isError: true })
  }

  function reminder(text: string) {
    notificationRef?.addNotification({ text: text, isReminder: true })
  }

  return (
    <NotificationContext.Provider
      value={{
        info: info,
        error: error,
        reminder: reminder,
      }}
      children={
        <div>
          {children}
          <Notifications
            ref={(value: any) => {
              setNotificationRef(value)
            }}
          ></Notifications>
        </div>
      }
    ></NotificationContext.Provider>
  )
}

export function useNotification() {
  return useContext(NotificationContext)
}
