import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@liquid-design/liquid-design-react'

import HomeLayout from '../../layouts/HomeLayout'

import ImageScreenshot from '../../assets/static/images/home/screenshot_1.png'
import ImageCard from '../../assets/static/images/home/screenshot_2.png'

import { ReactComponent as IconArrowRight } from '../../assets/static/images/icon-arrow-right.svg'

import FeatureTimeImage from '../../assets/static/images/home/features/feature-save-time.png'
import FeatureStructureImage from '../../assets/static/images/home/features/feature-structured-data.png'
import ExpertReduceError from '../../assets/static/images/home/expert/expert-reduce-error.png'
import ExpertEnhanceRobustness from '../../assets/static/images/home/expert/expert-enhance-robustness.png'

import IllustrationBetaCenter from '../../assets/static/images/home/beta/beta-center.png'
import IllustrationBetaLeft from '../../assets/static/images/home/beta/beta-left.png'
import IllustrationBetaRight from '../../assets/static/images/home/beta/beta-right.png'
import IllustrationScience from '../../assets/static/images/home/illustration-science.png'
import IllustrationExtraction from '../../assets/static/images/home/illustration-extraction.png'
import IllustrationCommunication from '../../assets/static/images/home/illustration-communication.png'

import { ReactComponent as IllustrationBurner } from '../../assets/static/images/home/illustration-burner.svg'

import { ScrollPosition } from '../../components/hooks/useScrollPosition'
import {
  BetaItem,
  FeatureCard,
  ImageAndText,
  SectionHeader,
} from './components'

import './style.scss'
import { Link as InlineLink } from '../Legal/components'

interface Props {}

const Home: React.FC<Props> = () => {
  const [scrollOffset, setScrollOffset] = useState<number>(0)
  const handleScroll = (position: ScrollPosition) => {
    setScrollOffset(position.y)
  }

  return (
    <HomeLayout onScroll={handleScroll}>
      <div className="flex flex-col flex-wrap items-center justify-center w-full pt-4 home">
        <div className="flex flex-col-reverse items-start w-full px-4 mb-24 sm:flex-row home__intro">
          <div className="w-full pr-4 sm:w-1/2">
            <div className="mb-4 text-rich-blue-darkest">
              <h1 className="text-3xl font-extrabold">
                Automate Chemical Data&nbsp;Extraction
              </h1>
            </div>
            <div className="pr-8 mb-8 text-xl leading-6 text-rich-blue-darker">
              Generate machine readable molecular structures from images and
              documents with high accuracy
            </div>
            <div className="mb-3">
              <Link to="/database">
                <Button>
                  <div className="flex">
                    <div className="mt-px mr-2 text-base">
                      Convert your first molecule
                    </div>
                    <IconArrowRight className="-mr-4"></IconArrowRight>
                  </div>
                </Button>
              </Link>
            </div>
            <div className="text-sm opacity-75 text-rich-blue-darkest">
              Free for a limited time
            </div>
          </div>
          <div className="relative w-full pr-4 mb-20 sm:w-1/2 sm:mb-0">
            <img
              className="card card-active"
              src={ImageScreenshot}
              alt="Screenshot of Lewis"
              style={{
                transform: `translate(0px, ${0 - scrollOffset / 12}px)`,
              }}
            ></img>
            <img
              className="absolute mr-2 sm:mr-0 card card-active"
              src={ImageCard}
              alt="Screenshot of Card"
              style={{
                top: '3.75rem',
                right: '0rem',
                width: '62%',
                transform: `translate(10px, ${0 - scrollOffset / 6}px)`,
              }}
            ></img>
          </div>
        </div>
        <div className="mb-16 home__features">
          <SectionHeader
            title="Features"
            subtitle="What Lewis offers to you"
            className="mb-4"
          ></SectionHeader>
          <div className="flex flex-col sm:flex-row">
            <div className="w-full px-4 py-4 sm:w-1/2">
              <FeatureCard
                title="Save Time"
                titleColor="text-vibrant-cyan"
                image={FeatureTimeImage}
                className="min-h-full"
              >
                <div>
                  Simply upload individual images
                  <br />
                  or PDF documents and Lewis will do the&nbsp;rest
                </div>
              </FeatureCard>
            </div>
            <div className="w-full px-4 py-4 sm:w-1/2">
              <FeatureCard
                title="Use Structured Data"
                titleColor="text-rich-blue"
                image={FeatureStructureImage}
                className="min-h-full"
              >
                <div>
                  <div className="mb-2">
                    Export molecular structures in multiple file formats like:
                    csv, sdf, smiles
                  </div>
                  <div>Import datasets to tools of your choice</div>
                </div>
              </FeatureCard>
            </div>
          </div>
        </div>
        <div className="w-full px-4 mb-24 home__expert-tool">
          <SectionHeader
            title="Built for Experts"
            subtitle="Expert Tool"
            align="left"
            className="mb-8 ml-2"
          ></SectionHeader>
          <div className="flex flex-col sm:flex-row">
            <div className="w-full px-4 py-4 sm:w-1/2">
              <ImageAndText
                title="Reduced Error rate"
                image={ExpertReduceError}
                className="min-h-full"
              >
                Lewis relies on automated rule generation by combining diverse
                Deep Neural Network Architectures, offering higher accuracy
                compared to methods using handwritten rules.
              </ImageAndText>
            </div>
            <div className="w-full px-4 py-4 sm:w-1/2">
              <ImageAndText
                title="Recognition Versatility"
                image={ExpertEnhanceRobustness}
                className="min-h-full"
              >
                Lewis is trained to recognize a wide range of chemical drawing
                styles, image quality and representation types.
              </ImageAndText>
            </div>
          </div>
        </div>
        <div className="flex justify-center mb-16 -mx-8 home__start-cta">
          <div className="px-4">
            <div
              className="relative flex items-center w-full px-8 py-8 text-white rounded-lg bg-rich-blue-darkest"
              style={{ minHeight: '9.25rem' }}
            >
              <div className="flex flex-col items-start pr-24 sm:items-center sm:flex-row">
                <div className="flex-shrink-1">
                  <div className="pr-4 mb-2 text-2xl font-extrabold">
                    Designed for anybody who deals with chemical data
                  </div>
                  <div className="pr-16 mb-4 text-lg sm:mb-0">
                    Upload a document, download and share the generated chemical
                    library with others.
                  </div>
                </div>
                <div
                  className="pl-0 mr-4 sm:pl-2"
                  style={{ minWidth: '12rem' }}
                >
                  <Link to="/database">
                    <Button
                      style={{
                        overflow: 'hidden',
                        maxWidth: '100%',
                      }}
                    >
                      <div className="flex">
                        <div className="mt-px mr-2 text-base whitespace-no-wrap">
                          Start right away
                        </div>
                        <IconArrowRight className=""></IconArrowRight>
                      </div>
                    </Button>
                  </Link>
                </div>
              </div>
              <IllustrationBurner className="absolute bottom-0 right-0 mr-6"></IllustrationBurner>
            </div>
          </div>
        </div>
        <div className="w-full px-4 mb-16 home__beta-offerings">
          <SectionHeader
            className="mb-4"
            title="Beta offering"
            subtitle="Lewis Beta"
          ></SectionHeader>
          <div
            className="flex items-center justify-center w-full mt-12 -mb-12 sm:mt-0 sm:mb-0"
            style={{ maxHeight: '12.75rem' }}
          >
            <div
              className="flex h-32 max-h-full"
              style={{
                transform: `translate(0px, ${50 - scrollOffset / 24}px)`,
              }}
            >
              <img
                className="object-contain max-h-full"
                src={IllustrationBetaLeft}
                alt="Beta Upload"
              ></img>
            </div>
            <div
              className="flex h-56 max-h-full"
              style={{
                transform: `translate(0px, ${110 - scrollOffset / 12}px)`,
              }}
            >
              <img
                className="object-contain max-h-full"
                src={IllustrationBetaCenter}
                alt="Beta Center"
              ></img>
            </div>
            <div
              className="flex h-32 max-h-full"
              style={{
                transform: `translate(0px, ${50 - scrollOffset / 24}px)`,
              }}
            >
              <img
                className="object-contain max-h-full"
                src={IllustrationBetaRight}
                alt="Beta Export"
              ></img>
            </div>
          </div>
          <div className="flex flex-wrap">
            <BetaItem className="w-full p-4 sm:w-1/2">
              Upload up to 100 pages per week
            </BetaItem>
            <BetaItem className="w-full p-4 sm:w-1/2">
              100 pages not enough? Get a refill at any time for&nbsp;free
            </BetaItem>
            <BetaItem className="w-full p-4 sm:w-1/2">
              Access your generated chemical library 24/7
            </BetaItem>
            <BetaItem className="w-full p-4 sm:w-1/2">
              Request error support & feature support
            </BetaItem>
          </div>
        </div>
        <div className="w-full px-4 py-16 mb-16 home__science-behind home__section-white">
          <div
            className="absolute flex"
            style={{
              height: '25rem',
              minHeight: '25rem',
              top: '5rem',
              zIndex: -1,
              transform: `translate(100%, ${160 - scrollOffset / 12}px)`,
            }}
          >
            <img
              className="object-contain object-left"
              src={IllustrationScience}
              alt="Illustration Science"
            ></img>
          </div>
          <SectionHeader
            className="pr-0 mb-6 ml-2 sm:pr-16 max-w-3/4"
            title="Solving a recognized challenge of chemical data retrieval with the power of artificial intelligence"
            subtitle="Science behind lewis"
            align="left"
          ></SectionHeader>
          <div className="flex flex-col w-full sm:flex-row text-rich-blue-darker">
            <div className="w-full px-2 mb-10 text-base sm:mb-0 sm:w-1/2">
              <p className="pr-4 mb-4 text-xl">
                The need for a practical method for automated processing of
                image-based chemical structures
              </p>
              <p className="pr-4 mb-4">
                Most{' '}
                <span className="font-extrabold">
                  scientific and patent documents
                </span>{' '}
                dealing with chemistry describe molecular structures either with
                systematic names or{' '}
                <span className="font-extrabold">graphical images</span> of
                Lewis structures. Graphical images pose inherent problems in{' '}
                <span className="font-extrabold">automated processing</span>{' '}
                when working with hundreds of thousands or even millions of
                documents since such representations cannot be{' '}
                <span className="font-extrabold">
                  directly interpreted by a computer
                </span>
                .
              </p>
              <p className="pr-4">
                Available image-based extraction methods are based on
                handwritten rules. A handwritten rule-based classification of
                structural features in molecular images is extremely error-prone
                because typical drawing styles of molecular structure follow no
                standard.
              </p>
            </div>
            <div className="w-full px-2 text-base sm:w-1/2">
              <p className="pr-4 mb-4 text-xl">
                Application of Deep Neural
                <br />
                Network Technology
              </p>
              <p className="pr-4 mb-4">
                Lewis is a novel software tool based on deep neural network
                technology which reliably extracts machine-readable chemical
                structures from image representations in a convenient web
                platform. Instead of relying on handwritten rules, we rely on a
                strong combination of different neural network architectures
                that yield enhanced robustness.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full px-4 mb-16 home__data-retreival">
          <SectionHeader
            className="mb-6 ml-2 max-w-3/4"
            title="Lewis Data Retrieval Procedure"
            subtitle="Science behind lewis"
            align="left"
          ></SectionHeader>
          <div className="flex flex-col w-full sm:flex-row text-rich-blue-darker">
            <div className="w-full px-2 mb-10 text-base sm:mb-0 sm:w-2/3">
              <div
                className="flex w-full pt-16 -mb-20 sm:pt-0 sm:mb-0"
                style={{
                  transform: `translate(0px, ${90 - scrollOffset / 24}px)`,
                }}
              >
                <img
                  className="object-contain object-left"
                  style={{ maxHeight: '24rem' }}
                  src={IllustrationExtraction}
                  alt="Beta Upload"
                ></img>
              </div>
            </div>
            <div className="flex flex-col w-full px-2 pl-4 mb-10 text-base sm:pl-8 sm:mb-0 sm:w-1/3">
              <div className="flex items-start mb-3 text-base">
                <div className="w-8 text-lg font-extrabold">1</div>
                <div className="pt-1 -mt-px">Extract image or page</div>
              </div>
              <div className="flex items-start mb-3 text-base">
                <div className="w-8 text-lg font-extrabold">2</div>
                <div className="pt-1 -mt-px">Recognize structure</div>
              </div>
              <div className="flex items-start mb-3 text-base">
                <div className="w-8 text-lg font-extrabold">3</div>
                <div className="pt-1 -mt-px">
                  Detect all structuring elements
                </div>
              </div>
              <div className="flex items-start mb-3 text-base">
                <div className="w-8 text-lg font-extrabold">4</div>
                <div className="flex-shrink pt-1 -mt-px max-w-3/4">
                  Rebuild molecular graph and convert structure to
                  machine-readable representation
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-4 py-16 home__message-team home__section-white">
          <div
            className="absolute flex"
            style={{
              height: '18rem',
              minHeight: '18rem',
              top: '12rem',
              zIndex: -1,
              transform: `translate(100%, ${160 - scrollOffset / 12}px)`,
            }}
          >
            <img
              className="object-contain"
              src={IllustrationCommunication}
              alt="Illustration Communication"
            ></img>
          </div>
          <div className="flex flex-col mb-20 sm:flex-row sm:mb-8">
            <div className="w-full sm:w-1/2">
              <SectionHeader
                className="mb-6 ml-2 max-w-3/4"
                title="A message from the team"
                subtitle="Lewis Beta"
                align="left"
              ></SectionHeader>
              <div className="flex flex-col w-full sm:flex-row text-rich-blue-darker">
                <div className="w-full px-2 pr-10 mb-10 text-base sm:mb-0">
                  <p className="pr-4 mb-8 text-lg">
                    Please keep in mind Lewis is in Beta:
                  </p>
                  <BetaItem className="mb-8" bold={false}>
                    <div>
                      If you experience any issue you are welcome to reach out
                      to the team via{' '}
                      <InlineLink
                        to="mailto:support@lewisapp.com"
                        className="text-vibrant-cyan hover:text-vibrant-cyan-dark"
                      >
                        mail
                      </InlineLink>
                      .
                    </div>
                  </BetaItem>
                  <BetaItem className="mb-8" bold={false}>
                    <div>
                      Lewis Team is dedicated to empowering scientists. We
                      encourage you to share your Lewis experiences and features
                      expectations with the{' '}
                      <InlineLink
                        to="mailto:feedback@lewisapp.com"
                        className="text-vibrant-cyan hover:text-vibrant-cyan-dark"
                      >
                        team
                      </InlineLink>
                      .
                    </div>
                  </BetaItem>
                </div>
              </div>
            </div>
            <div className="w-full pt-0 sm:pt-4 sm:w-1/2">
              <SectionHeader
                className="mb-6 ml-2 max-w-3/4"
                title="Coming Soon"
                subtitle=""
                align="left"
              ></SectionHeader>
              <div className="flex flex-col w-full sm:flex-row text-rich-blue-darker">
                <div className="w-full px-2 pr-10 text-base sm:mb-0">
                  <BetaItem className="mb-8" bold={false}>
                    <div>
                      API access and documentation for 3rd party application
                      development{' '}
                      <InlineLink
                        className="text-vibrant-cyan hover:text-vibrant-cyan-dark"
                        to="mailto:info@lewisapp.com"
                      >
                        (Get more information)
                      </InlineLink>
                    </div>
                  </BetaItem>
                  <BetaItem className="mb-8" bold={false}>
                    Parallel document upload and processing of multiple
                    documents with high velocity
                  </BetaItem>
                  <BetaItem className="mb-8" bold={false}>
                    Molecular Search to search for specific chemicals in
                    documents
                  </BetaItem>
                  <BetaItem className="mb-8" bold={false}>
                    Single Sign on
                  </BetaItem>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HomeLayout>
  )
}

export default Home
