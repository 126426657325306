import React from 'react'
import { Link } from 'react-router-dom'

import { version } from '../../../../util'
import { useUser } from '../../../../context/User'

import './style.scss'
import { Logo } from '../../../../components/logo'

interface Props {
  className?: string
}

const Header: React.FC<Props> = ({ className }) => {
  const { session } = useUser()

  return (
    <div className={`${className}`}>
      <div className={`flex w-full px-4 home__header`} id="home-header">
        <Link data-test="home-header-logo" className="flex items-center" to="/">
          <Logo className="text-rich-blue-darkest" version={version}></Logo>
        </Link>
        <div className="flex-grow"></div>
        <Link data-test="home-header-button-login" to="/database">
          <button className="px-3 py-2 text-xs font-extrabold border-2 border-solid rounded-md text-rich-blue sm:text-white border-rich-blue sm:border-white hover:text-white sm:hover:text-rich-blue hover:bg-rich-blue sm:hover:bg-white">
            {session ? 'Your database' : 'Login'}
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Header
