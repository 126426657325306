import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useExtractions } from '../../services/extractions'
import { useSubscription } from '../../services/user'

import ApplicationLayout from '../../layouts/ApplicationLayout'
import { DropCard, LimitCard, ProgressCard } from './components'
import { UserSubscriptionItemsItem } from '../../typings/api/types'

interface Props {}

enum UploadStep {
  Drop,
  Progress,
  Limit,
}

function transformStyles(
  currentStep: UploadStep,
  elementStep: UploadStep
): string {
  if (currentStep > elementStep) {
    return '-translate-x-64 opacity-0 pointer-events-none'
  }
  if (currentStep < elementStep) {
    return 'translate-x-64 opacity-0 pointer-events-none'
  }

  return 'translate-x-0 opacity-100'
}

const Upload: React.FC<Props> = () => {
  const history = useHistory()
  const {
    extractions,
    uploadFiles,
    clearUploads,
    activateUpdates,
    deactivateUpdates,
  } = useExtractions()
  const { subscription } = useSubscription()
  const [pageSubscription, setPageSubscription] = useState<
    UserSubscriptionItemsItem | undefined
  >()

  const [step, setStep] = useState<UploadStep>(UploadStep.Drop)

  useEffect(() => {
    let s: UploadStep = UploadStep.Drop
    if (extractions.filter((x) => x.upload).length > 0) {
      s = UploadStep.Progress
    } else if (
      pageSubscription &&
      pageSubscription.usage.consumed + pageSubscription.usage.pending >=
        pageSubscription.usage.limit
    ) {
      s = UploadStep.Limit
    }

    setStep(s)
  }, [extractions, pageSubscription])

  useEffect(() => {
    activateUpdates()
    return () => {
      deactivateUpdates()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const pageSubscription = subscription?.items.find(
      (x) => x.dimension === 'PAGE'
    )
    setPageSubscription(pageSubscription)
  }, [subscription])

  // Effect should only clear uploads if history changes.
  useEffect(() => {
    if (history.action === 'PUSH') {
      clearUploads()
    }
  }, [history]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ApplicationLayout>
      <div
        id="upload"
        className="flex flex-col items-center justify-start min-h-full px-12 py-8"
      >
        <div className="relative block w-full">
          <DropCard
            data-test="upload-card-drop"
            className={`left-0 right-0 ml-auto mr-auto absolute transform ease-out transition ${transformStyles(
              step,
              UploadStep.Drop
            )}`}
            onDrop={(files: File[]) => {
              uploadFiles(files)
            }}
          ></DropCard>
          <ProgressCard
            data-test="upload-card-progress"
            className={`left-0 right-0 ml-auto mr-auto absolute transform ease-out transition ${transformStyles(
              step,
              UploadStep.Progress
            )}`}
            extractions={extractions}
            onLeave={() => {
              history.push('/database')
            }}
            onCancel={() => setStep(UploadStep.Drop)}
          ></ProgressCard>
          <LimitCard
            data-test="upload-card-limit"
            className={`left-0 right-0 ml-auto mr-auto absolute transform ease-out transition ${transformStyles(
              step,
              UploadStep.Limit
            )}`}
            limit={{ total: pageSubscription?.usage.limit || 0 }}
          ></LimitCard>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default Upload
