import React from 'react'
import { ReactComponent as OptionsIcon } from '../../../assets/static/images/icon-options.svg'

import './style.scss'

interface Props {
  toggled?: boolean
  onClick?: () => void
  className?: string
}

const OptionsButton: React.FC<Props> = ({
  toggled = false,
  onClick,
  className = '',
}) => {
  const toggledClasses = toggled
    ? 'bg-transparent'
    : 'bg-sensitive-grey-dark hover:bg-sensitive-grey-darker active:bg-sensitive-grey-darkest'

  return (
    <button
      className={`focus:outline-none ${className}`}
      onClick={(e) => {
        onClick && onClick()
      }}
    >
      <div
        className={`relative rounded-full w-6 h-6 cursor-pointer ${toggledClasses}`}
      >
        <OptionsIcon
          className={`absolute left-0 top-0 w-full h-full options-button__icon${
            toggled ? ' options-button__icon--toggled' : ''
          }`}
        ></OptionsIcon>
      </div>
    </button>
  )
}

export default OptionsButton
