import React, { useState } from 'react'
import Cookies from 'js-cookie'

import { Link } from '../../pages/Legal/components'
import { useAnalytics } from '../../context/Analytics'
import { Button } from '../buttons'

interface Props {}

const CookieMessage: React.FC<Props> = () => {
  const ca = Cookies.get('lewis_cookies_accepted') ?? null
  const [cookiesAccepted, setCookiesAccepted] = useState(ca)
  const { updateConsent } = useAnalytics()

  if (cookiesAccepted) {
    return null
  }

  const acceptCookies = () => {
    setCookiesAccepted('true')
    Cookies.set('lewis_cookies_accepted', 'true', {
      expires: 365,
    })
    updateConsent()
  }

  return (
    <div
      id="cookie-message"
      className="absolute bottom-0 left-0 flex justify-center w-full px-2 pb-8"
    >
      <div
        className="flex flex-col items-start px-6 py-5 bg-white rounded-md sm:items-center sm:flex-row card card-active"
        style={{ maxWidth: '53rem' }}
      >
        <div className="pr-6 mb-6 text-sm sm:mb-0">
          This website uses <span className="font-extrabold">'cookies'</span> to
          analyze Website traffic and usage, and provide you with the best, most
          relevant experience. You can find out more about the cookies in our{' '}
          <Link to="/privacy#cookies">Cookie&nbsp;policy</Link>.
        </div>
        <div>
          <Button
            className="text-white bg-rich-blue hover:bg-rich-blue-dark"
            data-test="cookie-message-button-accept"
            onClick={acceptCookies}
          >
            Accept&nbsp;Cookies
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CookieMessage
