import { useEffect, useRef } from 'react'

function useInterval(callback: () => {}, ms: number) {
  const savedCallback = useRef<() => {}>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current()
    }
    const id = setInterval(tick, ms)
    return () => clearInterval(id)
  }, [ms])
}

export { useInterval }
