import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Headline } from '@liquid-design/liquid-design-react'

import { Loader } from '../../../components/loader'

interface Props {
  loading: boolean
  searching?: boolean
}

const Empty: React.FC<Props> = ({ loading, searching = false }) => {
  return (
    <div className="flex flex-col items-center mt-8 text-center">
      <div className="mb-4">
        <Loader loading={loading && !searching}></Loader>
      </div>
      {!loading && (
        <div>
          <div className="mb-8">
            <Headline>Your database is empty</Headline>
            {searching ? (
              <Headline type="H5">No structures found.</Headline>
            ) : (
              <Headline type="H5">No structures extracted yet.</Headline>
            )}
          </div>
          {!searching && (
            <Link to="/upload">
              <Button appearance="highlight">
                + Start your first extraction
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default Empty
