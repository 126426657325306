import {
  Job,
  Structure,
  UserSubscription as ApiUserSubscription,
  Export as ApiExport,
  Survey as ApiSurvey,
  SurveyResponse as ApiSurveyResponse,
} from '../api/types'

export enum FileUploadState {
  pending = 'pending',
  uploading = 'uploading',
  done = 'done',
  error = 'error',
}

export interface FileUpload {
  file: File
  state: FileUploadState
  progress: number
  error?: Error
}

export interface FileUploadResponse {
  jobId: string
  uploadDate: string
  file: File
}

export enum ExtractionState {
  undefined = 'undefined',
  uploading = 'uploading',
  extracting = 'extracting',
  completed = 'completed',
  error = 'error',
}

export interface ExtractionStructure extends Structure {
  internalId: string
  deleted: boolean
  deletePending: boolean
}

export interface ExtractionJob extends Job {}

export interface Extraction {
  jobId?: string
  state: ExtractionState
  upload?: FileUpload
  job?: ExtractionJob
  structures?: ExtractionStructure[]
  structuresUpdated?: number
}

export interface UserSubscription extends ApiUserSubscription {}

export interface UserSubscriptionDimension {
  end: string
  used: number
  left: number
  limit: number
  usageRate: number
  exceeded: boolean
}

export function labelForUserSubscriptionPlan(id?: string) {
  switch (id) {
    case 'FREE':
      return 'Free'
    default:
      return ''
  }
}

export interface Export extends ApiExport {}

export interface SurveyInquiry extends ApiSurvey {}

export interface SurveyResponse extends ApiSurveyResponse {}
