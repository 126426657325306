import React from 'react'
import { Helmet } from 'react-helmet'
import { Theme } from '@liquid-design/liquid-design-react'
import { BrowserRouter as Router } from 'react-router-dom'

import Pages from './pages'
import { NotificationProvider } from './context/Notification'
import { AnalyticsProvider } from './context/Analytics'

import './tailwind.generated.css'

const { REACT_APP_BASENAME: basename = '/' } = process.env

function App() {
  return (
    <AnalyticsProvider>
      <>
        <Helmet>
          <title>Lewis – Automate Chemical Data Extraction</title>
          <meta
            name="description"
            content="Generate machine-readable molecular structures from images and documents with high accuracy with Lewis. Convert your first molecule now!"
          />
          <meta
            name="keywords"
            content="chemical data extraction, convert chemical structures, chemical structure, lewis structure"
          ></meta>
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://lewisapp.com" />
          <meta
            property="og:image"
            content={`https://lewisapp.com/og_v2.png`}
          />
          <meta
            property="og:description"
            content="Generate machine-readable molecular structures from images and documents with high accuracy with Lewis. Convert your first molecule now!"
          />
          <meta
            property="og:title"
            content="Lewis – Automate Chemical Data Extraction"
          />
        </Helmet>
        <Router basename={basename}>
          <Theme
            className="inset-0 flex flex-col max-h-full"
            themeName="vibrantCyan"
          >
            <NotificationProvider>
              <Pages></Pages>
            </NotificationProvider>
          </Theme>
        </Router>
      </>
    </AnalyticsProvider>
  )
}

export default App
