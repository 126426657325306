import React from 'react'

interface Props {
  title: string
  subtitle: string
  align?: 'left' | 'center' | 'right'
  className?: string
}

const SectionHeader: React.FC<Props> = ({
  title,
  subtitle,
  align = 'center',
  className,
}) => {
  let alignText
  let alignItems

  switch (align) {
    case 'left':
      alignText = 'text-left'
      alignItems = 'items-start'
      break
    case 'center':
      alignText = 'text-center'
      alignItems = 'items-center'
      break
    case 'right':
      alignText = 'text-right'
      alignItems = 'items-end'
      break
  }

  return (
    <div className={`w-full ${className}`}>
      <div className={`flex flex-col ${alignItems} w-full`}>
        <div
          className={`mb-2 text-xs font-extrabold ${alignText} uppercase tracking-utlra-wide text-rich-blue`}
        >
          {subtitle}
        </div>
        <div
          className={`mb-2 text-2xl font-extrabold ${alignText} text-rich-blue-darkest`}
        >
          {title}
        </div>
      </div>
    </div>
  )
}

export default SectionHeader
