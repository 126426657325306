// https://css-tricks.com/building-progress-ring-quickly/

import React from 'react'

interface Props {
  radius: number
  stroke: number
  progress: number
  className?: string
}

const ProgressRing: React.FC<Props> = ({
  radius,
  stroke,
  progress,
  className = '',
}) => {
  const normalizedRadius = radius - stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - progress * circumference

  return (
    <div className={`flex justify-center ${className}`}>
      <div
        className="relative"
        style={{ width: `${radius * 2}px`, height: `${radius * 2}px` }}
      >
        <svg
          className="absolute top-0 left-0"
          height={radius * 2}
          width={radius * 2}
        >
          <circle
            stroke="white"
            opacity=".3"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + ' ' + circumference}
            style={{}}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        <svg
          className="absolute top-0 left-0 transform -rotate-90"
          height={radius * 2}
          width={radius * 2}
        >
          <circle
            stroke="white"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + ' ' + circumference}
            style={{
              strokeDashoffset,
            }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
      </div>
    </div>
  )
}

export default ProgressRing
