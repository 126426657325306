import React from 'react'
import Dropzone from 'react-dropzone'

import { ReactComponent as Hexagon } from '../../assets/static/images/hexagon.svg'

interface Props {
  allowedTypes: string[]
  onDrop: (files: File[]) => void
  inputId: string
  className?: string
}

const UploadDropzone: React.FC<Props> = ({
  allowedTypes,
  onDrop,
  inputId,
  className,
}) => {
  return (
    <div
      className={`border-dashed border-rich-blue border-2 bg-sensitive-grey rounded transform transition duration-150 ease-out hover:scale-101 hover:bg-sensitive-grey-dark active:bg-sensitive-grey-darker ${className}`}
    >
      <Dropzone onDrop={onDrop}>
        {({ getRootProps }): JSX.Element => (
          <div className="" {...getRootProps()}>
            <label className="upload__drop">
              <input
                type="file"
                onChange={(e): void => {
                  onDrop(
                    e.currentTarget.files
                      ? Array.from(e.currentTarget.files)
                      : []
                  )
                  e.target.value = ''
                }}
                accept={allowedTypes.join(', ')}
                className="block overflow-hidden max-h-zero"
                id={inputId}
                required
                multiple
              />
              <div
                data-test="upload-dropzone-label"
                className="flex flex-col items-center p-12 text-center cursor-pointer"
              >
                <Hexagon className="mb-3 stroke-current text-rich-blue-darkest"></Hexagon>
                <p>
                  Please drag & drop your{' '}
                  <strong className="text-rich-red">non-confidential</strong>{' '}
                  files into the window{' '}
                  <span className="upload__drop-content-link">
                    or click to select files.
                  </span>
                </p>
              </div>
            </label>
          </div>
        )}
      </Dropzone>
    </div>
  )
}

export default UploadDropzone
