import React from 'react'
import { TextField } from '@liquid-design/liquid-design-react'
import SurveyFormElementTitle from './SurveyFormElementTitle'

interface Props {
  title: string
  value?: string
  onChange: (value: string) => void
  className?: string
}

const SurveyTextBox: React.FC<Props> = ({
  title,
  value,
  onChange,
  className,
}) => {
  return (
    <div className={`survey__text-field ${className}`}>
      <SurveyFormElementTitle className="mb-4">{title}</SurveyFormElementTitle>
      <div className="w-full">
        <TextField
          value={value}
          onChange={onChange}
          grey
          style={{ minWidth: '100%' }}
        ></TextField>
      </div>
    </div>
  )
}

export default SurveyTextBox
