import React from 'react'

// import { ReactComponent as InitialM } from '../../assets/static/images/logo.svg'
import { ReactComponent as Hexagon } from '../../assets/static/images/hexagon-small.svg'

interface Props {
  version?: string
  showBeta?: boolean
  className?: string
}

// const Logo: React.FC<Props> = ({ className = '' }) => {
//   return (
//     <div className={`cursor-pointer ${className}`}>
//       <InitialM className="mr-3" />
//     </div>
//   )
// }

const Logo: React.FC<Props> = ({
  version,
  showBeta = true,
  className = '',
}) => {
  return (
    <div
      className={`flex items-center text-xs text-rich-blue-darkest ${className}`}
      title={version ? `Lewis App v${version}` : ''}
    >
      <Hexagon className="w-5 h-5 mr-2 stroke-current"></Hexagon>
      <div className="mr-2 uppercase">
        <span className="font-extrabold">Lewis</span>App
      </div>
      {showBeta && (
        <div className="px-1 py-px font-extrabold tracking-widest text-center uppercase border-2 rounded text-rich-blue text-2xs border-rich-blue">
          <div className="-mr-px">Beta</div>
        </div>
      )}
    </div>
  )
}

export default Logo
