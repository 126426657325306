import React from 'react'
import { Headline } from '@liquid-design/liquid-design-react'

import HomeLayout from '../../layouts/HomeLayout'

interface Props {}

const Error: React.FC<Props> = () => {
  return (
    <HomeLayout>
      <div className="flex flex-col items-start p-4 pt-20">
        <Headline className="w-full">404.</Headline>
        <Headline className="w-full">This Page Does Not Exist.</Headline>
      </div>
    </HomeLayout>
  )
}

export default Error
