import React from 'react'
import { Loader } from '../../../../components/loader'

interface Props {
  structureSequence: { index: number; of: number }
  className?: string
}

const ExtractionGridItemProgress: React.FC<Props> = ({
  structureSequence,
  className,
}) => {
  return (
    <div className={`p-4 ${className}`}>
      <div
        className={`relative h-48 bg-white rounded-md overflow-hidden shadow`}
      >
        <div className="relative flex flex-col justify-end w-full h-full">
          <div className="flex flex-col items-center justify-center w-full h-32 text-rich-blue-darkest bg-sensitive-grey-dark">
            <div className="px-4 mb-4">
              <span className="font-extrabold">In progress</span> -
              Structure&nbsp;
              {structureSequence.index}&nbsp;of&nbsp;{structureSequence.of}
            </div>
            <div>
              <Loader loading={true} size="small"></Loader>
            </div>
          </div>
          <div className="flex flex-wrap items-center w-full h-16 p-3 text-sm bg-white shadow-up animate-pulse">
            <div className="relative w-2/3 h-2">
              <div className="absolute w-full h-full rounded-full bg-sensitive-grey-dark"></div>
            </div>
            <div className="relative w-1/2 h-2">
              <div className="absolute w-full h-full rounded-full bg-sensitive-grey-dark"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExtractionGridItemProgress
