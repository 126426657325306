import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import 'focus-within-polyfill'
import './util/polyfill'

import React from 'react'
import { render } from 'react-dom'

import App from './App'
import { UserContextProvider } from './context/User'

// Using all plugins of immer.
import { enableAllPlugins } from 'immer'
enableAllPlugins()

// set autoLogin to true to automatically redirect

render(
  <UserContextProvider autoLogin={false}>
    <App />
  </UserContextProvider>,
  document.getElementById('root')
)
