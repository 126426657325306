import { useUser } from '../context/User'
import { apiBase } from '../lib'
import { request } from '../lib/fetch'
import { ExtractionStructure } from '../typings/app/types'
import { jobIdForStructure } from './structures'

// enum StructureErrorFeedbackCode {
//   FEEDBACK_ERROR_STRUCTURE = 'Structure completely wrong',
//   FEEDBACK_ERROR_STRUCTURE_PARTIAL = 'Only parts of the structure were correct',
//   FEEDBACK_ERROR_ELEMENTS = 'Wrong elements',
//   FEEDBACK_ERROR_CONNECTIVITY = 'Wrong connectivity',
//   FEEDBACK_ERROR_MOLECULES = 'Wrong number of molecules',
//   FEEDBACK_ERROR_OTHER = 'Other error',
// }

enum StructureErrorFeedbackCode {
  STRUCTURE = 'STRUCTURE',
  STRUCTURE_PARTIAL = 'STRUCTURE_PARTIAL',
  ELEMENTS = 'ELEMENTS',
  CONNECTIVITY = 'CONNECTIVITY',
  MOLECULES = 'MOLECULES',
  OTHER = 'OTHER',
}

async function submitStructureErrorFeeback(
  structure: ExtractionStructure,
  errorCode: StructureErrorFeedbackCode,
  text?: string,
  accessToken?: string
): Promise<{ success: boolean; error?: Error }> {
  const jobId = jobIdForStructure(structure)
  if (!jobId) {
    return { success: false, error: Error('Invalid structure') }
  }

  try {
    const path = `${apiBase}/job/${jobId}/structure/${structure.structureId}/feedback`
    const { response, error } = await request<any>(path, {
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken}` },
      body: {
        code: StructureErrorFeedbackCode[errorCode],
        comment: text,
      },
    })

    return { success: response.ok, error: error || undefined }
  } catch (error) {
    return { success: false, error: error }
  }
}

async function submitGeneralFeedback(
  text: string,
  type: 'general' | 'refill',
  accessToken?: string
) {
  let subject = ''
  switch (type) {
    case 'general':
      subject = 'General Feedback'
      break
    case 'refill':
      subject = 'Refill Request'
      break

    default:
      break
  }

  try {
    const path = `${apiBase}/feedback`
    const { response, error } = await request<any>(path, {
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken}` },
      body: {
        subject: subject,
        body: text,
      },
    })

    return { success: response.ok, error: error || undefined }
  } catch (error) {
    return { success: false, error: error }
  }
}

function useFeedback() {
  const user = useUser()

  async function structureErrorFeedback(
    structure: ExtractionStructure,
    errorCode: StructureErrorFeedbackCode,
    text?: string
  ) {
    return submitStructureErrorFeeback(
      structure,
      errorCode,
      text,
      user.session?.accessToken
    )
  }

  async function generalFeedback(text: string, type: 'general' | 'refill') {
    return submitGeneralFeedback(text, type, user.session?.accessToken)
  }

  return { structureErrorFeedback, generalFeedback }
}

export { useFeedback, submitStructureErrorFeeback, StructureErrorFeedbackCode }
