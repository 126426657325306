import { nanoid } from 'nanoid'

import { apiBase } from '../lib'
import { request } from '../lib/fetch'

import { CreateStreamResponseJson } from '../typings/api/types'
import { sleep } from '../util'

function openWebSocket<T>(
  url: string,
  onMessage?: (message: T) => void,
  onError?: (error: Error) => void,
  onReconnected?: (
    webSocket: WebSocket,
    urls: { old: string; new: string }
  ) => void,
  reconnectUrl?: () => Promise<string | undefined>
): { webSocket: WebSocket } {
  let webSocket = new WebSocket(url)

  if (webSocket) {
    webSocket.onerror = (event: any) => {
      console.error('Socket error')
      onError && onError(Error(`Websocket error occured: ${event.data}`))
    }

    webSocket.onopen = (event: any) => {
      // console.debug('Socket connected')
    }

    webSocket.onclose = async (event: any) => {
      // console.debug('Socket closed')

      if (reconnectUrl) {
        const originalUrl = webSocket.url
        const url = await reconnectUrl()

        if (url) {
          // console.debug('Socket Url: ' + url)
          const { webSocket: ws } = openWebSocket(
            url,
            onMessage,
            onError,
            onReconnected,
            reconnectUrl
          )
          onReconnected && onReconnected(ws, { old: originalUrl, new: url })
        }
      }
    }

    webSocket.onmessage = (event: any) => {
      // console.debug(JSON.parse(event.data))
      onMessage && onMessage(JSON.parse(event.data))
    }
  }

  return { webSocket }
}

function closeWebSocket(socket: WebSocket) {
  if (
    socket.readyState === socket.CLOSED ||
    socket.readyState === socket.CLOSING
  )
    return

  if (socket.readyState === socket.OPEN) {
    socket.close()
    return
  }

  socket.addEventListener('open', () => {
    socket.close()
  })
}

interface WebSocketRecord {
  path: string
  webSocket: WebSocket
  subscriptions: {
    id: string
    onMessage?: (message: any) => void
    onError?: (error: Error) => void
  }[]
}

const streamUrls: {
  [key: string]: { url?: string; pending: boolean; error?: Error }
} = {}

async function fetchStreamUrl(
  accessToken: string
): Promise<{ response: string | null; error: Error | null }> {
  if (streamUrls[accessToken]) {
    while (streamUrls[accessToken].pending) {
      await sleep(100)
    }

    return {
      response: streamUrls[accessToken].url || null,
      error: streamUrls[accessToken].error || null,
    }
  }

  const path = `${apiBase}/stream`
  try {
    streamUrls[accessToken] = { pending: true }
    const { response, body, error } = await request<CreateStreamResponseJson>(
      path,
      {
        method: 'POST',
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )

    streamUrls[accessToken] = {
      url: body?.url,
      pending: false,
      error: error || undefined,
    }
    if (response.ok && body) {
      return { response: body.url || null, error: null }
    }

    throw error
  } catch (error) {
    return { response: null, error: error }
  }
}

let webSocketRecords: WebSocketRecord[] = []
let pingInterval: NodeJS.Timeout | null

export async function subscribeSocket<T>(
  accessToken: string,
  onMessage?: (message: T) => void,
  onError?: (error: Error) => void
): Promise<string | undefined> {
  const { response: url, error } = await fetchStreamUrl(accessToken)

  if (error || !url) {
    return undefined
  }

  const path = url.split('?')[0]
  let record = webSocketRecords.find((x) => x.path === path)
  if (!record) {
    const ws = openWebSocket(
      url,
      (message) => {
        const record = webSocketRecords.find((x) => x.path === path)
        record?.subscriptions.forEach(
          (x) => x.onMessage && x.onMessage(message)
        )
      },
      (error) => {
        const record = webSocketRecords.find((x) => x.path === path)
        record?.subscriptions.forEach((x) => x.onError && x.onError(error))
      },
      (webSocket: WebSocket, urls: { old: string; new: string }) => {
        const record = webSocketRecords.find((x) => x.path === urls.old)
        if (!record) {
          return
        }

        // console.log('Reconnect')
        record.path = urls.new
        record.webSocket = webSocket
      },
      async () => {
        const { response: url } = await fetchStreamUrl(accessToken)
        return url || undefined
      }
    )

    if (ws) {
      record = {
        path: path,
        webSocket: ws.webSocket,
        subscriptions: [],
      }
      webSocketRecords = [...webSocketRecords, record]

      if (!pingInterval) {
        const interval = setInterval(async () => {
          webSocketRecords.forEach((x) => {
            x.webSocket.readyState === x.webSocket.OPEN && x.webSocket.send('')
          })

          pingInterval = interval
        }, 60000)
      }
    } else {
      return undefined
    }
  }

  const id = nanoid()
  record.subscriptions = [
    ...record.subscriptions,
    { id: id, onMessage: onMessage, onError: onError },
  ]

  return id
}

export function unsubscribeSocket(id: string) {
  const record = webSocketRecords.find((x) =>
    x.subscriptions.some((y) => y.id === id)
  )

  if (!record) return

  record.subscriptions = record.subscriptions.filter((x) => x.id !== id)
  if (!record.subscriptions || !record.subscriptions.length) {
    closeWebSocket(record.webSocket)
    webSocketRecords = webSocketRecords.filter((x) => x !== record)

    if (webSocketRecords.length === 0) {
      pingInterval && clearInterval(pingInterval)
      pingInterval = null
    }
  }
}
