import React, { useState, useEffect } from 'react'
import { Headline, Theme, Button } from '@liquid-design/liquid-design-react'

import Card from './Card'
import { ReactComponent as ProgressIllustration } from '../../../assets/static/images/illustration-progress.svg'
import { ProgressBar } from '../../../components/progress'

import { Extraction, FileUpload } from '../../../typings/app/types'
import {
  isUploading,
  isExtracting,
  pendingPages,
  pendingStructures,
  pendingUpoloads,
} from '../../../services/extractions'

interface Props {
  extractions: Extraction[]
  onLeave?: () => void
  onCancel?: () => void
  className?: string
}

const ProgressCard: React.FC<Props> = ({
  extractions,
  onLeave,
  onCancel,
  className,
  ...props
}) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0.0)
  const [filesUploading, setFilesUploading] = useState<number>(0)
  const [fileUploadsTotal, setFileUploadsTotal] = useState<number>(0)

  const [uploads, setUploads] = useState<FileUpload[]>([])

  const [extractionProgress, setExtractionProgress] = useState(0)

  const [filesExtracting, setFilesExtracting] = useState(0)
  const [pagesExtracting, setPagesExtracting] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalStructures, setTotalStructures] = useState(0)
  const [structuresExtracting, setStructuresExtracting] = useState(0)

  useEffect(() => {
    // const extracting = extractions.filter((x) => isExtracting(x))
    // const uploading = extractions.filter((x) => isUploading(x))

    const extractingAndUploading = extractions.filter(
      (x) => (isExtracting(x) && isUploading(x)) || isUploading(x)
    )

    const u = pendingUpoloads(extractions)
    setUploads(u)

    setFilesExtracting(extractingAndUploading.length)
    setPagesExtracting(pendingPages(extractingAndUploading).pending)
    setTotalPages(pendingPages(extractingAndUploading).total)
    setTotalStructures(pendingStructures(extractingAndUploading).total)
    setStructuresExtracting(pendingStructures(extractingAndUploading).pending)
  }, [extractions])

  useEffect(() => {
    let progress =
      1 -
      (structuresExtracting + pagesExtracting) / (totalStructures + totalPages)
    if (isNaN(progress)) progress = uploads.length > 0 ? 0 : 1

    setExtractionProgress(progress)
  }, [
    totalStructures,
    structuresExtracting,
    pagesExtracting,
    totalPages,
    uploads,
  ])

  useEffect(() => {
    const progress =
      uploads
        .map((x) => x.progress)
        .reduce((x, y) => {
          return x + y
        }, 0.0) / uploads.length

    setUploadProgress(progress)

    setFilesUploading(
      uploads.filter(
        (x) =>
          x.state === 'done' || x.state === 'error' || x.state === 'uploading'
      ).length
    )
    setFileUploadsTotal(uploads.length)
  }, [uploads])

  return (
    <Card {...props} className={className}>
      <div className="w-full max-w-full px-4 mb-6 text-center">
        <Headline className="">Extraction in Progress</Headline>
      </div>
      <div className="flex flex-col flex-wrap">
        <div className="flex justify-center mb-2">
          <ProgressIllustration></ProgressIllustration>
        </div>
        <div className="mb-4 font-extrabold text-center">
          {/* Estimated 24 minutes to finish the extraction. */}
        </div>
        <div className="mb-4 text-sm text-center text-rich-blue-darkest-50">
          <div className="flex flex-col items-center mb-4">
            <div className="mb-2">
              {uploadProgress >= 1
                ? `Upload completed`
                : `Uploading file ${filesUploading} of ${fileUploadsTotal}`}
            </div>
            <ProgressBar
              className="w-56 max-w-full"
              color="vibrant-cyan"
              value={uploadProgress * 100.0}
            ></ProgressBar>
          </div>
          <div className="flex flex-col items-center mb-4">
            {extractionProgress >= 1 ? (
              <div className="mb-2">Extraction complete</div>
            ) : (
              <div className="mb-2">
                Extracting {totalPages} page
                {totalPages !== 1 ? 's' : ''} from {filesExtracting} file
                {filesExtracting !== 1 ? 's' : ''} -{' '}
                <strong className="font-extrabold">
                  {structuresExtracting} structure
                  {structuresExtracting !== 1 ? 's' : ''} left
                </strong>
              </div>
            )}
            <ProgressBar
              className="w-56 max-w-full"
              color="vibrant-yellow"
              value={extractionProgress * 100}
            ></ProgressBar>
          </div>
        </div>
        <div className="flex justify-center w-full">
          <div className="flex flex-col items-center w-56">
            <Theme themeName="richBlue">
              {uploadProgress >= 1 && (
                <Button
                  data-test="upload-progress-button-database"
                  onClick={onLeave}
                >
                  Go to database
                </Button>
              )}
              {uploadProgress < 1 && (
                <Button
                  data-test="upload-progress-button-cancel"
                  appearance="ghost"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              )}
            </Theme>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ProgressCard
