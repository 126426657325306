import { request } from '../lib/fetch'
import { apiBase } from '../lib'

import { SurveyInquiry, SurveyResponse } from '../typings/app/types'

export async function fetchSurveyInquiries(
  accessToken: string
): Promise<{
  surveyItems?: SurveyInquiry[]
  error?: Error
}> {
  const path = `${apiBase}/survey`

  try {
    const { response, body, error } = await request<{ items: SurveyInquiry[] }>(
      path,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )

    if (response.ok && body) {
      // This is just for development
      // if (!body.items.length) {
      //   body.items = [{ formName: 'survey1', surveyId: 'MOCK' }]
      // }
      return { surveyItems: body.items, error: undefined }
    }

    if (error) {
      throw error
    }
  } catch (error) {
    return { surveyItems: undefined, error: error }
  }

  return { surveyItems: undefined, error: undefined }
}

export async function sendSurveyResponse(
  surveyId: string,
  surveyResponse: SurveyResponse,
  accessToken: string
): Promise<{
  response: boolean
  error?: Error
}> {
  // // TODO: Temporarily disable submission:
  // return { response: true, error: undefined }

  const path = `${apiBase}/survey/${surveyId}/response`

  try {
    const { response, body, error } = await request<any>(path, {
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken}` },
      body: surveyResponse,
    })

    if (response.ok && body) {
      return { response: response.ok, error: undefined }
    }

    if (error) {
      throw error
    }
  } catch (error) {
    return { response: false, error: error }
  }

  return { response: false, error: undefined }
}
