import React from 'react'
import { Headline, Block, Link, TitleValue } from './components'
import HomeLayout from '../../layouts/HomeLayout'

interface Props {}

const Imprint: React.FC<Props> = () => {
  return (
    <HomeLayout>
      <div
        className="flex flex-col px-4 pb-32 text-sm leading-6"
        style={{
          maxWidth: '32rem',
        }}
      >
        <Headline>Imprint</Headline>
        <Block title="Address">
          EMD Digital Inc.
          <br />
          400 Summit Drive
          <br />
          Burlington, MA 01803
          <br />
          USA
        </Block>
        {/* <Block className="font-extrabold">Phone: -</Block> */}
        <Block className="mb-16">
          Phone: <Link to="tel:+13147805662">+1 314 780 5662</Link>
          <br />
          <br />
          <Link to="mailto:info@lewisapp.com">info@lewisapp.com</Link>
          <br />
          <Link to="https://www.emdgroup.com/">www.emdgroup.com</Link>
        </Block>
        <Block title="Details">
          Legal form: Massachusetts corporation
          <br />
          Massachusetts ID Number: 001344149 <br />
          Registered Agent: Corporation Service Company, 84 State St., Boston,
          MA 02109 USA
        </Block>
        <Block>
          <TitleValue title="Board of Directors (Managers)" wrap={true}>
            James Kugler
            <br />
            Monica Elliott
            <br />
            Employer Identification Number (EIN): 83-1784758
          </TitleValue>
        </Block>
        <Block>
          <TitleValue title="Responsible for the content" wrap={true}>
            Michael Vande Vrede
            <br />
            EMD Digital Inc.
            <br />
            400 Summit Dr.
            <br />
            Burlington, MA 01803
            <br />
            USA
          </TitleValue>
        </Block>
        <Block>
          You may also have a look at our{' '}
          <Link to="/privacy">privacy statement</Link> to see that we take data
          protection and confidentiality very seriously and on our site do not
          make further use of your social media contributions.
        </Block>
      </div>
    </HomeLayout>
  )
}

export default Imprint
