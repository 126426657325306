import React from 'react'

interface Props {
  children?: JSX.Element | string
  className?: string
}

const SurveyFormElementTitle: React.FC<Props> = ({ children, className }) => {
  return <h3 className={`text-sm font-extrabold ${className}`}>{children}</h3>
}

export default SurveyFormElementTitle
