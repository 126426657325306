// https://github.com/n8tb1t/use-scroll-position
import { useState, useEffect, MutableRefObject } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export interface ScrollPosition {
  x: number
  y: number
}

export interface ScrollRatio {
  horizontal: number
  vertical: number
}

const isBrowser = typeof window !== `undefined`
const zeroPosition = { x: 0, y: 0 }
const zeroRatio = { vertical: 0, horizontal: 0 }

const getClientRect = (element: HTMLElement | null) =>
  element?.getBoundingClientRect()

const getScrollPosition = ({
  element,
  useWindow,
  boundingElement,
}: {
  element?: MutableRefObject<HTMLElement | null | undefined>
  boundingElement?: MutableRefObject<HTMLElement | null | undefined>
  useWindow?: boolean
}): { position: ScrollPosition; ratio: ScrollRatio } => {
  if (!isBrowser) {
    return { position: zeroPosition, ratio: zeroRatio }
  }

  if (useWindow) {
    return {
      position: { x: window.scrollX, y: window.scrollY },
      ratio: {
        horizontal:
          window.scrollX + window.innerWidth / document.body.clientWidth,
        vertical:
          window.scrollY + window.innerHeight / document.body.clientHeight,
      },
    }
  }

  const targetPosition = getClientRect(element?.current || document.body)
  const containerPosition = getClientRect(boundingElement?.current || null)

  if (!targetPosition) {
    return { position: zeroPosition, ratio: zeroRatio }
  }

  return containerPosition
    ? {
        position: {
          x: (containerPosition.x || 0) - (targetPosition.x || 0),
          y: (containerPosition.y || 0) - (targetPosition.y || 0),
        },
        ratio: {
          horizontal:
            ((containerPosition.x || 0) -
              (targetPosition.x || 0) +
              (containerPosition.width || 0)) /
            (targetPosition.width || 0),
          vertical:
            ((containerPosition.y || 0) -
              (targetPosition.y || 0) +
              (containerPosition.height || 0)) /
            (targetPosition.height || 0),
        },
      }
    : {
        position: { x: targetPosition.left, y: targetPosition.top },
        ratio: {
          horizontal: (targetPosition.x || 0) / (targetPosition.width || 0),
          vertical: (targetPosition.y || 0) / (targetPosition.height || 0),
        },
      }
}

export const useScrollPosition = (
  element?: MutableRefObject<HTMLElement | null | undefined>,
  useWindow?: boolean,
  wait?: number,
  boundingElement?: MutableRefObject<HTMLElement | null | undefined>,
  offset: { x: number; y: number } = { x: 0, y: 0 }
): { scrollPosition: ScrollPosition; scrollRatio: ScrollRatio } => {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>(
    zeroPosition
  )
  const [scrollRatio, setScrollRatio] = useState<ScrollRatio>(zeroRatio)

  const debouncedHandleScroll = useDebouncedCallback(
    () => {
      const { position, ratio } = getScrollPosition({
        element,
        useWindow,
        boundingElement,
      })

      if (position.x !== scrollPosition.x || position.y !== scrollPosition.y) {
        setScrollPosition({
          x: position.x + offset.x,
          y: position.y + offset.y,
        })
      }
      if (
        ratio.horizontal !== scrollRatio.horizontal ||
        ratio.vertical !== scrollRatio.vertical
      ) {
        setScrollRatio(ratio)
      }
    },
    wait || 0,
    { leading: true, maxWait: wait }
  )

  useEffect(() => {
    if (!isBrowser) {
      return undefined
    }

    const bounding = boundingElement?.current || window
    bounding.addEventListener('scroll', debouncedHandleScroll.callback)

    return () => {
      bounding.removeEventListener('scroll', debouncedHandleScroll.callback)
    }
  }, [element, boundingElement, debouncedHandleScroll.callback])

  return { scrollPosition, scrollRatio }
}
