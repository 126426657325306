import React from 'react'
import { Headline as LiquidHeadline } from '@liquid-design/liquid-design-react'

interface Props {
  children?: JSX.Element | string
  className?: string
}

const Headline: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={`mb-16 text-rich-blue-darkest ${className || ''}`}>
      <LiquidHeadline>{children}</LiquidHeadline>
    </div>
  )
}

export default Headline
