import React from 'react'
import { Redirect } from 'react-router-dom'

import { useUser } from '../../context/User'
interface Props {}

const Logout: React.FC<Props> = () => {
  const { session, logout } = useUser()
  if (session && logout) {
    logout()
    return null
  }
  return <Redirect to="/"></Redirect>
}

export default Logout
