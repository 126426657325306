import React, { useRef, useState, useEffect } from 'react'
import { Button } from '../../../../components/buttons'
import { useScrollPosition } from '../../../../components/hooks'
import { ExtractionStructure } from '../../../../typings/app/types'
import StructureDetailsField from './StructureDetailsField'

import { ReactComponent as DeleteIcon } from '../../../../assets/static/images/icon-bin.svg'
import { ReactComponent as StrikeThroughIcon } from '../../../../assets/static/images/icon-strike-through.svg'

import './style.scss'
import { StructureImage } from '../../../../components/image'

interface Props {
  structure: ExtractionStructure
  fileName: string
  onDelete: (structure: ExtractionStructure) => void
  onReportError: (structure: ExtractionStructure) => void
  className?: string
}

const StructureDetails: React.FC<Props> = ({
  structure,
  fileName,
  onDelete,
  onReportError,
  className = '',
}) => {
  const scrollRef = useRef(null)
  const containerRef = useRef(null)
  const imageRef = useRef(null)

  const [imageScale, setImageScale] = useState(1.0)

  const { scrollPosition } = useScrollPosition(
    scrollRef,
    false,
    10,
    containerRef,
    { x: 0, y: 256 }
  )

  useEffect(() => {
    let scale = 1.0 - scrollPosition.y / 256
    scale = Math.min(Math.max(scale, 0.5), 1.0)

    setImageScale(scale)
  }, [scrollPosition])

  return (
    <div className={`structure-details ${className}`}>
      <div
        className={`relative overflow-y-auto overflow-x-hidden flex flex-col justify-start h-full w-full`}
        ref={containerRef}
      >
        <div
          className={`sticky top-0 left-0 flex justify-start w-full px-8 pb-4 mb-4 bg-white transition-bounds ease-out duration-150 ${
            scrollPosition.y > 0 ? 'shadow-lg' : ''
          }`}
          style={{
            minWidth: '300%',
            maxHeight: '16rem',
            willChange: 'transform height',
            // height: `${imageScale * 16}rem`,
            transformOrigin: '0 0',
            transform: `scale(${imageScale})`,
          }}
        >
          <StructureImage
            className="object-contain h-auto max-h-full text-center text-sensitive-grey-darkest"
            structure={structure}
            ref={imageRef}
          ></StructureImage>
        </div>
        <div
          className="flex flex-wrap w-full text-sm bg-white text-rich-blue-darkest"
          ref={scrollRef}
        >
          <div className="w-full px-8 pt-8">
            <StructureDetailsField
              data-test="database-structure-details-formula"
              label="Formula"
              value={structure.formula}
            ></StructureDetailsField>
            <StructureDetailsField
              label="Smiles"
              value={structure.smiles}
            ></StructureDetailsField>
            <StructureDetailsField
              label="Molecular Weight"
              value={`${Number(structure.weight).toFixed(2)} g/mol`}
              canClipboard={false}
            ></StructureDetailsField>
            {structure.pubChemId && (
              <StructureDetailsField
                label="Pubchem Id"
                value={`${structure.pubChemId}`}
              ></StructureDetailsField>
            )}
            <StructureDetailsField
              label="Source file"
              value={`${fileName}`}
              canClipboard={false}
            ></StructureDetailsField>
          </div>
          <div className="w-full px-8 pt-8 mb-10">
            <Button
              className="w-full mb-4 text-rich-red"
              icon={<DeleteIcon />}
              onClick={() => {
                onDelete(structure)
              }}
            >
              Delete
            </Button>
            <Button
              className="w-full mb-4 text-rich-red bg-rich-red-lightest hover:bg-rich-red-lighter hover:text-rich-red-dark"
              icon={<StrikeThroughIcon className="stroke-current" />}
              onClick={() => {
                onReportError(structure)
              }}
            >
              Report Error
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StructureDetails
