import React from 'react'

import { SelectButton, OptionsButton } from '../../../../components/buttons'
import { StructureImage } from '../../../../components/image'
import { ExtractionStructure } from '../../../../typings/app/types'

interface Props {
  structure: ExtractionStructure
  structureSequence: { index: number; of: number }
  fileName: string
  selected: boolean
  selectionChanged: (value: boolean) => void
  onClick: () => void
  className?: string
}

const ExtractionGridItem: React.FC<Props> = ({
  structure,
  structureSequence,
  fileName,
  selected,
  selectionChanged,
  onClick,
  className = '',
  ...props
}) => {
  return (
    <div
      {...props}
      data-test="database-grid-structure"
      className={`p-4 ${className}`}
    >
      <div
        className={`relative h-48 bg-white rounded-md overflow-hidden cursor-pointer shadow hover:shadow-lg duration-300 transition ${
          selected ? 'shadow-lg' : ''
        }`}
      >
        <div
          className="relative flex flex-col justify-end w-full h-full"
          onClick={onClick}
        >
          <div className="flex justify-center w-full h-32">
            <StructureImage
              className="object-contain w-full h-auto max-h-full text-center text-sensitive-grey-darkest"
              structure={structure}
              lazy
            ></StructureImage>
          </div>
          <div className="flex flex-wrap w-full h-16 p-3 text-sm bg-white border-t border-solid border-sensitive-grey-dark shadow-up text-rich-blue-darkest">
            <div className="w-full">
              <div
                data-test="database-grid-structure-formula"
                className="font-extrabold truncate"
              >
                {structure.formula}
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="truncate">
                Page {structure.page} - {structureSequence.index} of{' '}
                {structureSequence.of}
              </div>
              {/* <div className="text-right truncate opacity-50 max-w-1/2 text-rich-blue-darkest">
                {fileName}
              </div> */}
            </div>
          </div>
        </div>
        <SelectButton
          data-test="database-grid-structure-checkbox"
          selected={selected}
          onChange={(value: boolean) => selectionChanged(value)}
          className="absolute top-0 left-0 p-4"
        ></SelectButton>
        <OptionsButton className="absolute top-0 right-0 hidden p-4"></OptionsButton>
      </div>
    </div>
  )
}

export { ExtractionGridItem }
