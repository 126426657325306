export enum Environment {
  Development,
  Preview,
  Production,
}

export function getEnvironment(): Environment {
  if (isProduction()) return Environment.Production
  if (isPreview()) return Environment.Preview

  return Environment.Development
}

export function isProduction(): boolean {
  if (typeof window === 'undefined') {
    if (process.env.NODE_ENV === 'production') return true
    return false
  }
  if (/^lewisapp\.com/.test(window.location.host)) return true
  return false
}
export function isPreview() {
  if (typeof window === 'undefined') {
    if (process.env.NODE_ENV === 'development') return true
    return false
  }
  if (/^dev\.lewisapp\.com/.test(window.location.host)) return true
  return false
}
export function isDevelopment() {
  if (typeof window === 'undefined') {
    if (process.env.NODE_ENV === 'production') return false
    return true
  }

  if (/^local/.test(window.location.host)) return true
  if (/^localhost/.test(window.location.hostname)) return true

  return false
}
