import React, { useState } from 'react'
import { Headline } from '@liquid-design/liquid-design-react'
import LimitIllustration from '../../../assets/static/images/illustration-limit.png'
import { ProgressBar } from '../../../components/progress'

import Card from './Card'
import { Button } from '../../../components/buttons'
import { useNotification } from '../../../context/Notification'
import { FeedbackModal } from '../../../components/modal'

interface ContentProps {
  limit: { total: number }
  onRequestRefill: () => void
}

export const LimitCardContent: React.FC<ContentProps> = ({
  limit,
  onRequestRefill,
}) => {
  return (
    <div className="w-full max-w-full px-4 mb-6 text-center">
      <div className="flex justify-center mb-4">
        <img
          className="object-contain max-w-full text-center text-sensitive-grey-darkest"
          style={{
            maxHeight: '10rem',
          }}
          src={LimitIllustration}
          alt="Illustration Limit"
        ></img>
      </div>
      <div className="flex justify-center w-full mb-16">
        <div className="w-64 text-xs text-rich-red">
          <div className="flex mb-2">
            <div className="flex-grow text-left">No conversion left</div>
            <div>0 of {limit.total}</div>
          </div>
          <ProgressBar
            className="max-w-full"
            color="rich-red"
            value={100.0}
          ></ProgressBar>
        </div>
      </div>

      <div className="max-w-full">
        <div className="mb-4">
          <Headline>You hit your conversion limit.</Headline>
        </div>
        <p className="px-4 mb-12 text-rich-blue-darkest-50">
          As we are currently in an open beta we have decided to ristrict the
          conversions. Thanks for your understanding.
        </p>
        <Button
          onClick={() => {
            onRequestRefill()
          }}
          className={`bg-vibrant-cyan text-white rounded-md hover:bg-vibrant-cyan-dark active:bg-vibrant-cyan-darker focus:outline-none mr-1`}
        >
          <div>Request a refill here</div>
        </Button>
      </div>
    </div>
  )
}

interface Props {
  limit: { total: number }
  className?: string
}

const LimitCard: React.FC<Props> = ({ limit, className, ...props }) => {
  const { info: infoNotification, error: errorNotification } = useNotification()
  const [requestRefillModalOpen, setRequestRefillModalOpen] = useState(false)

  return (
    <Card {...props} className={`${className}`}>
      <div>
        <LimitCardContent
          limit={limit}
          onRequestRefill={() => setRequestRefillModalOpen(true)}
        />
        <FeedbackModal
          feedbackType="refill"
          open={requestRefillModalOpen}
          onSubmit={(success, error) => {
            setRequestRefillModalOpen(false)

            if (success) {
              infoNotification(
                "Thank you for your request! We'll get back to you"
              )
            } else {
              errorNotification(
                `Could not submit request. ${error ? error : ''}`
              )
            }
          }}
          onClose={() => {
            setRequestRefillModalOpen(false)
          }}
        />
      </div>
    </Card>
  )
}

export default LimitCard
