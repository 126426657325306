import React from 'react'
import { RadioButton, TextField } from '@liquid-design/liquid-design-react'
import SurveyFormElementTitle from './SurveyFormElementTitle'

import '../style.scss'

export interface SurveyRadioSelectOption {
  id: string
  label: string
}

interface Props {
  title: string
  selectedOption?: SurveyRadioSelectOption
  options: SurveyRadioSelectOption[]
  allowOtherText?: boolean
  otherText?: string
  onChange: (option: SurveyRadioSelectOption) => void
  onOtherTextChange?: (value: string) => void
  className?: string
}

const SurveyRadioSelect: React.FC<Props> = ({
  title,
  selectedOption,
  options,
  allowOtherText,
  otherText,
  onChange,
  onOtherTextChange,
  className,
}) => {
  return (
    <div className={`survey__radioselect ${className}`}>
      <SurveyFormElementTitle className="mb-4">{title}</SurveyFormElementTitle>
      <div className="flex flex-col">
        {options.map((x: SurveyRadioSelectOption) => {
          return (
            <div className="flex mb-2" key={x.id}>
              <RadioButton
                label={x.label}
                isSelected={x.id === selectedOption?.id}
                onClick={() => onChange(x)}
              ></RadioButton>
              {x.id.includes('other') && allowOtherText && (
                <div
                  className={`flex-grow w-full ml-2 ${
                    x.id === selectedOption?.id ? '' : 'hidden'
                  }`}
                >
                  <TextField
                    placeholder="Please specify"
                    value={otherText}
                    onChange={onOtherTextChange}
                    grey
                    style={{
                      minWidth: '100%',
                      marginBottom: '0',
                      marginTop: '-10px',
                      transform: 'translateY(4px)',
                    }}
                  ></TextField>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SurveyRadioSelect
