import React, { ReactNode, useRef, useEffect } from 'react'

import { Header } from './components/home/header'
import { Footer } from './components/home/footer'
import { CookieMessage } from '../components/cookiemessage'
import {
  ScrollPosition,
  useScrollPosition,
} from '../components/hooks/useScrollPosition'

import './components/home/style.scss'

interface Props<T> {
  onScroll?: (position: ScrollPosition) => void
  children: ReactNode
}

const HomeLayout = <T extends object>({ onScroll, children }: Props<T>) => {
  const scrollRef = useRef(null)
  const containerRef = useRef(null)
  const { scrollPosition } = useScrollPosition(
    scrollRef,
    false,
    60,
    containerRef
  )

  useEffect(() => {
    onScroll && onScroll(scrollPosition)
  }, [scrollPosition, onScroll])

  return (
    <div className="fixed w-full h-full overflow-hidden bg-sensitive-grey text-rich-blue-darkest">
      <div className="relative flex flex-grow h-full max-h-full min-h-full">
        <main
          ref={containerRef}
          className="relative flex justify-center flex-grow h-full max-w-full max-h-full overflow-x-hidden overflow-y-auto"
        >
          <div
            ref={scrollRef}
            className={`absolute top-0 left-0 right-0 flex justify-center w-full h-full min-h-full `}
          >
            <div
              className={`flex flex-col w-full h-full min-h-full py-4`}
              style={{ maxWidth: '60rem' }}
            >
              <Header className="w-full mb-6 sm:mb-12"></Header>
              <div className="relative flex-grow">
                <div
                  className={`invisible sm:visible absolute left-auto right-auto top-0 w-full h-full pointer-events-none background-alt`}
                  style={{ maxWidth: '60rem' }}
                ></div>
                <div style={{ minHeight: 'calc(100vh - 12.5rem - 7rem)' }}>
                  {children}
                </div>
              </div>
              <Footer className="w-full"></Footer>
            </div>
          </div>
        </main>
        <CookieMessage></CookieMessage>
      </div>
    </div>
  )
}

export default HomeLayout
