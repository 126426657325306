import React, { useState } from 'react'
import { SurveyRequest } from '.'
import { useNotification } from '../../context/Notification'
import { useSurvey } from '../../context/Survey'
import { SurveyResponse } from '../../typings/app/types'
import SurveyForm from './SurveyForm'

interface Props {
  surveyId: string
  formName: string
  open: boolean
  onClose: (submitted: boolean) => void
}

const Survey: React.FC<Props> = ({ surveyId, formName, open, onClose }) => {
  const [consentGiven, setConsentGiven] = useState(false)

  const { handleSurveyResponse } = useSurvey()
  const { info: infoNotification, error: errorNotification } = useNotification()

  async function handleSubmit(response: SurveyResponse) {
    const { success, error } = await handleSurveyResponse(surveyId, response)
    onClose(success)

    if (!success) {
      errorNotification(error?.message || 'Sorry, something went wrong.')
    } else {
      infoNotification('Success. Thank you for taking the survey')
    }
  }

  async function handleDecline() {
    onClose(false)
    await handleSurveyResponse(surveyId, {})
  }

  function handleSurveyRequest(consent: boolean) {
    setConsentGiven(consent)
    if (!consent) {
      handleDecline()
    }
  }

  if (!consentGiven) {
    return (
      <SurveyRequest
        data-test="survey-request"
        open={open}
        onClose={() => handleSurveyRequest(false)}
        onAccept={() => handleSurveyRequest(true)}
      ></SurveyRequest>
    )
  }

  return (
    <SurveyForm
      data-test="survey-form"
      formName={formName}
      surveyId={surveyId}
      open={open}
      onSubmit={handleSubmit}
      onClose={handleDecline}
    ></SurveyForm>
  )
}

export default Survey
