import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react'
import { useLocation } from 'react-router'
import { Survey } from '../components/surveys'
import { fetchSurveyInquiries, sendSurveyResponse } from '../services/survey'
import { useSubscription } from '../services/user'
import { SurveyInquiry, SurveyResponse } from '../typings/app/types'
import { useUser } from './User'

export const SurveyContext = createContext<{
  pendingInquiry?: SurveyInquiry
  inquiries: SurveyInquiry[]
  handleSurveyResponse: (
    surveyId: string,
    surveyResponse: SurveyResponse
  ) => Promise<{ success: boolean; error?: Error }>
  sending: boolean
}>({
  inquiries: [],
  handleSurveyResponse: () => {
    throw Error('Not implemented yet.')
  },
  sending: false,
})

export function SurveyContextProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const location = useLocation()
  const { subscription } = useSubscription()

  const { session } = useUser()
  const [inquiries, setInquiries] = useState<SurveyInquiry[] | undefined>([])
  const [pendingInquiry, setPendingInquiry] = useState<
    SurveyInquiry | undefined
  >(inquiries && inquiries.length ? inquiries[0] : undefined)
  const [sending, setSending] = useState(false)
  const [open, setOpen] = useState(true)

  const [userSessionUpdate, setUserSessionUpdate] = useState<
    number | undefined
  >()
  const surveyDelay = 1 * 60 // 1 Minute
  const [surveyDelayTimeout, setSurveyDelayTimeout] = useState<
    NodeJS.Timeout | undefined
  >()
  const [surveyDelayPassed, setSurveyDelayPassed] = useState(false)
  const [inquiryRequestTimestamp, setInquiryRequestTimestamp] = useState<
    Date | undefined
  >()

  useEffect(() => {
    async function updateInquiries(accessToken: string) {
      setInquiryRequestTimestamp(new Date())
      const { surveyItems } = await fetchSurveyInquiries(accessToken)
      setInquiries(surveyItems)
    }

    if (
      inquiryRequestTimestamp &&
      new Date().getTime() - inquiryRequestTimestamp.getTime() < 30000
    ) {
      return
    }

    if (session && session.accessToken) {
      updateInquiries(session.accessToken)
    }
  }, [
    session,
    userSessionUpdate,
    location,
    inquiryRequestTimestamp,
    subscription,
  ])

  useEffect(() => {
    if (session && userSessionUpdate !== session.updated) {
      setUserSessionUpdate(session.updated)
    }
  }, [session, userSessionUpdate])

  useEffect(() => {
    if (!userSessionUpdate) return

    let timeoutDuration = surveyDelay * 1000
    if (Date.now() - userSessionUpdate > surveyDelay * 1000) {
      timeoutDuration = 5 * 1000
    }

    const timeout = setTimeout(() => {
      setSurveyDelayPassed(true)
    }, timeoutDuration)
    setSurveyDelayTimeout(timeout)

    return () => {
      surveyDelayTimeout && clearTimeout(surveyDelayTimeout)
    }
  }, [userSessionUpdate]) // eslint-disable-line

  const handleSurveyResponse = useCallback(
    async (
      surveyId: string,
      response: SurveyResponse
    ): Promise<{ success: boolean; error?: Error }> => {
      if (!session?.accessToken) {
        return { success: false, error: undefined }
      }

      setSending(true)
      const { response: success, error } = await sendSurveyResponse(
        surveyId,
        response,
        session.accessToken
      )
      setSending(false)

      return { success, error }
    },
    [setSending, session]
  )

  useEffect(() => {
    setPendingInquiry(inquiries && inquiries.length ? inquiries[0] : undefined)
  }, [inquiries])

  return (
    <SurveyContext.Provider
      value={useMemo(() => {
        return {
          inquiries: inquiries || [],
          pendingInquiry: pendingInquiry,
          handleSurveyResponse: handleSurveyResponse,
          sending: sending,
        }
      }, [inquiries, pendingInquiry, handleSurveyResponse, sending])}
    >
      {children}
      {pendingInquiry && surveyDelayPassed && (
        <Survey
          surveyId={pendingInquiry.surveyId}
          formName={pendingInquiry.formName}
          open={open}
          onClose={(submitted: boolean) => {
            setOpen(false)
          }}
        ></Survey>
      )}
    </SurveyContext.Provider>
  )
}

export function useSurvey() {
  return useContext(SurveyContext)
}
