import React from 'react'

import { ReactComponent as CrossIcon } from '../../../../assets/static/images/icon-cross.svg'

import { ExtractionStructure } from '../../../../typings/app/types'
import { ExportType } from '../../../../services/export'

import StructureDetails from './StructureDetails'
import { ExportButton } from '..'

interface Props {
  structure: ExtractionStructure | null
  fileName: string | null
  className?: string
  exportPending: boolean
  onClose?: () => void
  onExport?: (structure: ExtractionStructure, type: ExportType) => void
  onDelete: (structure: ExtractionStructure) => void
  onReportError: (structure: ExtractionStructure) => void
}

const StructureDetailsContainer: React.FC<Props> = ({
  structure,
  fileName,
  className,
  exportPending,
  onClose,
  onExport,
  onDelete,
  onReportError,
}) => {
  return (
    <div
      data-test="database-structure-details-container"
      className={`structure-details-container bg-white h-screen ${className}`}
    >
      <div className="flex flex-col w-full h-full">
        <div className="z-20 flex flex-row items-center justify-start w-full px-4 pt-8 mb-6">
          <button className={`focus:outline-none m-1`} onClick={onClose}>
            <div className={`relative rounded-full w-4 h-4 cursor-pointer`}>
              <CrossIcon
                className={`absolute left-0 top-0 w-full h-full`}
              ></CrossIcon>
            </div>
          </button>
          <ExportButton
            className="flex items-center justify-end flex-grow bg-white rounded-md"
            label="Export"
            exportPending={exportPending}
            onExport={(type) => {
              if (structure) {
                onExport && onExport(structure, type)
              }
            }}
          ></ExportButton>
        </div>
        {structure && (
          <StructureDetails
            className="flex-grow w-full overflow-y-hidden"
            structure={structure}
            fileName={fileName || ''}
            onReportError={onReportError}
            onDelete={onDelete}
          ></StructureDetails>
        )}
      </div>
    </div>
  )
}

export default StructureDetailsContainer
