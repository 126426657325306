import React from 'react'
import { ReactComponent as CheckmarkIcon } from '../../../assets/static/images/icon-checkmark.svg'

interface Props {
  selected: boolean
  onChange: (value: boolean) => void
  className?: string
}

const SelectButton: React.FC<Props> = ({
  selected,
  onChange,
  className = '',
  ...props
}) => {
  const baseClasses = selected
    ? 'bg-rich-blue-darkest border-rich-blue-darkest'
    : 'bg-white border-rich-blue-darkest hover:border-rich-blue-dark'

  const checkmarkClasses = selected
    ? 'opacity-100 scale-100'
    : 'opacity-0 scale-0'

  return (
    <div
      {...props}
      className={`${className}`}
      onClick={(e) => {
        onChange(!selected)
      }}
    >
      <div
        className={`relative w-5 h-5 rounded-full cursor-pointer border-solid border-2 transition-colors duration-0 focus:outline-none ${baseClasses}`}
      >
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
          <CheckmarkIcon
            className={`transition duration-0 ease-in transform ${checkmarkClasses}`}
          ></CheckmarkIcon>
        </div>
      </div>
    </div>
  )
}

export default SelectButton
