// import { nanoid } from 'nanoid'

import { apiBase } from '../lib'
import { request } from '../lib/fetch'
import { ListJobsResponseJson } from '../typings/api/types'
import {
  // Extraction,
  ExtractionJob,
  // ExtractionState,
} from '../typings/app/types'

// function createMockExtraction(): Extraction {
//   const jobId = nanoid()
//   return {
//     jobId: jobId,
//     state: ExtractionState.completed,
//     job: {
//       jobId: jobId,
//       pages: {
//         failed: 0,
//         processed: 2,
//         total: 4,
//       },
//       structures: {
//         failed: 0,
//         processed: 2,
//         total: 4,
//       },
//       file: {
//         name: 'whatever.pdf',
//         size: 223344,
//       },
//       finished: true,
//       uploadDate: '2019-08-24T14:15:22Z',
//     },
//     structures: [
//       {
//         smiles: 'O=C(O)c1ccccc1OCF',
//         weight: '170.13899999999995',
//         confidence: 0.06739543169879339,
//         inChIKey: 'BXLCIWIBPUSDSV-UHFFFAOYSA-N',
//         formula: 'C8H7FO3',
//         structureId: nanoid(6),
//         page: 1,
//         sequence: 1,
//         feedback: {
//           code: 'string',
//           text: 'string',
//         },
//         imageUrl: {
//           png: 'https://picsum.photos/270/200',
//           svg: 'https://blablabla.com/vector',
//           mol: 'https://blablabla.com/mol',
//         },
//       },
//       {
//         smiles: 'O=C(O)c1ccccc1OCF',
//         weight: '170.13899999999995',
//         confidence: 0.06739543169879339,
//         inChIKey: 'BXLCIWIBPUSDSV-UHFFFAOYSA-N',
//         formula: 'C8H7FO3',
//         structureId: nanoid(6),
//         page: 1,
//         sequence: 1,
//         feedback: {
//           code: 'string',
//           text: 'string',
//         },
//         imageUrl: {
//           png: 'https://picsum.photos/270/200',
//           svg: 'https://blablabla.com/vector',
//           mol: 'https://blablabla.com/mol',
//         },
//       },
//       {
//         smiles: 'O=C(O)c1ccccc1OCF',
//         weight: '170.13899999999995',
//         confidence: 0.06739543169879339,
//         inChIKey: 'BXLCIWIBPUSDSV-UHFFFAOYSA-N',
//         formula: 'C8H7FO3',
//         structureId: nanoid(6),
//         page: 1,
//         sequence: 1,
//         feedback: {
//           code: 'string',
//           text: 'string',
//         },
//         imageUrl: {
//           png: 'https://picsum.photos/270/200',
//           svg: 'https://blablabla.com/vector',
//           mol: 'https://blablabla.com/mol',
//         },
//       },
//       {
//         smiles: 'O=C(O)c1ccccc1OCF',
//         weight: '170.13899999999995',
//         confidence: 0.06739543169879339,
//         inChIKey: 'BXLCIWIBPUSDSV-UHFFFAOYSA-N',
//         formula: 'C8H7FO3',
//         structureId: nanoid(6),
//         page: 1,
//         sequence: 1,
//         feedback: {
//           code: 'string',
//           text: 'string',
//         },
//         imageUrl: {
//           png: 'https://picsum.photos/270/200',
//           svg: 'https://blablabla.com/vector',
//           mol: 'https://blablabla.com/mol',
//         },
//       },
//     ],
//   }
// }

export async function fetchJobs(
  accessToken: string,
  nextPage?: string
): Promise<{
  response?: { jobs: ExtractionJob[]; next?: string } | null
  error: Error | null
}> {
  const path = `${apiBase}/job${nextPage ? nextPage : ''}`

  try {
    const { response, body, error } = await request<ListJobsResponseJson>(
      path,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )

    if (response.ok) {
      const allJobs: ExtractionJob[] = body?.jobs ?? []

      return { response: { jobs: allJobs, next: body?.nextPage }, error: error }
    }

    throw error
  } catch (error) {
    return { response: null, error: error }
  }
}

export async function deleteJob(
  accessToken: string,
  jobId: string
): Promise<{ response: boolean; error: Error | null }> {
  const path = `${apiBase}/job/${jobId}`

  try {
    const { response, error } = await request<any>(path, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${accessToken}` },
    })

    return { response: response.ok, error: error }
  } catch (err) {
    return { response: false, error: err }
  }
}
