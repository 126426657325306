import React, { createContext, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'

import { aemSrc } from '../util/analytics'

export const AnalyticsContext = createContext<{
  updateConsent: () => void
}>({
  updateConsent: () => {
    throw new Error('Not Implemented')
  },
})

interface Props {
  children?: React.ReactNode
}

const AnalyticsProvider: React.FC<Props> = ({ children }) => {
  const [aem, setAem] = useState(aemSrc())

  const updateConsent = () => {
    setAem(aemSrc())
  }

  return (
    <AnalyticsContext.Provider value={{ updateConsent: updateConsent }}>
      <Helmet>
        <script src={aem} async></script>
      </Helmet>
      {children}
    </AnalyticsContext.Provider>
  )
}

function useAnalytics() {
  return useContext(AnalyticsContext)
}

export { AnalyticsProvider, useAnalytics }
