import React from 'react'

interface Props {
  title: string
  wrap?: boolean
}

const TitleValue: React.FC<Props> = ({ title, wrap = false, children }) => {
  return (
    <div>
      <span className="font-extrabold">{title}:</span>
      {wrap ? <br /> : ' '}
      {children}
    </div>
  )
}

export default TitleValue
