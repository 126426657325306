import React from 'react'
import { Button as LiquidDesignButton } from '@liquid-design/liquid-design-react'
import { Loader } from '../../loader'

interface LiquidButtonProps {
  appearance?: 'primary' | 'secondary' | 'highlight' | 'ghost'
  disabled?: boolean
  size?: 'small' | 'big'
  onClick?: () => void
  icon?: JSX.Element
  children?: JSX.Element | string
  className?: string
  title?: string
}

const LiquidButton: React.FC<LiquidButtonProps> = ({
  appearance = 'primary',
  disabled = false,
  size = 'small',
  onClick,
  icon,
  children,
  className,
  title,
}) => {
  return (
    <LiquidDesignButton
      appearance={appearance}
      disabled={disabled}
      size={size}
      className={`${className}`}
      onClick={onClick}
      title={title}
    >
      <div className="flex items-center text-sm">
        {icon && <div className="mr-3 -ml-3">{icon}</div>}
        <div className="">{children}</div>
      </div>
    </LiquidDesignButton>
  )
}

interface ButtonProps {
  disabled?: boolean
  onClick?: () => void
  icon?: JSX.Element
  size?: 'big' | 'small'
  children?: JSX.Element | string
  className?: string
}

const Button: React.FC<ButtonProps> = ({
  disabled = false,
  onClick,
  icon,
  size = 'big',
  children,
  className,
  ...props
}) => {
  const roundedClass = className?.includes('rounded') ? '' : 'rounded-md'
  const backgroundClasses = className?.includes('bg-')
    ? ''
    : 'bg-sensitive-grey hover:bg-sensitive-grey-dark active:bg-sensitive-grey-darker'

  const textClasses = className?.includes('text-') ? '' : 'text-rich-blue'

  return (
    <button
      {...props}
      disabled={disabled}
      className={`inline-flex justify-center items-center py-2 ${
        size === 'big' ? 'h-10' : 'h-8'
      } border-none outline-none transition-colors duration-150 ${roundedClass} leading-none cursor-pointer ${textClasses} ${backgroundClasses} ${className}`}
      onClick={onClick}
    >
      <div className="flex items-center px-8 font-extrabold">
        {icon && <div className="mr-3 -ml-3">{icon}</div>}
        <div className="text-sm">{children}</div>
      </div>
    </button>
  )
}

interface LoaderButtonProps extends ButtonProps {
  loading: boolean
}

const LoaderButton: React.FC<LoaderButtonProps> = ({
  loading,
  disabled = false,
  onClick,
  icon,
  size = 'big',
  children,
  className,
  ...props
}) => {
  const roundedClass = className?.includes('rounded') ? '' : 'rounded-md'
  const backgroundClasses = className?.includes('bg-')
    ? ''
    : 'bg-sensitive-grey hover:bg-sensitive-grey-dark active:bg-sensitive-grey-darker'

  const textClasses = className?.includes('text-') ? '' : 'text-rich-blue'

  return (
    <button
      {...props}
      disabled={disabled || loading}
      className={`inline-flex justify-center items-center py-2 ${
        size === 'big' ? 'h-10' : 'h-8'
      } border-none outline-none transition-colors duration-150 ${roundedClass} leading-none cursor-pointer ${textClasses} ${backgroundClasses} ${className}`}
      onClick={onClick}
    >
      {loading ? (
        <div>
          <div className="transform scale-90">
            <Loader size="small" loading={true} />
          </div>
        </div>
      ) : (
        <div className="flex items-center px-8 font-extrabold">
          {icon && <div className="mr-3 -ml-3">{icon}</div>}
          <div className="text-sm">{children}</div>
        </div>
      )}
    </button>
  )
}

export { LiquidButton, Button, LoaderButton }
