import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'

import { LightBox } from '@liquid-design/liquid-design-react'

import { FeedbackModal } from '../modal'

import { Head } from './head'
import { Item } from './item'
import { User } from './user'

import { ReactComponent as IconDatabase } from '../../assets/static/images/icon-database.svg'
import { ReactComponent as IconUpload } from '../../assets/static/images/icon-upload.svg'
import { ReactComponent as IconMail } from '../../assets/static/images/icon-mail.svg'

import './style.scss'
import { useMediaQuery } from '../hooks/useMediaQuery'
import { Breakpoint } from '../../util'
import Subscription from './subscription/Subscription'
import { useNotification } from '../../context/Notification'
import { LimitCardContent } from '../../pages/Upload/components/LimitCard'
import { useSubscription } from '../../services/user'
import { labelForUserSubscriptionPlan } from '../../typings/app/types'
import { DateTime } from 'luxon'

interface Props {
  className?: string
}

const Sidebar: React.FC<Props> = ({ className }) => {
  const location = useLocation()
  const isMd = useMediaQuery(Breakpoint.md)

  const { subscription, pageSubscription } = useSubscription()

  const { info: infoNotification, error: errorNotification } = useNotification()

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const [limitModalOpen, setLimitModalOpen] = useState(false)
  const [requestRefillModalOpen, setRequestRefillModalOpen] = useState(false)

  const [subscriptionExceeded, setSubscriptionExceeded] = useState(false)
  useEffect(() => {
    if (!pageSubscription) {
      return
    }
    setSubscriptionExceeded(pageSubscription.exceeded)
  }, [pageSubscription])

  useEffect(() => {
    if (!subscriptionExceeded || !pageSubscription) {
      return
    }

    setLimitModalOpen(
      (subscriptionExceeded && !(Cookies.get('lewis_limit_modal') === '1')) ||
        false
    )

    Cookies.set('lewis_limit_modal', '1', {
      expires: DateTime.fromISO(pageSubscription.end).toJSDate(),
    })
  }, [subscriptionExceeded]) // eslint-disable-line

  return (
    <div className={className} id="sidebar">
      <div
        className={`flex flex-col h-full bg-vibrant-cyan transition-bounds duration-150 ease-out overflow-hidden ${
          isMd ? 'w-56' : 'w-20'
        }`}
      >
        <div className="">
          <Head isCollapsed={!isMd}></Head>
        </div>
        <div className="flex-grow">
          <Link to="/upload">
            <Item
              data-test="sidebar-item-upload"
              label="Upload"
              icon={<IconUpload></IconUpload>}
              isCollapsed={!isMd}
              isSelected={location.pathname === '/upload'}
            ></Item>
          </Link>
          <Link to="/database">
            <Item
              data-test="sidebar-item-database"
              label="My Database"
              icon={<IconDatabase></IconDatabase>}
              isCollapsed={!isMd}
              isSelected={location.pathname === '/database'}
            ></Item>
          </Link>
        </div>
        <div className="">
          <div>
            <Subscription
              className="px-6 py-5 text-sm text-white"
              planName={labelForUserSubscriptionPlan(subscription?.plan.planId)}
              subscriptionDimension={pageSubscription}
              onRequestRefill={() => {
                setRequestRefillModalOpen(true)
              }}
            ></Subscription>
            <LightBox
              open={limitModalOpen}
              onClose={() => setLimitModalOpen(false)}
            >
              <div className="pt-5">
                <LimitCardContent
                  limit={{ total: pageSubscription?.limit || 0 }}
                  onRequestRefill={() => {
                    setLimitModalOpen(false)
                    setRequestRefillModalOpen(true)
                  }}
                ></LimitCardContent>
              </div>
            </LightBox>
            <FeedbackModal
              feedbackType="refill"
              open={requestRefillModalOpen}
              onSubmit={(success, error) => {
                setRequestRefillModalOpen(false)

                if (success) {
                  infoNotification(
                    "Thank you for your request! We'll get back to you"
                  )
                } else {
                  errorNotification(
                    `Could not submit request. ${error ? error : ''}`
                  )
                }
              }}
              onClose={() => {
                setRequestRefillModalOpen(false)
              }}
            />
          </div>
          <div>
            <Item
              data-test="sidebar-item-feedback"
              label="Give Feedback"
              icon={<IconMail></IconMail>}
              isCollapsed={!isMd}
              onClick={
                () => setFeedbackModalOpen(true)
                // window.location.assign('mailto:feedback@lewisapp.com')
              }
              isSelected={false}
            ></Item>
            <FeedbackModal
              feedbackType="general"
              open={feedbackModalOpen}
              onSubmit={(success, error) => {
                setFeedbackModalOpen(false)

                if (success) {
                  infoNotification('Thank you for your feedback!')
                } else {
                  errorNotification(
                    `Could not submit feedback. ${error ? error : ''}`
                  )
                }
              }}
              onClose={() => {
                setFeedbackModalOpen(false)
              }}
            />
          </div>
        </div>
        <div>
          <User isCollapsed={!isMd}></User>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
