import { Extraction, ExtractionStructure } from '../typings/app/types'

const extractQuery = (searchTerm: string): string[] => {
  return searchTerm
    .split(' ')
    .map((x) => x.toLowerCase())
    .filter((x) => x)
}

const filterExtractionByTerm = (
  extraction: Extraction,
  searchTerm?: string,
  ignoreStructures = false
): boolean => {
  if (!searchTerm) return true

  const query = extractQuery(searchTerm)
  return query.every((queryItem) => {
    return (
      extraction.job?.file.name.toLowerCase().includes(queryItem) ||
      (!ignoreStructures &&
        extraction.structures &&
        extraction.structures.filter((x) => filterStructureByTerm(x, queryItem))
          .length > 0) ||
      false
    )
  })
}

const filterStructureByTerm = (
  structure: ExtractionStructure,
  searchTerm?: string
): boolean => {
  if (!searchTerm) return true

  const query = extractQuery(searchTerm)
  return query.every((queryItem) => {
    return (
      structure.formula.toLowerCase().includes(queryItem) ||
      (structure.inChIKey &&
        structure.inChIKey.toLowerCase().includes(queryItem)) ||
      (structure.pubChemId &&
        `${structure.pubChemId}`.toLowerCase().includes(queryItem)) ||
      (structure.smiles &&
        `${structure.smiles}`.toLowerCase().includes(queryItem)) ||
      false
    )
  })
}

export { filterExtractionByTerm, filterStructureByTerm }
