import React from 'react'
import { Checkbox } from '@liquid-design/liquid-design-react'
import SurveyFormElementTitle from './SurveyFormElementTitle'

import '../style.scss'

export interface SurveyMultiSelectOption {
  id: string
  label: string
  selected: boolean
}

interface Props {
  title: string
  options: SurveyMultiSelectOption[]
  onChange: (options: SurveyMultiSelectOption[]) => void
  className?: string
}

const SurveyMultiSelect: React.FC<Props> = ({
  title,
  options,
  onChange,
  className,
}) => {
  function handleOnChange(option: SurveyMultiSelectOption) {
    const changedOption = options.find((x) => option.id === x.id)
    if (changedOption) {
      changedOption.selected = !changedOption.selected
      onChange(options)
    }
  }

  return (
    <div className={`survey__multiselect ${className}`}>
      <SurveyFormElementTitle className="mb-4">{title}</SurveyFormElementTitle>
      <div className="flex flex-col">
        {options.map((x: SurveyMultiSelectOption) => (
          <Checkbox
            key={x.id}
            className="mb-2"
            label={x.label}
            isChecked={x.selected}
            onChange={() => handleOnChange(x)}
          ></Checkbox>
        ))}
      </div>
    </div>
  )
}

export default SurveyMultiSelect
