import React, { useState } from 'react'
import {
  LightBox,
  Dropdown,
  TextField,
  Theme,
} from '@liquid-design/liquid-design-react'
import { Button } from '../buttons'

import { StructureErrorFeedbackCode } from '../../services/feedback'
import { ExtractionStructure } from '../../typings/app/types'
import { StructureImage } from '../image'

const errorOptions = [
  {
    id: StructureErrorFeedbackCode.STRUCTURE,
    name: 'Structure completely wrong',
  },
  {
    id: StructureErrorFeedbackCode.STRUCTURE_PARTIAL,
    name: 'Only parts of the structure were correct',
  },
  { id: StructureErrorFeedbackCode.ELEMENTS, name: 'Wrong elements' },
  { id: StructureErrorFeedbackCode.CONNECTIVITY, name: 'Wrong connectivity' },
  {
    id: StructureErrorFeedbackCode.MOLECULES,
    name: 'Wrong number of molecules',
  },
  { id: StructureErrorFeedbackCode.OTHER, name: 'Other error' },
]

interface Props {
  open: boolean
  structure?: ExtractionStructure
  onClose: () => void
  onSubmit: (
    structure: ExtractionStructure,
    type: StructureErrorFeedbackCode,
    text?: string
  ) => void
  className?: string
}

const ReportErrorModal: React.FC<Props> = ({
  open,
  structure,
  onClose,
  onSubmit,
  className,
}) => {
  const [selectedErrorType, setSelectedErrorType] = useState<
    string | undefined
  >()
  const [text, setText] = useState<string | undefined>()

  function handleOnClose() {
    setSelectedErrorType(undefined)
    onClose()
  }

  return (
    <LightBox
      open={open}
      onClose={handleOnClose}
      style={{ maxWidth: '450px', width: '450px' }}
    >
      <div
        className={`${className || ''}`}
        data-test="database-lightbox-report-error"
      >
        <div className="mb-2 text-3xl font-extrabold">Report Error</div>
        <div className="mb-6 text-rich-blue-darkest-50">
          Please help us make Lewis better.
        </div>
        <div className="px-3 mb-12">
          <div className="flex justify-start">
            {structure && (
              <StructureImage
                className="object-contain h-40 text-center text-sensitive-grey-darkest"
                structure={structure}
              ></StructureImage>
            )}
          </div>
          <div className="mb-2 text-sm text-rich-blue-darkest-50">Smiles</div>
          <div className="text-sm font-extrabold break-all">
            {structure?.smiles}
          </div>
        </div>
        <Theme themeName="richBlue">
          <div className="flex flex-col">
            <div className="w-full mb-4">
              <Dropdown
                label="Error Type"
                options={errorOptions}
                value={selectedErrorType}
                onSubmit={(value: { id: string; name: string }): void => {
                  console.log(value.id)
                  setSelectedErrorType(value.id)
                }}
              ></Dropdown>
            </div>
            <TextField
              multiline
              grey
              placeholder="Please describe the error in more detail."
              onChange={(value: string) => setText(value)}
            ></TextField>
          </div>
        </Theme>
        <div className="flex justify-between">
          <Button
            data-test="database-lightbox-report-error-button-cancel"
            size="big"
            className="w-1/2 mr-2"
            onClick={handleOnClose}
          >
            Cancel
          </Button>
          <Button
            data-test="database-lightbox-report-error-button-submit"
            className={`w-1/2 ml-2 text-white bg-rich-blue hover:bg-rich-blue-dark ${
              selectedErrorType ? '' : 'pointer-events-none opacity-25'
            }`}
            onClick={() => {
              if (selectedErrorType && structure) {
                onSubmit(
                  structure,
                  selectedErrorType as StructureErrorFeedbackCode,
                  text
                )
              }
              handleOnClose()
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </LightBox>
  )
}

export default ReportErrorModal
