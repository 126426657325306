import React from 'react'
import Clipboard from 'clipboard'
import { nanoid } from 'nanoid'

import { ReactComponent as CopyIcon } from '../../../../assets/static/images/icon-copy.svg'
import { useNotification } from '../../../../context/Notification'

interface Props {
  label: string
  value?: string
  canClipboard?: boolean
  className?: string
}

const StructureDetailsField: React.FC<Props> = ({
  label,
  value,
  canClipboard = true,
  className = '',
  ...props
}) => {
  const { info } = useNotification()

  const clipboardId = '_' + nanoid(9) // Math.random().toString(36).substr(2, 9)
  const clipboard = new Clipboard(`#${clipboardId}`)

  clipboard.on('success', (e) => {
    info(`Copied: ${value}`)
    e.clearSelection()
  })

  return (
    <div
      {...props}
      className={`${canClipboard ? 'mb-8' : 'mb-6'} ${className}`}
    >
      <div
        className={`${
          canClipboard ? 'mb-4' : 'mb-2'
        } tracking-wide uppercase text-rich-blue-darkest-50`}
      >
        {label}
      </div>
      <div className="flex flex-row w-full">
        <div
          className={`flex-grow px-3 py-2 -my-2 -ml-3 overflow-y-auto text-lg font-extrabold break-all rounded-md  text-rich-blue-darkest ${
            canClipboard ? 'bg-sensitive-grey' : ''
          }`}
          style={{ maxHeight: '7rem' }}
        >
          {value ? value : 'N/A'}
        </div>
        {value && canClipboard && (
          <div>
            <button
              id={clipboardId}
              data-clipboard-text={value || ''}
              className={`ml-2 focus:outline-none`}
            >
              <div className={`rounded-full w-6 h-6 cursor-pointer`}>
                <CopyIcon className={`w-full h-full`}></CopyIcon>
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default StructureDetailsField
