import React from 'react'
import { DateTime } from 'luxon'
import { ReactComponent as IconPlan } from '../../../assets/static/images/icon-plan.svg'
import { ReactComponent as IconRefill } from '../../../assets/static/images/icon-refill.svg'
import { ProgressRing } from '../../progress'
import { UserSubscriptionDimension } from '../../../typings/app/types'

interface Props {
  planName?: string
  subscriptionDimension?: UserSubscriptionDimension
  onRequestRefill: () => void
  className?: string
}

const Subscription: React.FC<Props> = ({
  planName,
  subscriptionDimension,
  onRequestRefill,
  className = '',
}) => {
  const subscriptionEndDate: DateTime | undefined = subscriptionDimension
    ? DateTime.fromISO(subscriptionDimension.end)
    : undefined

  const usageRate = subscriptionDimension
    ? subscriptionDimension.usageRate
    : 0.0

  return (
    <div>
      <div className={`${className}`}>
        <SubscriptionSidebarItem
          planName={planName || ''}
          usage={{
            limit: subscriptionDimension?.limit || 0,
            left: subscriptionDimension?.left || 0,
          }}
          usageRate={usageRate}
          endDate={subscriptionEndDate}
          requestRefill={() => {
            onRequestRefill()
          }}
        ></SubscriptionSidebarItem>
      </div>
    </div>
  )
}

interface SidebarItemProps {
  planName: string
  usageRate: number
  usage: { left: number; limit: number }
  endDate?: DateTime
  requestRefill: () => void
  className?: string
}

const SubscriptionSidebarItem: React.FC<SidebarItemProps> = ({
  planName,
  usageRate,
  usage,
  endDate,
  requestRefill,
  className,
}) => {
  const dateLabel = endDate
    ? `${endDate.toISODate()} ${endDate.toISOTime()}`
    : ''

  let subscriptionEndLabel = undefined
  if (endDate) {
    if (endDate.hasSame(DateTime.utc(), 'day')) {
      subscriptionEndLabel = `until ${endDate.toFormat('hh:mm')}`
    } else if (endDate.diffNow('days').days < 1) {
      subscriptionEndLabel = 'for today'
    } else {
      subscriptionEndLabel = `until ${endDate.toFormat('MMM d')}`
    }
  }

  let textColor = 'text-white'
  let bgColor = 'bg-white'

  if (usageRate >= 0.75) {
    textColor = 'text-vibrant-yellow'
    bgColor = 'bg-vibrant-yellow'
  }

  return (
    <div className={`${textColor} ${className}`}>
      <div className="flex items-center w-full mb-2 md:mb-4">
        <div className="flex items-center justify-center w-full capitalize md:justify-start">
          <IconPlan className="mr-0 md:mr-2"></IconPlan>
          <div
            data-test="sidebar-subscription-plan"
            className="hidden md:block"
          >
            {planName} Plan
          </div>
        </div>
        {usageRate >= 1.0 && (
          <div className="hidden md:block hover:opacity-75">
            <button
              className="flex items-center font-extrabold focus:outline-none"
              onClick={() => {
                requestRefill()
              }}
            >
              <IconRefill className="mr-2 stroke-current"></IconRefill>Refill
            </button>
          </div>
        )}
      </div>
      {true && (
        <div>
          <div className="hidden md:block">
            <div className={`flex mb-2 text-xs font-extrabold`}>
              <div className="flex-grow" title={dateLabel}>
                Pages left {subscriptionEndLabel}
              </div>
              <div>
                {usage.left} / {usage.limit}
              </div>
            </div>
            <div className="relative w-full h-1 overflow-hidden rounded-full">
              <div className="absolute top-0 left-0 w-full h-full bg-white opacity-25"></div>
              <div
                className={`absolute top-0 left-0 w-full h-full transition-transform duration-300 ease-out transform ${bgColor} opacity-100`}
                style={{
                  transformOrigin: 'left',
                  transform: `scale(${usageRate}, 1)`,
                }}
              ></div>
            </div>
          </div>
          <div className="flex flex-col justify-center md:hidden">
            <div className="text-xs font-extrabold text-center">
              <div>{usage.left}</div>
              <div className="h-px mx-1 bg-white"></div>
              <div>{usage.limit}</div>
            </div>
            <ProgressRing
              progress={1.0 - usageRate}
              stroke={3}
              radius={14}
              className="w-full"
            ></ProgressRing>
          </div>
        </div>
      )}
    </div>
  )
}

export default Subscription
