import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface Props {
  to?: string
  target?: string
  onClick?: () => void
  className?: string
}

const Link: React.FC<Props> = ({
  to,
  target = '',
  onClick,
  className = 'font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline',
  children,
  ...props
}) => {
  if (to) {
    const relativeLink = to[0] === '/'

    if (relativeLink) {
      return (
        <RouterLink
          target={target}
          to={to}
          className={className}
          rel="noopener noreferrer"
          {...props}
        >
          {children}
        </RouterLink>
      )
    }

    return (
      <a
        {...props}
        target={target}
        className={className}
        href={to}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }

  return (
    <span
      className={`cursor-pointer ${className}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </span>
  )
}

export default Link
