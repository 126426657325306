import React from 'react'

interface Props {
  bold?: boolean
  children?: JSX.Element | string
  className?: string
}

const BetaItem: React.FC<Props> = ({ bold = true, children, className }) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <div
        className="mb-2 bg-rich-blue-darker"
        style={{ height: '0.1875rem', width: '2.25rem' }}
      ></div>
      <div
        className={`${
          bold ? 'font-extrabold' : ''
        } text-rich-blue-darker text-lg`}
      >
        {children}
      </div>
    </div>
  )
}

export default BetaItem
