import React from 'react'
import { Headline } from '@liquid-design/liquid-design-react'

import Card from './Card'
import { UploadDropzone } from '../../../components/upload'

interface Props {
  onDrop?: (files: File[]) => void
  className?: string
}

const DropCard: React.FC<Props> = ({ onDrop, className, ...props }) => {
  return (
    <Card {...props} className={`${className}`}>
      <div className="w-full px-4 mb-12 text-center">
        <Headline className="">
          Upload your files to extract chemical structures
        </Headline>
        <p>
          You can upload the following file types:{' '}
          <strong>.pdf, .jpg, .png, .tiff</strong>
        </p>
      </div>
      <div className="">
        <UploadDropzone
          allowedTypes={['image/*', 'application/pdf']}
          onDrop={(files: File[]) => onDrop && onDrop(files)}
          inputId="lewis__upload__input"
        ></UploadDropzone>
      </div>
    </Card>
  )
}

export default DropCard
