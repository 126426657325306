import { DateTime } from 'luxon'
import { saveAs } from 'file-saver'

import { apiBase } from '../lib'

import { request, requestFile } from '../lib/fetch'

import { ExtractionStructure, Export } from '../typings/app/types'
import { useUser } from '../context/User'
import { jobIdForStructure } from './structures'
import { sleep } from '../util'
import { useState } from 'react'

export enum ExportType {
  Csv = '.csv',
  Sdf = '.sdf',
  Smi = '.smi',
}

function exportFormat(type: ExportType): 'CSV' | 'SMI' | 'SDF' {
  switch (type) {
    case '.csv':
      return 'CSV'
    case '.sdf':
      return 'SDF'
    case '.smi':
      return 'SMI'
    default:
      throw new Error(`Invalid Export: ${type}`)
  }
}

export function exportTypeFromString(type: string) {
  switch (type) {
    case '.csv':
      return ExportType.Csv
    case '.sdf':
      return ExportType.Sdf
    case '.smi':
      return ExportType.Smi
    default:
      throw new Error(`Invalid String for ExportType: ${type}`)
  }
}

export interface ExportOption {
  id: ExportType
  name: string
}

export const exportOptions: ExportOption[] = [
  {
    id: ExportType.Csv,
    name: '.csv',
  },
  {
    id: ExportType.Sdf,
    name: '.sdf',
  },
  {
    id: ExportType.Smi,
    name: '.smi',
  },
]

function createFileName(ext: string) {
  const timestamp = DateTime.local().toFormat('yyyyMMdd-HHmmss')
  return `${timestamp}--lewis-export${ext}`
}

function download(filename: string, content: string): void {
  // eslint-disable-next-line no-undef
  const blob = new Blob([content], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, filename)
}

async function fetchMolFile(structure: ExtractionStructure): Promise<string> {
  const sdfUrl = structure.imageUrl?.sdf
  if (!sdfUrl) {
    return ''
  }

  const molFile = await requestSdf(sdfUrl)
  return molFile || ''
}

async function exportSdf(structures: ExtractionStructure[]): Promise<boolean> {
  let sdf = ''
  for (const structure of structures) {
    const mol = await fetchMolFile(structure)
    if (mol) {
      sdf = sdf.concat(mol)
      sdf = sdf.concat('\n$$$$\n')
    }
  }

  console.log(sdf)
  const fileName = createFileName('.sdf')
  download(fileName, sdf)

  return true
}

async function exportCsv(structures: ExtractionStructure[]): Promise<boolean> {
  let csv = 'Smiles,InChIKey,g/mol\n'
  for (const structure of structures) {
    csv += `${structure.smiles},${structure.inChIKey || ''},${
      structure.weight
    }\n`
  }

  console.log(csv)
  const fileName = createFileName('.csv')
  download(fileName, csv)

  return true
}

async function exportSmi(
  structures: ExtractionStructure[],
  extension = '.smi'
): Promise<boolean> {
  let smi = ''
  for (const structure of structures) {
    smi += `${structure.smiles} ${structure.inChIKey}\n`
  }

  console.log(smi)
  const fileName = createFileName(extension)
  download(fileName, smi)

  return true
}

export async function exportFile(
  structures: ExtractionStructure[],
  type: ExportType
): Promise<boolean> {
  switch (type) {
    case ExportType.Sdf:
      return await exportSdf(structures)
    case ExportType.Csv:
      return await exportCsv(structures)
    case ExportType.Smi:
      return await exportSmi(structures)
    default:
      break
  }
  return false
}

async function requestSdf(url: string): Promise<string | undefined> {
  try {
    const fileUrl = url

    const [{ ok }, res] = await requestFile(fileUrl)
    if (ok) {
      return res
    }
  } catch (err) {
    console.error('Unable to fetch .sdf file', err)
  }

  return undefined
}

async function requestExport(
  structures: ExtractionStructure[],
  type: ExportType,
  accessToken?: string
): Promise<{ downloadUrl?: string; error?: Error }> {
  let path = `${apiBase}/export`

  const jobs: { jobId: string; structureIds: string[] }[] = []
  structures.forEach((structure) => {
    const jobId = jobIdForStructure(structure)
    if (!jobs.some((x) => x.jobId === jobId)) {
      jobs.push({ jobId: jobId, structureIds: [] })
    }

    const exportJob = jobs.find((x) => x.jobId === jobId)
    exportJob?.structureIds.push(structure.structureId)
  })

  const { response, body, error } = await request<Export>(path, {
    method: 'POST',
    headers: { Authorization: `Bearer ${accessToken}` },
    body: {
      format: exportFormat(type),
      jobs: jobs && jobs.length ? jobs : undefined,
    },
  })

  if (!response.ok || error || !body || !body.exportId || body.errorCode) {
    return {
      error:
        error ||
        Error(`Export failed${body?.errorCode ? ': ' + body.errorCode : ''}`),
    }
  }

  if (body.downloadUrl) {
    return { downloadUrl: body.downloadUrl }
  }

  const exportId = body.exportId
  let downloadUrl: string | undefined

  while (!downloadUrl) {
    path = `${apiBase}/export/${exportId}`
    const {
      response: exportResponse,
      body: exportBody,
      error: exportError,
    } = await request<Export>(path, {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    })

    if (exportBody?.status === 'FINISHED' && exportBody.downloadUrl) {
      downloadUrl = exportBody.downloadUrl
    }

    if (
      !exportResponse.ok ||
      exportError ||
      !exportBody ||
      exportBody.errorCode ||
      (exportBody.status === 'FINISHED' && !exportBody.downloadUrl)
    ) {
      return {
        error:
          exportError ||
          Error(
            `Export failed${
              exportBody?.errorCode ? ': ' + exportBody.errorCode : ''
            }`
          ),
      }
    }

    await sleep(1000)
  }

  return { downloadUrl: downloadUrl }
}

export function useExport() {
  const user = useUser()
  const [exportPending, setPending] = useState<boolean>(false)

  async function exportStructures(
    structures: ExtractionStructure[],
    type: ExportType
  ) {
    setPending(true)
    const result = await requestExport(
      structures,
      type,
      user.session?.accessToken
    )
    setPending(false)

    return result
  }

  return { exportStructures, exportPending }
}
