import React from 'react'
import { Headline as LiquidHeadline } from '@liquid-design/liquid-design-react'

interface Props {
  title?: string
  id?: string
  className?: string
}

const Block: React.FC<Props> = ({ title, id, children, className }) => {
  return (
    <div id={id} className={`mb-6 ${className || ''}`}>
      {title && (
        <div className={`mb-6 text-rich-blue-darkest`}>
          <LiquidHeadline type="H4">{title}</LiquidHeadline>
        </div>
      )}
      <div>{children}</div>
    </div>
  )
}

export default Block
