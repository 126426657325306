import React, { useState } from 'react'
import { Dropdown } from '@liquid-design/liquid-design-react'

import { Button } from '../../../components/buttons'

import {
  ExportOption,
  exportOptions,
  ExportType,
  exportTypeFromString,
} from '../../../services/export'

import './style.scss'
import { Loader } from '../../../components/loader'

interface Props {
  label?: string
  exportPending: boolean
  onExport?: (type: ExportType) => void
  className?: string
}

const ExportButton: React.FC<Props> = ({
  label = 'Export',
  exportPending,
  onExport,
  className,
}) => {
  const [selectedExportOption, setSelectedExportOption] = useState<string>(
    exportOptions[0].id
  )

  return (
    <div
      className={`flex items-center justify-end ${className}${
        exportPending ? ' pointer-events-none' : ''
      } button-export`}
    >
      <Button
        className={`bg-vibrant-cyan text-white rounded-md hover:bg-vibrant-cyan-dark active:bg-vibrant-cyan-darker focus:outline-none mr-1`}
        onClick={() => {
          onExport && onExport(exportTypeFromString(selectedExportOption))
        }}
      >
        <div className="relative">
          <div
            className={`absolute flex items-center justify-center top-0 left-0 w-full h-full ${
              exportPending ? 'visible' : 'hidden'
            }`}
          >
            <Loader className="" size="small" loading={true} />
          </div>
          <div className={`${exportPending ? 'opacity-0' : 'opacity-100'}`}>
            {label}
          </div>
        </div>
      </Button>
      <Dropdown
        className="min-w-0"
        label=""
        options={exportOptions}
        value={selectedExportOption}
        onSubmit={(value: ExportOption): void => {
          setSelectedExportOption(value.id)
        }}
      />
    </div>
  )
}

export default ExportButton
