import React from 'react'

interface Props {
  title: string
  children?: JSX.Element | string
  image: string
  className?: string
}

const ImageAndText: React.FC<Props> = ({
  title,
  children,
  image,
  className,
}) => {
  return (
    <div className={`${className}`}>
      <div className="flex items-stretch w-full h-full">
        <div className="w-1/3 -my-8">
          <img
            className="object-contain max-w-full text-center text-sensitive-grey-darkest"
            style={{
              maxHeight: '10rem',
            }}
            src={image}
            alt={title}
          ></img>
        </div>
        <div className="flex flex-col justify-start w-2/3 min-h-full text-rich-blue-darker">
          <div className={`text-xl mb-2`}>{title}</div>
          <div className="text-base">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default ImageAndText
