import React from 'react'
import { useSurvey } from '../../../context/Survey'
import { SurveyResponse } from '../../../typings/app/types'
import Survey1 from './Survey1'

export interface FormProps {
  sending: boolean
  onSubmit: (response: SurveyResponse) => void
}

interface Props {
  formName: string
  surveyId: string
  onSubmit: (surveyResponse: SurveyResponse) => void
  className?: string
}

export const Form: React.FC<Props> = ({
  formName,
  surveyId,
  onSubmit,
  className,
}) => {
  const { sending } = useSurvey()

  switch (formName) {
    case 'survey1':
      return <Survey1 onSubmit={onSubmit} sending={sending}></Survey1>

    default:
      return <></>
  }
}
