import React from 'react'
import { LightBox } from '@liquid-design/liquid-design-react'
import { LiquidButton } from '../buttons'

import { ReactComponent as SurveyRequestHeaderIllustration } from '../../assets/static/images/illustration_survey_header.svg'
import { Logo } from '../logo'
import SurveyCloseButton from './components/SurveyCloseButton'

interface Props {
  open: boolean
  onClose: () => void
  onAccept: () => void
  className?: string
}

const SurveyRequest: React.FC<Props> = ({
  open,
  onClose,
  onAccept,
  className = '',
  ...props
}) => {
  return (
    <LightBox
      open={open}
      onClose={onClose}
      style={{ maxWidth: '450px', width: '450px' }}
      reactModalProps={{ shouldCloseOnOverlayClick: false }}
    >
      <div {...props} className={`text-rich-blue-darkest ${className}`}>
        <div
          className="relative bg-rich-blue-darkest"
          style={{
            margin: '-35px -50px -50px',
            height: '13rem',
          }}
        >
          <Logo className="px-4 pt-4 text-white" showBeta={false}></Logo>
          <SurveyRequestHeaderIllustration
            className="absolute bottom-0 left-0 w-full h-auto px-4"
            style={{ marginBottom: '-2.4rem' }}
          ></SurveyRequestHeaderIllustration>
          <div className="absolute top-0 right-0">
            <SurveyCloseButton
              data-test="survey-request-button-close"
              onClick={onClose}
            ></SurveyCloseButton>
          </div>
        </div>
        <div className="mt-24 text-sm">
          <h2 className="mb-4 text-2xl font-extrabold">
            What do you think about Lewis?
          </h2>
          <p className="mb-4">
            Lewis Software Engineers, Data Scientists and many others are
            excited to further develop Lewis! But it’s so difficult to decide
            which functionalities we should focus on.
          </p>
          <p className="mb-8">
            Could you help us out by answering a few questions?
          </p>
          <LiquidButton onClick={onAccept} className="w-full">
            Start survey
          </LiquidButton>
        </div>
      </div>
    </LightBox>
  )
}

export default SurveyRequest
