import { apiBase } from '../lib'
import { request } from '../lib/fetch'

import { CreateJobResponseJson } from '../typings/api/types'
import {
  FileUploadResponse,
  FileUpload,
  FileUploadState,
} from '../typings/app/types'

export async function upload(
  file: File,
  accessToken: string,
  progressCallback?: (uploadProgress: FileUpload) => void
): Promise<FileUploadResponse | null> {
  const methodCreateJob = 'POST'
  const pathCreateJob = `${apiBase}/job`

  try {
    const { body, error } = await request<CreateJobResponseJson>(
      pathCreateJob,
      {
        method: methodCreateJob,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: { file: { name: file.name } },
      }
    )

    if (error) {
      progressCallback &&
        progressCallback({
          file,
          state: FileUploadState.pending,
          progress: 0.0,
          error,
        })
      return null
    }

    if (!body) {
      progressCallback &&
        progressCallback({
          file,
          state: FileUploadState.pending,
          progress: 0.0,
          error: new Error('Invalid CreateJob Response'),
        })
      return null
    }

    const signedUploadUrl = body.signedUploadUrl
    const jobId = body.jobId
    const uploadDate = body.uploadDate

    const fileUploadInfo: FileUploadResponse = {
      jobId: jobId,
      uploadDate: uploadDate,
      file: file,
    }

    const { success: sendFileSuccess, error: sendFileError } = await sendFile(
      file,
      signedUploadUrl,
      (value: number) => {
        progressCallback &&
          progressCallback({
            file,
            state: FileUploadState.uploading,
            progress: value,
          })
      }
    )

    if (sendFileSuccess) {
      progressCallback &&
        progressCallback({ file, state: FileUploadState.done, progress: 1.0 })
    } else {
      progressCallback &&
        progressCallback({
          file,
          state: FileUploadState.error,
          progress: 1.0,
          error: sendFileError,
        })
    }

    return fileUploadInfo
  } catch (error) {
    progressCallback &&
      progressCallback({
        file,
        state: FileUploadState.error,
        progress: 0.0,
        error,
      })

    return null
  }
}

async function sendFile(
  file: File,
  uploadUrl: string,
  progressCallback?: (value: number) => void
): Promise<{ success: boolean; status: number; error?: Error }> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.upload.onprogress = (e: ProgressEvent<EventTarget>) => {
      const progress = e.loaded / e.total
      progressCallback && progressCallback(progress)
    }

    xhr.onreadystatechange = () => {
      // Ignore state changes prior to completion
      if (xhr.readyState !== 4) return

      let body
      const contentType = xhr.getResponseHeader('Content-Type')

      if (contentType && contentType.includes('application/json')) {
        body = JSON.parse(xhr.responseText)
      }

      if (xhr.status >= 200 && xhr.status < 300) {
        resolve({ success: true, status: xhr.status })
        return
      }

      reject({
        success: false,
        status: xhr.status,
        error: new Error(`${body ? body : xhr.status}`),
      })
    }

    xhr.open('PUT', uploadUrl, true)
    xhr.send(file)
  })
}
