import React from 'react'

interface Props {
  value: number
  color?: 'vibrant-green' | 'vibrant-yellow' | 'vibrant-cyan' | 'rich-red'
  className?: string
}

const ProgressBar: React.FC<Props> = ({
  value,
  color = 'vibrant-green',
  className,
}) => {
  const bgColor = `bg-${color}`

  return (
    <div
      className={`relative h-2 rounded-full overflow-hidden bg-sensitive-grey-dark ${className}`}
    >
      <div
        className={`absolute w-full h-full ${bgColor} transform origin-left transition duration-500 ease-in-out`}
        style={{ transform: `scale(${value / 100}, 1)` }}
      ></div>
    </div>
  )
}

export default ProgressBar
