import React from 'react'
import dayjs from 'dayjs'

import { Extraction } from '../../../../typings/app/types'

import { SelectButton } from '../../../../components/buttons'
import { truncateMiddle } from '../../../../util/string'

interface Props {
  extraction: Extraction
  selected: boolean
  selectionChanged: (value: boolean) => void
  className?: string
}

const ExtractionGridSectionHeader: React.FC<Props> = ({
  extraction,
  selected,
  selectionChanged,
  className,
}) => {
  return (
    <div className={`flex items-center p-4 ${className}`}>
      <SelectButton
        data-test="database-grid-job-checkbox"
        selected={selected}
        onChange={(value: boolean) => selectionChanged(value)}
        className="mr-3"
      ></SelectButton>
      <div className="mr-3">
        <span data-test="database-grid-job-date" className="font-extrabold">
          {dayjs(extraction.job?.uploadDate).isValid()
            ? dayjs(extraction.job?.uploadDate).format('MMMM D, YYYY - HH:mm')
            : 'Recent Upload'}
        </span>{' '}
        <span
          data-test="database-grid-job-filename"
          title={extraction.job?.file.name}
        >
          - {truncateMiddle(extraction.job?.file.name || '', 16, 4, '[...]')}
        </span>
      </div>
      <div className="flex-grow h-px opacity-25 bg-rich-blue-darkest"></div>
    </div>
  )
}

export { ExtractionGridSectionHeader }
