import React from 'react'

import { ReactComponent as Hexagon } from '../../assets/static/images/hexagon.svg'
import { ReactComponent as HexagonSmall } from '../../assets/static/images/hexagon-small.svg'

interface Props {
  size?: 'default' | 'small'
  loading: boolean
  className?: string
}

const Loader: React.FC<Props> = ({ loading, size = 'default', className }) => {
  const Icon = size === 'default' ? Hexagon : HexagonSmall

  return (
    <div className={className}>
      {
        <Icon
          className={`stroke-current ${loading ? 'animate-spin-ease' : ''}`}
        ></Icon>
      }
    </div>
  )
}

export default Loader
