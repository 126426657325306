import React, { ReactNode } from 'react'

import { Sidebar } from '../components/sidebar'

interface Props<T> {
  children: ReactNode
}

const ApplicationLayout = <T extends object>({ children }: Props<T>) => {
  return (
    <div className="fixed flex flex-col items-stretch w-full h-screen overflow-hidden bg-sensitive-grey text-rich-blue-darkest">
      <div className="flex flex-grow h-full overflow-auto">
        <Sidebar className="h-full"></Sidebar>
        <main className="flex-grow h-full min-h-screen overflow-auto">
          {children}
        </main>
      </div>
    </div>
  )
}

export default ApplicationLayout
