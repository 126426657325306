import React from 'react'
import { Headline } from '@liquid-design/liquid-design-react'

import HomeLayout from '../../layouts/HomeLayout'

interface Props {}

const Auth: React.FC<Props> = () => {
  return (
    <HomeLayout>
      <div className="flex flex-col items-center pt-20">
        <div className="p-16 bg-white card card-active">
          <Headline className="w-full text-center">
            You are now logged in.
            <br />
            Welcome to Lewis.
          </Headline>
          <div className="w-full mt-4 text-center">
            Please wait, you'll be redirected
          </div>
        </div>
      </div>
    </HomeLayout>
  )
}

export default Auth
