import React from 'react'
import { Link } from '../../../../pages/Legal/components'

import { ReactComponent as IconArrowRight } from '../../../../assets/static/images/icon-arrow-right.svg'
import { ReactComponent as Hexagon } from '../../../../assets/static/images/hexagon-small.svg'

import './style.scss'

interface Props {
  className?: string
}

const Footer: React.FC<Props> = ({ className }) => {
  return (
    <div
      id="home-footer"
      className={`text-sm w-full pt-8 pb-8 px-4 text-white -mb-4 ${className} footer__background`}
      style={{ minHeight: 'auto' }}
    >
      <div className="flex flex-col w-full mb-12 text-sm sm:flex-row">
        <div className="flex flex-col mb-4 text-2xl font-extrabold sm:mb-0 sm:flex-row">
          Start converting molecules.
        </div>
        <div className="flex-grow"></div>

        <Link data-test="home-footer-button-cta" className="flex items-center" to="/database">
          <button className="focus:outline-none">
            <div className="flex font-extrabold text-vibrant-cyan">
              <div className="mr-2 -mt-px text-base whitespace-no-wrap">
                Convert your first molecule
              </div>
              <IconArrowRight className="footer__button-icon"></IconArrowRight>
            </div>
          </button>
        </Link>
      </div>
      <div className="flex-grow w-full border-b border-white opacity-10"></div>
      <div className="flex flex-col w-full pt-8 text-sm sm:flex-row">
        <div
          data-test="footer-container-links"
          className="flex flex-col items-start sm:items-center sm:flex-row"
        >
          <Link className="mb-6 mr-6 sm:mb-0" to="/">
            <div className="flex items-center text-xs text-white">
              <Hexagon className="w-5 h-5 mr-2 footer__logo-icon"></Hexagon>
              <div className="mr-2 uppercase">
                <span className="font-extrabold">Lewis</span>App
              </div>
              <div className="px-1 py-px font-extrabold tracking-widest text-center text-white uppercase border-2 border-white rounded text-2xs">
                <div className="-mr-px">Beta</div>
              </div>
            </div>
          </Link>
          <Link
            className="mb-1 mr-6 text-sm tracking-wider text-white opacity-50 hover:underline sm:mb-0"
            data-test="footer-link-privacy"
            to="/privacy"
          >
            Data Privacy
          </Link>
          <Link
            className="mb-1 mr-6 text-sm tracking-wider text-white opacity-50 hover:underline sm:mb-0"
            data-test="footer-link-terms"
            to="/terms"
          >
            Website Terms of Use
          </Link>
          <Link
            className="mb-1 mr-6 text-sm tracking-wider text-white opacity-50 hover:underline sm:mb-0"
            data-test="footer-link-service-agreements"
            to="/serviceagreement"
          >
            Lewis Service Agreement
          </Link>
          <Link
            className="mb-1 mr-6 text-sm tracking-wider text-white opacity-50 hover:underline sm:mb-0"
            data-test="footer-link-imprint"
            to="/imprint"
          >
            Imprint
          </Link>
        </div>
        <div className="flex-grow mb-4 sm:mb-0"></div>
        <div className="flex items-center text-sm text-white opacity-50">
          <div>© EMD Digital Inc. 2020</div>
        </div>
      </div>
    </div>
  )
}

export default Footer
