import { produce } from 'immer'
import create from 'zustand'

import { apiBase } from '../lib'
import { request } from '../lib/fetch'
import { subscribeSocket, unsubscribeSocket } from '../lib/websockets'
import { StreamSocketResponseJson } from '../typings/api/websocket'

import { UserInfo, useUser } from '../context/User'
import {
  UserSubscription,
  UserSubscriptionDimension,
} from '../typings/app/types'
import { useEffect, useState } from 'react'

type SubscriptionStore = {
  subscription?: UserSubscription
  error?: Error
  debounceUpdate?: NodeJS.Timeout
  socketSubscription?: string
  update: (fn: (store: SubscriptionStore) => void) => void
}

const useSubscriptionStore = create<SubscriptionStore>((set) => ({
  update: (fn: any) => set(produce(fn)),
}))

async function fetchSubsciption(
  accessToken: string
): Promise<{
  subscription?: UserSubscription
  error?: Error
}> {
  const path = `${apiBase}/user/subscription`
  try {
    const { response, body, error } = await request<UserSubscription>(path, {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    })

    if (response.ok && body) {
      return { subscription: body, error: undefined }
    }

    if (error) {
      throw error
    }
  } catch (error) {
    return { subscription: undefined, error: error }
  }

  return { subscription: undefined, error: undefined }
}

async function updateSubsciption(
  update: (fn: (store: SubscriptionStore) => void) => void,
  accessToken: string
) {
  const { subscription: sub, error: err } = await fetchSubsciption(accessToken)

  // // TODO: Debug
  // const pageItem = sub?.items.find((x) => x.dimension === 'PAGE')
  // if (pageItem) {
  //   pageItem.usage.consumed += 100
  // }
  // console.log(sub)

  if (err) console.error(err)

  update((store) => {
    store.subscription = sub
    store.error = err
  })
}

async function registerForUpdates(
  update: (fn: (store: SubscriptionStore) => void) => void,
  accessToken?: string
) {
  try {
    if (accessToken) {
      const subscription = await subscribeSocket<StreamSocketResponseJson>(
        accessToken,
        (message) => {
          if (message.Items.some((x) => x.Type === 'Job')) {
            update((store) => {
              if (store.debounceUpdate) {
                clearTimeout(store.debounceUpdate)
              }

              store.debounceUpdate = setTimeout(() => {
                updateSubsciption(update, accessToken || '')
              }, 2000)
            })
          }
        }
      )

      update((store) => {
        const pendingSubscription = store.socketSubscription
        store.socketSubscription = subscription

        if (pendingSubscription) unsubscribeSocket(pendingSubscription)
      })

      return
    }
  } catch (error) {
    console.error(error)
  }
}

export function useSubscription(): {
  subscription?: UserSubscription
  pageSubscription?: UserSubscriptionDimension
  error?: Error
} {
  const user = useUser()
  const [accessToken, setAccessToken] = useState(user.session?.accessToken)
  const { subscription, error, update } = useSubscriptionStore((state) => state)
  const [pageSubscription, setPageSubscription] = useState<
    UserSubscriptionDimension | undefined
  >()

  useEffect(() => {
    if (accessToken) {
      updateSubsciption(update, accessToken)
      registerForUpdates(update, accessToken)
    }
  }, [accessToken, update])

  useEffect(() => {
    setAccessToken(user.session?.accessToken)
  }, [user])

  useEffect(() => {
    if (!subscription) {
      setPageSubscription(undefined)
      return
    }

    const pageSubscription = subscription?.items.find(
      (x) => x.dimension === 'PAGE'
    )
    if (!pageSubscription) {
      setPageSubscription(undefined)
    } else {
      const totalUsed = Math.min(
        pageSubscription.usage.consumed,
        pageSubscription.usage.limit
      )
      const usageRate = totalUsed / pageSubscription.usage.limit
      setPageSubscription({
        end: subscription.periodEnd,
        used: totalUsed,
        left: pageSubscription.usage.limit - totalUsed,
        limit: pageSubscription.usage.limit,
        usageRate: usageRate,
        exceeded: usageRate >= 1.0,
      })
    }
  }, [subscription])

  return { subscription, pageSubscription, error }
}

export function mailtoRequestRefill(userInfo?: UserInfo) {
  const emailAddress = 'refill@lewisapp.com'
  const body = `Hi Lewis Team,\n\nI want to request a refill.\n\n\n\n\n${
    userInfo?.givenName || ''
  }${userInfo?.givenName ? ' ' : ''}${userInfo?.familyName || ''}\nUserId: ${
    userInfo?.sub
  }`
  const subject = 'Refill'
  return `mailto:${emailAddress}?subject=${subject}&body=${encodeURIComponent(
    body
  )}`
}
