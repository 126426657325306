import React from 'react'
import SurveyFormElementTitle from './SurveyFormElementTitle'

export const npsOptions: SurveyValueSelectOption[] = [
  { value: 0 },
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
]

export interface SurveyValueSelectOption {
  value: string | number
  label?: string
}

interface Props {
  title: string
  options: SurveyValueSelectOption[]
  selectedOption?: SurveyValueSelectOption
  onChange: (value: SurveyValueSelectOption) => void
  className?: string
}

const SurveyValueSelect: React.FC<Props> = ({
  title,
  options,
  selectedOption,
  onChange,
  className,
}) => {
  return (
    <div className={`survey__value-select ${className}`}>
      <SurveyFormElementTitle className="mb-4">{title}</SurveyFormElementTitle>
      <div className="flex h-10">
        {options.map((option, index) => {
          let className =
            'flex-grow h-full mx-px hover:bg-vibrant-cyan-lightest'
          className += index === 0 ? ' rounded-l-lg' : ''
          className += index === options.length - 1 ? ' rounded-r-lg' : ''
          className +=
            selectedOption?.value === option.value
              ? ' pointer-events-none bg-vibrant-cyan text-white font-extrabold'
              : ' bg-sensitive-grey'
          return (
            <button
              key={option.value}
              className={className}
              onClick={() => onChange(option)}
            >
              {option.label || option.value}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default SurveyValueSelect
