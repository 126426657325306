import React, { useState, useEffect, useRef } from 'react'

import {
  ExtractionStructure,
  Extraction,
  ExtractionState,
} from '../../typings/app/types'

import { useNotification } from '../../context/Notification'
import { useExtractions } from '../../services/extractions'

import { Searchbar } from '../../components/searchbar'
import { ProgressButton } from '../../components/buttons'
import { Empty, ExportButton, ExtractionGrid, Hoverbar } from './components'

import ApplicationLayout from '../../layouts/ApplicationLayout'
import { Loader } from '../../components/loader'
import StructureDetailsContainer from './components/Details/StructureDetailsContainer'

import { ExportType, useExport } from '../../services/export'
import {
  filterExtractionByTerm,
  filterStructureByTerm,
} from '../../services/serach'

import './style.scss'
import { structuresForIds } from '../../services/structures'
import { ConfirmDeleteModal } from '../../components/modal'
import ReportErrorModal from '../../components/modal/ReportErrorModal'
import {
  StructureErrorFeedbackCode,
  useFeedback,
} from '../../services/feedback'

interface Props {}

const Database: React.FC<Props> = () => {
  const scrollContainerRef = useRef(null)
  const [error] = useState<string | null>()

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const [deleteModalAction, setDeleteModalAction] = useState<() => void>(
    () => () => {
      console.warn('Delete Modal Action not defined')
    }
  )

  const [errorFeedbackStructure, setErrorFeedbackStructure] = useState<
    ExtractionStructure | undefined
  >()

  const [selectedStructureDetails, setSelectedStructureDetails] = useState<{
    structure: ExtractionStructure
    fileName: string
  } | null>(null)
  const [selectedStructures, setSelectedStructures] = useState<string[]>([])
  // Used to select extractions which currently have no structures as all are pending.
  const [
    selectedIncompleteExtractions,
    setSelectedIncompleteExtractions,
  ] = useState<string[]>([])
  const {
    loading,
    pending,
    extractions,
    fetchExtractions,
    deleteStructures,
    activateUpdates,
    deactivateUpdates,
  } = useExtractions()

  useEffect(() => {
    activateUpdates()
    return () => {
      deactivateUpdates()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { structureErrorFeedback } = useFeedback()
  const { exportStructures, exportPending } = useExport()

  const [searchTerm, setSearchTerm] = useState<string | undefined>()

  const { error: errorNotification, info: infoNotification } = useNotification()

  useEffect(() => {
    if (!error) return

    errorNotification(error)
  }, [error, errorNotification])

  useEffect(() => {
    if (
      selectedStructureDetails &&
      !filterStructureByTerm(selectedStructureDetails.structure, searchTerm)
    ) {
      setSelectedStructureDetails(null)
    }
  }, [searchTerm, selectedStructures, selectedStructureDetails])

  const [filteredExtractions, setfilteredExtractions] = useState<Extraction[]>(
    []
  )
  useEffect(() => {
    const filtered = extractions.filter(
      (x) =>
        x.job &&
        x.job.structures.total &&
        !(
          x.state === ExtractionState.completed && x.structures?.length === 0
        ) &&
        (!searchTerm || filterExtractionByTerm(x, searchTerm))
    )
    setfilteredExtractions(filtered)
  }, [extractions, searchTerm])

  useEffect(() => {
    if (searchTerm && filteredExtractions.length === 0 && !pending) {
      fetchExtractions()
    }
  }, [searchTerm, filteredExtractions, fetchExtractions, pending])

  const handleRequestMoreExtractions = () => {
    if (loading || pending) {
      return
    }

    fetchExtractions()
  }

  async function handleExportStructures(
    structures: ExtractionStructure[],
    type: ExportType
  ) {
    const { downloadUrl, error } = await exportStructures(structures, type)
    // const result = await exportFile(structures, type)
    if (error || !downloadUrl) {
      errorNotification(error?.message || 'Export failed')
      return
    }

    document.location.href = downloadUrl
  }

  function handleDeleteStructures(structures: ExtractionStructure[]) {
    deleteStructures(
      structures,
      extractions.filter(
        (x) => x.jobId && selectedIncompleteExtractions.includes(x.jobId)
      ),
      (_, message) => {
        errorNotification(message)
      }
    )
  }

  async function handleReportError(
    structure: ExtractionStructure,
    errorCode: StructureErrorFeedbackCode,
    text?: string
  ) {
    const { success, error } = await structureErrorFeedback(
      structure,
      errorCode,
      text
    )

    if (error || !success) {
      errorNotification('Feedback failed')
    } else {
      infoNotification('Thank you for your feedback')
    }
  }

  return (
    <ApplicationLayout>
      <div id="database" className="relative flex flex-row">
        <div className="relative flex flex-col items-start justify-center w-full max-h-screen min-h-screen pt-8 database__mainContainer">
          <div className="w-full px-8 mb-6 database__head">
            <div className="flex items-center mb-5">
              <Searchbar
                className="flex-grow mr-4"
                onChange={(search?: string) => {
                  setSearchTerm(search)
                }}
              >
                Searchbar
              </Searchbar>
              <ProgressButton className="mr-4"></ProgressButton>
              <div className="flex items-center justify-end">
                <ExportButton
                  label="Export All"
                  exportPending={exportPending}
                  onExport={(exportType: ExportType) => {
                    handleExportStructures([], exportType)
                  }}
                  className="z-20"
                ></ExportButton>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-start flex-grow w-full px-4 pb-6 overflow-y-auto database__content"
            ref={scrollContainerRef}
          >
            {filteredExtractions.length <= 0 && !(searchTerm && pending) && (
              <Empty
                loading={loading || pending}
                searching={searchTerm !== undefined}
              ></Empty>
            )}
            {filteredExtractions.length > 0 && (
              <ExtractionGrid
                extractions={filteredExtractions}
                selectedStructures={structuresForIds(
                  selectedStructures,
                  extractions
                )}
                selectedIncompleteExtractions={extractions.filter(
                  (x) =>
                    x.jobId && selectedIncompleteExtractions.includes(x.jobId)
                )}
                selectionChanged={(structures: ExtractionStructure[]) =>
                  setSelectedStructures(structures.map((x) => x.internalId))
                }
                incompleteExtractionSelectionChanged={(
                  incompleteExtractions: Extraction[]
                ) => {
                  const incompleteExtractionsIds: string[] = []
                  incompleteExtractions.forEach((x) => {
                    if (x.jobId) {
                      incompleteExtractionsIds.push(x.jobId)
                    }
                  })
                  setSelectedIncompleteExtractions(incompleteExtractionsIds)
                }}
                onStructureClick={(
                  structure: ExtractionStructure,
                  fileName: string
                ) =>
                  setSelectedStructureDetails(
                    structure === selectedStructureDetails?.structure
                      ? null
                      : { structure: structure, fileName: fileName }
                  )
                }
                requestMoreExtractions={handleRequestMoreExtractions}
                containerRef={scrollContainerRef}
                searchTerm={searchTerm}
              ></ExtractionGrid>
            )}
            {pending && (filteredExtractions.length > 0 || searchTerm) && (
              <div className="flex justify-center w-full mb-4">
                <div>
                  <Loader loading={true} size="small"></Loader>
                </div>
              </div>
            )}
          </div>
          <Hoverbar
            className=""
            active={
              selectedStructures.length > 0 ||
              selectedIncompleteExtractions.length > 0
            }
            exportPending={exportPending}
            onDelete={() => {
              setDeleteModalAction(() => () => {
                handleDeleteStructures(
                  structuresForIds(selectedStructures, extractions)
                )
                setSelectedStructures([])
              })
              setConfirmDeleteModalOpen(true)
            }}
            onExport={(type: ExportType) => {
              handleExportStructures(
                structuresForIds(selectedStructures, extractions),
                type
              )
            }}
          ></Hoverbar>
        </div>
        <StructureDetailsContainer
          structure={selectedStructureDetails?.structure || null}
          fileName={selectedStructureDetails?.fileName || null}
          exportPending={exportPending}
          onClose={() => {
            setSelectedStructureDetails(null)
          }}
          onExport={(structure: ExtractionStructure, type: ExportType) => {
            handleExportStructures([structure], type)
          }}
          onDelete={(structure: ExtractionStructure) => {
            setDeleteModalAction(() => () => {
              handleDeleteStructures([structure])
              setSelectedStructureDetails(null)
            })
            setConfirmDeleteModalOpen(true)
          }}
          onReportError={(structure: ExtractionStructure) => {
            setErrorFeedbackStructure(structure)
          }}
          // absolute z-20 right-0 top-0
          className={`max-h-screen min-h-screen transition-bounds duration-300 ease-in-out database__detailContainer ${
            selectedStructureDetails !== null
              ? 'database__detailContainer--open'
              : ''
          }`}
        ></StructureDetailsContainer>
        <ConfirmDeleteModal
          open={confirmDeleteModalOpen}
          onClose={() => {
            setConfirmDeleteModalOpen(false)
          }}
          onConfirm={() => {
            deleteModalAction()
            setConfirmDeleteModalOpen(false)
          }}
        />
        <ReportErrorModal
          open={errorFeedbackStructure ? true : false}
          structure={errorFeedbackStructure}
          onClose={() => {
            setErrorFeedbackStructure(undefined)
          }}
          onSubmit={(
            structure: ExtractionStructure,
            code: StructureErrorFeedbackCode,
            text?: string
          ) => {
            handleReportError(structure, code, text)
          }}
        />
      </div>
    </ApplicationLayout>
  )
}

export default Database
