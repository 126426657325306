import React from 'react'
import { LightBox } from '@liquid-design/liquid-design-react'
import { Button } from '../buttons'

interface Props {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  className?: string
}

const ConfirmDeleteModal: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  className,
}) => {
  return (
    <LightBox open={open} onClose={onClose} style={{ maxWidth: '450px' }}>
      <div className={className} data-test="database-lightbox-confirm-delete">
        <div className="mb-2 text-3xl font-extrabold">Delete Structures</div>
        <div className="mb-12 text-rich-blue-darkest-50">
          Do you want to delete these strcutures permamently?
        </div>
        <div className="flex justify-between">
          <Button
            data-test="database-lightbox-confirm-delete-button-cancel"
            size="big"
            className="w-1/2 mr-2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-test="database-lightbox-confirm-delete-button-delete"
            className="w-1/2 ml-2 text-white bg-rich-red hover:bg-rich-red-dark"
            onClick={onConfirm}
          >
            Delete
          </Button>
        </div>
      </div>
    </LightBox>
  )
}

export default ConfirmDeleteModal
