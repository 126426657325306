import { getEnvironment, Environment } from './env'
import Cookies from 'js-cookie'

const aemDevelopment =
  '//assets.adobedtm.com/b5a2629b807e/54b245595db3/launch-021c069487a7-development.min.js'
const aemStaging =
  '//assets.adobedtm.com/b5a2629b807e/54b245595db3/launch-0822a6a7f478-staging.min.js'
const aemProduction =
  '//assets.adobedtm.com/b5a2629b807e/54b245595db3/launch-abc8fe96e4d2.min.js'

const canTrack = () => {
  return (
    typeof window !== 'undefined' &&
    !(navigator.doNotTrack || window.doNotTrack) &&
    !Cookies.get('analytics_optout') &&
    !!Cookies.get('lewis_cookies_accepted')
  )
}

export const aemSrc = () => {
  if (!canTrack()) return ''
  switch (getEnvironment()) {
    case Environment.Development:
      return aemDevelopment

    case Environment.Preview:
      return aemStaging

    case Environment.Production:
      return aemProduction

    default:
      return ''
  }
}
