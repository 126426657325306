import { useState, useEffect, useCallback } from 'react'
import querystring from 'querystring'

type QueryStatus = 'success' | 'loading' | 'error'

type Method = 'POST' | 'GET' | 'PUT' | 'DELETE'

type Body = { [key: string]: any }

export async function request<T>(
  input: string,
  args: {
    method?: Method
    body?: Body | string
    headers?: { [key: string]: string }
    queryParameters?: { [key: string]: string }
  } = {}
): Promise<{ response: Response; body: T | null; error: Error | null }> {
  const qs = querystring.stringify(args.queryParameters)
  const res = await fetch(qs ? `${input}?${qs}` : input, {
    headers: {
      'content-type': 'application/json',
      ...args.headers,
    },
    method: args.method,
    body:
      args.method === 'GET'
        ? undefined
        : typeof args.body === 'object'
        ? JSON.stringify(args.body)
        : args.body,
  })
  if (res.status === 401) {
    const auth = res.headers.get('www-authenticate');
    if (auth && auth.match(/JWKS/)) {
      // Workaround for AWS API Gateway bug that cached an old version
      // of the JWKS document and complains about the JWT kid being invalid.
      // AWS Ticket ID: 7688913841, Account ID: 849080500167
      return request<T>(input, args);
    }
  }
  const text = await res.text()
  try {
    const body = text.length ? JSON.parse(text) : text
    return { response: res, body: body, error: null }
  } catch (err) {
    return { response: res, body: null, error: err }
  }
}

export async function requestFile(input: string): Promise<[Response, any]> {
  const res = await fetch(input, {
    method: 'GET',
    mode: 'cors',
    credentials: 'same-origin',
    redirect: 'follow',
    headers: {
      'content-type': 'application/json',
    },
  })

  const text = await res.text()
  return [res, text]
}

export function useQuery<T>(
  method: Method | null,
  path: string,
  body?: Body | string,
  headers?: { [key: string]: string }
) {
  const [response, setResponse] = useState<T | null>(null)
  const [status, setStatus] = useState<QueryStatus | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = useCallback(() => {
    setStatus(null)
    if (method === null) {
      setResponse(null)
      setError(null)
      return
    }
    setStatus('loading')
    request<T>(path, { method, body, headers })
      .then(
        ({ response: res, body, error: err }) => {
          if (res.ok) {
            setResponse(body)
            setStatus('success')
          } else {
            setError(err)
            setStatus('error')
          }
        },
        (err) => {
          setError(err)
          setStatus('error')
        }
      )
      .catch((e) => {
        console.error(e)
      })
  }, [method, path, body, headers])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { status, error, response, revalidate: fetchData }
}
