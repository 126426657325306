import React, { useEffect, useState } from 'react'
import { Dropdown } from '@liquid-design/liquid-design-react'

import '../style.scss'

export interface SurveyIntroResult {
  roleOption: { id: string; name: string }
  fieldOption: { id: string; name: string }
  goalOption: { id: string; name: string }
}

const roleOptions = [
  { id: 'role-undefined', name: 'your role' },
  { id: 'role-staff-scientist', name: 'Staff Scientist' },
  { id: 'role-information-specialist', name: 'Information Specialist' },
  { id: 'role-it-specialist', name: 'IT Specialist' },
  { id: 'role-lab-technician', name: 'Lab Technician' },
  { id: 'role-researcher', name: 'Researcher' },
  { id: 'role-principal-investigator', name: 'Principal Investigator' },
  { id: 'role-department-head', name: 'Department Head' },
  { id: 'role-lab-manager', name: 'Lab Manager' },
  { id: 'role-project-manager', name: 'Project Manager' },
  { id: 'role-legal-counsel', name: 'Legal Counsel' },
  { id: 'role-patent-attorney', name: 'Patent Attorney' },
  { id: 'role-professor', name: 'Professor' },
  { id: 'role-post-doc', name: 'Post-Doc' },
  { id: 'role-student', name: 'Student' },
  { id: 'role-other', name: 'Other' },
]
const fieldOptions = [
  { id: 'field-undefined', name: 'your field' },
  {
    id: 'field-sample-preperation',
    name: 'Sample preparation',
  },
  { id: 'field-biotech', name: 'Biotech' },
  { id: 'field-chemistry', name: 'Chemistry' },
  { id: 'field-clinical-and-diagnostics', name: 'Clinical & diagnostics' },
  {
    id: 'field-industrial-and-applied-science',
    name: 'Industrial & Applied Science',
  },
  { id: 'field-intellectual-property', name: 'Intellectual Property' },
  { id: 'field-life-science-research', name: 'Life science research' },
  { id: 'field-material-science', name: 'Material Science' },
  { id: 'field-micro-biology', name: 'Microbiology' },
  { id: 'field-pharma', name: 'Pharma' },
  { id: 'field-other', name: 'Other' },
]
const goalOptions = [
  { id: 'goal-undefined', name: 'your goal' },
  { id: 'goal-discover-innovation-fields', name: 'discover innovation fields' },
  {
    id: 'goal-discover-new-chemical-structures',
    name: 'discover new chemical structures in catalogs',
  },
  { id: 'goal-generate-insights', name: 'generate insights from data' },
  { id: 'goal-determine-patentability', name: 'determine patentability' },
  { id: 'goal-other', name: 'other' },
]

interface Props {
  onChange: (update: SurveyIntroResult) => void
  className?: string
}

const SurveyIntro: React.FC<Props> = ({ onChange, className }) => {
  const [results, setResults] = useState<SurveyIntroResult>({
    fieldOption: fieldOptions[0],
    goalOption: goalOptions[0],
    roleOption: roleOptions[0],
  })

  useEffect(() => {
    onChange(results)
  }, [results, onChange])

  function handleUpdateResults(update: SurveyIntroResult) {
    setResults({ ...update })
  }

  return (
    <div className={`survey__intro text-base font-extrabold ${className}`}>
      <span className="mr-1">I am a </span>
      <Dropdown
        options={roleOptions}
        value={results.roleOption.id}
        onSubmit={(value: { id: string; name: string }) => {
          const roleOptionResult = roleOptions.find((x) => x.id === value.id)
          if (roleOptionResult) {
            results.roleOption = roleOptionResult
            handleUpdateResults(results)
          }
        }}
        inline
      ></Dropdown>{' '}
      <span className="mr-1">primarily focusing on </span>
      <Dropdown
        options={fieldOptions.map((x) => {
          return { id: x.id, name: `${x.name}.` }
        })}
        value={results.fieldOption.id}
        onSubmit={(value: { id: string; name: string }) => {
          const fieldOptionResult = fieldOptions.find((x) => x.id === value.id)
          if (fieldOptionResult) {
            results.fieldOption = fieldOptionResult
            handleUpdateResults(results)
          }
        }}
        inline
      ></Dropdown>{' '}
      <span className="mr-1">I’m using Lewis to </span>
      <Dropdown
        options={goalOptions.map((x) => {
          return { id: x.id, name: `${x.name}.` }
        })}
        value={results.goalOption.id}
        onSubmit={(value: { id: string; name: string }) => {
          const goalOptionResult = goalOptions.find((x) => x.id === value.id)
          if (goalOptionResult) {
            results.goalOption = goalOptionResult
            handleUpdateResults(results)
          }
        }}
        inline
      ></Dropdown>{' '}
      *
      {/* {' '}
      because{' '}
      <Dropdown
        options={reasonOptions}
        value={selectedReason}
        onSubmit={(value: string) => setSelectedReason(value)}
        inline
      ></Dropdown>
      . */}
    </div>
  )
}

export default SurveyIntro
