import React, { useState } from 'react'
import { ExtractionStructure } from '../../typings/app/types'

interface Props {
  structure: ExtractionStructure
  lazy?: boolean
  ref?: React.LegacyRef<HTMLImageElement>
  className?: string
}

const StructureImage: React.FC<Props> = ({ structure, lazy, ref, className }) => {
  const [useFallback, setUseFallback] = useState(false)
  if (useFallback) {
    return (
      <img
        className={className}
        src={useFallback ? structure.imageUrl?.png : ''}
        alt={structure.smiles}
        ref={ref}
        loading={lazy ? 'lazy' : 'eager'}
      ></img>
    )
  }
  return (
    <img
      className={`p-3 ${className}`}
      src={structure.imageUrl?.svg ?? ''}
      alt={structure.smiles}
      onError={() => setUseFallback(true)}
      ref={ref}
      loading={lazy ? 'lazy' : 'eager'}
    ></img>
  )
}

export default StructureImage
