import React, { useState } from 'react'
import { LightBox, TextField, Theme } from '@liquid-design/liquid-design-react'
import { Button, LoaderButton } from '../buttons'

import { useFeedback } from '../../services/feedback'

const modalContent = {
  general: {
    title: 'Feedback',
    subtitle: 'Tell us what you think about Lewis.',
  },
  refill: {
    title: 'Request refill',
    subtitle:
      'You ran out of pages? No problem, we are happy to add some pages to your account. Just shoot us a message.',
  },
}

interface Props {
  open: boolean
  feedbackType: 'general' | 'refill'
  onClose: () => void
  onSubmit: (success: boolean, error?: Error) => void
  className?: string
}

const FeedbackModal: React.FC<Props> = ({
  open,
  feedbackType,
  onClose,
  onSubmit,
  className,
}) => {
  const { generalFeedback } = useFeedback()
  const [text, setText] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const content = modalContent[feedbackType]

  function handleOnClose() {
    onClose()
  }

  return (
    <LightBox
      open={open}
      onClose={handleOnClose}
      style={{ maxWidth: '450px', width: '450px' }}
    >
      <div
        className={`${className || ''}`}
        data-test="database-lightbox-feedback"
      >
        <div className="mb-2 text-3xl font-extrabold">{content.title}</div>
        <div className="mb-6 text-rich-blue-darkest-50">{content.subtitle}</div>
        <Theme themeName="richBlue">
          <div className="flex flex-col">
            <TextField
              multiline
              grey
              value={text}
              placeholder="Please describe the error in more detail."
              onChange={(value: string) => setText(value)}
            ></TextField>
          </div>
        </Theme>
        <div className="flex justify-between">
          <Button
            data-test="database-lightbox-report-error-button-cancel"
            size="big"
            className="w-1/2 mr-2"
            onClick={handleOnClose}
          >
            Cancel
          </Button>
          <LoaderButton
            loading={isLoading}
            data-test="database-lightbox-report-error-button-submit"
            className={`w-1/2 ml-2 text-white bg-rich-blue hover:bg-rich-blue-dark ${
              text && text.length ? '' : 'pointer-events-none opacity-25'
            }`}
            onClick={async () => {
              setIsLoading(true)
              const { success, error } = await generalFeedback(
                text || '',
                feedbackType
              )

              setIsLoading(false)
              onSubmit(success, error)

              if (success) {
                setText(undefined)
              }

              handleOnClose()
            }}
          >
            Submit
          </LoaderButton>
        </div>
      </div>
    </LightBox>
  )
}

export default FeedbackModal
