import { DateTime } from 'luxon'
import { produce } from 'immer'
import create from 'zustand'

import { useUser } from '../context/User'
import { upload } from './upload'
import { fetchJobs, deleteJob } from './jobs'

import {
  FileUpload,
  FileUploadState,
  Extraction,
  ExtractionState,
  ExtractionStructure,
  ExtractionJob,
} from '../typings/app/types'
import {
  fetchStructures,
  jobIdForStructure,
  deleteStructure,
} from './structures'
import { subscribeSocket, unsubscribeSocket } from '../lib/websockets'
import { StreamSocketResponseJson } from '../typings/api/websocket'
import { useInterval } from '../components/hooks/useInterval'
import { debounce } from '../util'

type Store = {
  initialized: boolean
  loading: boolean
  pending: boolean
  extractions: Extraction[]
  next?: string
  webSocketSubscription?: string
  update: (fn: (store: Store) => void) => void
}

const useStore = create<Store>((set) => ({
  initialized: false,
  loading: false,
  pending: false,
  extractions: [],
  next: '',
  update: (fn: any) => set(produce(fn)),
}))

async function _uploadFiles(
  files: File[],
  extractions: Extraction[],
  update: (fn: (store: Store) => void) => void,
  accessToken?: string
) {
  // Add all new uploads to extractions
  update((store) => {
    const e: Extraction[] = [
      ...files.map((x) => {
        return {
          state: ExtractionState.uploading,
          upload: {
            file: x,
            state: FileUploadState.pending,
            progress: 0.0,
          },
        }
      }),
      ...store.extractions,
    ]

    store.extractions = e
  })

  files.forEach(async (file) => {
    // Update upload to reflect 'uploading' state.
    update((store) => {
      const upload = store.extractions.find(
        (x) =>
          x.upload?.file === file && x.upload.state === FileUploadState.pending
      )?.upload

      if (upload) {
        upload.state = FileUploadState.uploading
      }
    })

    const fileUpload = await upload(
      file,
      accessToken || '',
      (fileUploadProgress) => {
        // Update upload to reflect current progress
        update((store) => {
          const upload = store.extractions.find((x) => x.upload?.file === file)
            ?.upload

          if (upload) {
            upload.progress = fileUploadProgress.progress
          }
        })
      }
    )

    if (fileUpload) {
      // Update upload to reflect completed state and update jobId.
      update((store) => {
        const extraction = store.extractions.find(
          (x) => x.upload?.file === file
        )

        if (extraction) {
          if (extraction.upload) {
            extraction.upload.state = FileUploadState.done
            extraction.upload.progress = 1.0
          }

          extraction.jobId = fileUpload.jobId
        }
      })
    }
  })
}

const _debouncedFetchStrucutures = debounce(
  (
    update: (fn: (store: Store) => void) => void,
    jobId: string,
    accessToken?: string
  ) => {
    _fetchStructures(update, jobId, accessToken)
  },
  1000
)

async function _fetchStructures(
  update: (fn: (store: Store) => void) => void,
  jobId: string,
  accessToken?: string
) {
  const {
    response: structures,
    error: structuresError,
  } = await fetchStructures(jobId, accessToken || '')

  if (structuresError) {
    throw structuresError
  }

  if (structures) {
    update((store: Store) => {
      const extraction = store.extractions.find((y) => y.jobId === jobId)

      if (!extraction) {
        return
      }

      extraction.structuresUpdated = DateTime.local().toSeconds()
      extraction.structures = structures
    })
  }
}

async function _fetchExtractions(
  update: (fn: (store: Store) => void) => void,
  accessToken?: string,
  next?: string,
  initial = false
) {
  if (!next && !initial) {
    return
  }

  try {
    update((store) => {
      store.loading = true
      store.pending = true
    })

    const { response, error } = await fetchJobs(accessToken || '', next)

    const jobs = response?.jobs
    update((store: Store) => {
      store.next = response?.next
    })

    if (jobs) {
      update((store: Store) => {
        jobs.forEach((x) => {
          let extraction = store.extractions.find((y) => y.jobId === x.jobId)

          if (!extraction) {
            extraction = {
              jobId: x.jobId,
              state: ExtractionState.undefined,
            }
            store.extractions.push(extraction)
          }

          extraction.job = x
          extraction.state = x.finished
            ? ExtractionState.completed
            : ExtractionState.extracting
        })
      })

      update((store) => {
        store.loading = false
      })

      if (error) {
        throw error
      }

      if (jobs) {
        for await (const job of jobs) {
          await _fetchStructures(update, job.jobId, accessToken)
        }
      }

      update((store) => {
        store.pending = false
      })
    }
  } catch (error) {
    console.error(error)
  }
}

async function _unregisterForUpdates(
  update: (fn: (store: Store) => void) => void
) {
  update((store) => {
    store.webSocketSubscription &&
      unsubscribeSocket(store.webSocketSubscription)
  })
}

async function _registerForUpdates(
  update: (fn: (store: Store) => void) => void,
  accessToken?: string
) {
  try {
    if (accessToken) {
      const subscription = await subscribeSocket<StreamSocketResponseJson>(
        accessToken,
        (message) => {
          console.debug(message)
          update((store) => {
            message.Items.forEach((x) => {
              _debouncedFetchStrucutures(update, x.Item.JobId, accessToken)

              const extraction = store.extractions.find(
                (y) => x.Item.JobId === y.jobId
              )

              if (extraction) {
                extraction.state = x.Item.Finished
                  ? ExtractionState.completed
                  : ExtractionState.extracting
                extraction.job = {
                  ...extraction.job,
                  file: {
                    size: x.Item.File.Size,
                    name: x.Item.File.Name,
                  },
                  pages: {
                    failed: x.Item.Pages.Failed,
                    total: x.Item.Pages.Total,
                    processed: x.Item.Pages.Processed,
                  },
                  structures: {
                    failed: x.Item.Structures.Failed,
                    total: x.Item.Structures.Total,
                    processed: x.Item.Structures.Processed,
                  },
                  finished: x.Item.Finished,
                  jobId: x.Item.JobId,
                  uploadDate: extraction.job?.uploadDate || '',
                }
              }
            })
          })
        }
      )

      update((store) => {
        store.webSocketSubscription = subscription
      })

      return
    }
  } catch (error) {
    console.error(error)
  }
}

async function _deleteStructures(
  extractions: Extraction[],
  structures: ExtractionStructure[],
  includeExtractions: Extraction[],
  errorOcurred: (error: Error | null, message: string) => void,
  update: (fn: (store: Store) => void) => void,
  accessToken?: string
) {
  const jobsToDelete: ExtractionJob[] = []
  const structuresToDelete: ExtractionStructure[] = []

  update((store) => {
    store.extractions.forEach((extraction) =>
      extraction.structures?.forEach((structure) => {
        if (structures.some((x) => structure.internalId === x.internalId)) {
          structure.deletePending = true
        }
      })
    )
  })

  includeExtractions.forEach((x) => {
    if (x.job) {
      jobsToDelete.push(x.job)
    }
  })

  structures.forEach((structure) => {
    const jobId = jobIdForStructure(structure)
    const extraction = extractions.find((x) => x.jobId === jobId)

    if (extraction && extraction.job) {
      const deleteWholeJob = extraction.structures?.every((x) =>
        structures.some((y) => y.internalId === x.internalId)
      )

      if (deleteWholeJob) {
        if (!jobsToDelete.some((x) => x.jobId === jobId)) {
          jobsToDelete.push(extraction.job)
        }
      } else {
        structuresToDelete.push(structure)
      }
    }
  })

  jobsToDelete.forEach((job) => {
    deleteJob(accessToken || '', job.jobId)
      .then(({ response, error }) => {
        if (response) {
          update((store) => {
            store.extractions = store.extractions.filter(
              (x) => x.jobId !== job.jobId
            )
          })
        } else {
          errorOcurred(error, `Job failed to delete: ${job.jobId}`)
        }
      })
      .catch((error) => {
        errorOcurred(error, `Job failed to delete: ${job.jobId}`)
      })
  })

  structuresToDelete.forEach((structure) => {
    const jobId = jobIdForStructure(structure)
    if (!jobId) return

    deleteStructure(accessToken || '', jobId, structure.structureId)
      .then(({ response, error }) => {
        if (response) {
          update((store) => {
            const extraction = store.extractions.find((x) => x.jobId === jobId)

            if (extraction) {
              const deletedStructure = extraction.structures?.find(
                (x) => x.internalId === structure.internalId
              )
              if (deletedStructure) {
                extraction.structures = extraction.structures?.filter(
                  (x) => x !== deletedStructure
                )
              }
            }
          })
        } else {
          errorOcurred(
            error,
            `Structure failed to delete: ${structure.formula} (${structure.internalId})`
          )
        }
      })
      .catch((error) => {
        errorOcurred(
          error,
          `Structure failed to delete: ${structure.formula} (${structure.internalId})`
        )
      })
  })
}

function useExtractions() {
  const user = useUser()

  const { initialized, loading, pending, extractions, next, update } = useStore(
    (state) => state
  )

  useInterval(async () => {
    const needUpdate = extractions.filter(
      (x) =>
        x.structuresUpdated &&
        DateTime.local().toSeconds() > x.structuresUpdated + 60 * 5 // Last Update is older then 5 minutes
    )

    for await (const job of needUpdate) {
      if (!job.jobId) {
        continue
      }

      await _fetchStructures(update, job.jobId, user.session?.accessToken)
    }
  }, 60 * 1000) // Check every minute for required updates

  async function uploadFiles(files: File[]) {
    // Clear old uploads when starting a new one.
    clearUploads()
    await _uploadFiles(files, extractions, update, user.session?.accessToken)
  }

  function clearUploads() {
    update((store) => {
      store.extractions.forEach((x) => {
        if (
          x.upload &&
          x.upload?.state !== FileUploadState.pending &&
          x.upload?.state !== FileUploadState.uploading
        ) {
          x.upload = undefined
        }
      })
    })
  }

  async function fetchExtractions() {
    await _fetchExtractions(update, user.session?.accessToken, next)
  }

  if (!extractions.length && !initialized) {
    update((store) => {
      store.initialized = true
    })
    _fetchExtractions(update, user.session?.accessToken, next, true)
  }

  function activateUpdates() {
    _registerForUpdates(update, user.session?.accessToken)
  }

  function deactivateUpdates() {
    _unregisterForUpdates(update)
  }

  // Deletes Structures. Also provides property `includeExtractions` for these rare cases that a job does not have any
  // structrure at all because of a broken pipeline etc.
  async function deleteStructures(
    structures: ExtractionStructure[],
    includeExtractions: Extraction[],
    errorOcurred: (error: Error | null, message: string) => void
  ) {
    await _deleteStructures(
      extractions,
      structures,
      includeExtractions,
      errorOcurred,
      update,
      user.session?.accessToken
    )
  }

  return {
    loading,
    pending,
    extractions,
    fetchExtractions,
    deleteStructures,
    uploadFiles,
    clearUploads,
    activateUpdates,
    deactivateUpdates,
  }
}

function isExtracting(extraction: Extraction): boolean {
  if (!extraction.job) return false
  if (extraction.job?.finished) return false

  const total = extraction.job.structures.total || 0
  const processed = extraction.job.structures.processed || 0
  const failed = extraction.job.structures.failed || 0

  return total > processed + failed
}

function isUploading(extraction: Extraction): boolean {
  return Boolean(extraction.upload !== null && extraction.upload)
}

function filterUploading(extractions: Extraction[]): Extraction[] {
  return extractions.filter((x) => isUploading(x))
}

function pendingUpoloads(extractions: Extraction[]): FileUpload[] {
  return filterUploading(extractions)
    .map((x) => x.upload)
    .filter<FileUpload>((x): x is FileUpload => x !== null && x !== undefined)
}

function pendingPages(
  extractions: Extraction[]
): { total: number; processed: number; failed: number; pending: number } {
  const extractionsInProgress = extractions.filter(
    (x) => x.state === ExtractionState.extracting
  )

  const total = extractions.reduce(
    (x, y: Extraction) => x + (y.job?.pages.total || 0),
    0
  )
  const processed = extractions.reduce(
    (x, y: Extraction) => x + (y.job?.pages.processed || 0),
    0
  )
  const failed = extractions.reduce(
    (x, y: Extraction) => x + (y.job?.pages.failed || 0),
    0
  )

  const pending = extractionsInProgress.reduce((x, y: Extraction) => {
    if (!y.job) {
      return x
    }

    const totalPages = y.job.pages.total || 0
    const processedPages = y.job.pages.processed || 0
    const failedPages = y.job.pages.failed || 0
    return x + (totalPages - processedPages - failedPages)
  }, 0)

  return { total, processed, failed, pending }
}

function pendingStructures(
  extractions: Extraction[]
): { total: number; processed: number; failed: number; pending: number } {
  const extractionsInProgress = extractions
  const total = extractionsInProgress.reduce(
    (x, y: Extraction) => x + (y.job?.structures.total || 0),
    0
  )
  const processed = extractionsInProgress.reduce(
    (x, y: Extraction) => x + (y.job?.structures.processed || 0),
    0
  )
  const failed = extractionsInProgress.reduce(
    (x, y: Extraction) => x + (y.job?.structures.failed || 0),
    0
  )

  return { total, processed, failed, pending: total - processed - failed }
}

function filterInProgress(extractions: Extraction[]): Extraction[] {
  return extractions.filter((x) => {
    return isExtracting(x)
  })
}

export {
  useExtractions,
  isExtracting,
  isUploading,
  filterUploading,
  filterInProgress,
  pendingUpoloads,
  pendingPages,
  pendingStructures,
}
