import React from 'react'

interface Props {
  children?: React.ReactNode
  className?: string
}

const Card: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <div
      {...props}
      className={`flex flex-wrap flex-col items-center justify-center max-w-full card
       p-12 bg-white rounded-md ${className}`}
      style={{ width: '36rem' }}
    >
      {children}
    </div>
  )
}

export default Card
