// Make sure this is synced with '../assets/styles/variables/_breakpoints'

export enum Breakpoint {
  zero = 1,
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  xxl = 2048,
}
