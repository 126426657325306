import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import Auth from './Auth/Auth'
import Logout from './Auth/Logout'
import Database from './Database/Database'
import Error from './Error/Error'
import Home from './Home/Home'
import Upload from './Upload/Upload'
import Imprint from './Legal/Imprint'
import Terms from './Legal/Terms'
import Privacy from './Legal/Privacy'
import ServiceAgreement from './Legal/ServiceAgreement'

import { useUser } from '../context/User'

import './reset.scss'
import './lds.scss'
import { SurveyContextProvider } from '../context/Survey'

function RequireLogin({
  children,
}: {
  children: React.ReactNode
}): JSX.Element | null {
  const { login, session } = useUser()

  if (!session && login) {
    login()
    return null
  }

  return <>{children}</>
}

const Pages: React.FC = () => {
  const { pathname, hash } = useLocation()

  useEffect(() => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 0)
    }
  }, [pathname, hash])

  return (
    <>
      <Switch>
        <Route exact path="/auth">
          <Auth />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>
        <Route exact path="/upload">
          <RequireLogin>
            <SurveyContextProvider>
              <Upload />
            </SurveyContextProvider>
          </RequireLogin>
        </Route>
        <Route exact path="/database">
          <RequireLogin>
            <SurveyContextProvider>
              <Database />
            </SurveyContextProvider>
          </RequireLogin>
        </Route>
        <Route exact path="/imprint">
          <Imprint />
        </Route>
        <Route exact path="/terms">
          <Terms />
        </Route>
        <Route exact path="/privacy">
          <Privacy />
        </Route>
        <Route exact path="/serviceagreement">
          <ServiceAgreement />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route>
          <Error />
        </Route>
      </Switch>
    </>
  )
}

export default Pages
