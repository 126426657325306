import React from 'react'
import HomeLayout from '../../layouts/HomeLayout'
import { Block, Headline, Link as InlineLink } from './components'

interface Props {
  className?: string
}

const ServiceAgreement: React.FC<Props> = ({ className }) => {
  return (
    <HomeLayout>
      <div
        className="flex flex-col px-4 pb-32 text-sm leading-6"
        style={{
          maxWidth: '32rem',
        }}
      >
        <Headline>Lewis Service Agreement</Headline>
        <Block id="intro">
          Thank you for using Lewis. The following terms and conditions,
          together with any documents they expressly incorporate by reference,
          govern your access to and use of Lewis service during Beta testing of
          the software.
        </Block>
        <Block id="acceptableusage" title="Acceptable Usages">
          <ul className="pl-5 list-disc list-outsinde">
            <li>Use Lewis free of charge during the Beta testing of Lewis.</li>
            <li>
              Use Lewis pursuant to the{' '}
              <InlineLink to="/terms">Website Terms of Use</InlineLink> to
              upload up to 100 pages/week for free.
            </li>
            <li>
              Use Lewis service output, i.e., machine-readable chemical
              structure that can be exported in different formats, for any
              purposes with the exclusion of purposes outlined in section below{' '}
              <InlineLink to="#prohibitedusages">Prohibited Usages</InlineLink>{' '}
              and if the usage complies with all applicable federal, state,
              local, and international laws and regulations.
            </li>
            <li>
              Make technical support and feature requests via email{' '}
              <InlineLink to="mailto:support@lewisapp.com">
                support@lewisapp.com
              </InlineLink>
            </li>
            <li>
              Contact Lewis team via{' '}
              <InlineLink to="mailto:support@lewisapp.com">
                support@lewisapp.com
              </InlineLink>{' '}
              to request an additional 100 pages for free if the weekly limit is
              not enough.
            </li>
          </ul>
        </Block>
        <Block id="prohibitedusages" title="Prohibited Usages">
          <ul className="pl-5 list-disc list-outsinde">
            <li>
              Distribute, redistribute, license, sublicense, rent, lease, loan,
              assign, provide hosting services or access to, copy, sell or
              otherwise transfer Lewis or its components.
            </li>
            <li>
              Cause or authorize to modify, adapt, alter, translate, reverse
              engineer, decompile, disassemble, or otherwise attempt to
              discover, derive the code for or create derivative works of any
              portion of the source code of Lewis.
            </li>
            <li>
              Share, transfer or sell Lewis user account access credentials.
            </li>
          </ul>
        </Block>
        <Block id="servicequality" title="Service Quality">
          <ul className="pl-5 list-disc list-outsinde">
            <li>
              No guarantee any uploaded document type is compatible with the
              Lewis tool.
            </li>
            <li>No guarantee as to the accuracy of the service output.</li>
            <li>
              No guarantee with respect to the confidentiality of uploaded
              documents and no obligation on the part of EMD Digital Inc. to
              maintain the confidentiality of any uploaded documents.
            </li>
            <li>
              No guarantee that the service will be available at any particular
              time or location, will function in an uninterrupted manner or be
              secure or error-free.
            </li>
            <li>
              See{' '}
              <InlineLink to="/terms#disclaimerofwarranties">
                Disclaimer of Warranties
              </InlineLink>{' '}
              section of the{' '}
              <InlineLink to="/terms">Website Terms of Use</InlineLink>
            </li>
          </ul>
        </Block>
        <Block id="regionaccessibility" title="Region Accessibility">
          The Lewis Beta is intended for users residing in the United States or
          any of its territories or possessions. For more information see{' '}
          <InlineLink to="/terms#geographicrestrictions">
            Geographic Restrictions
          </InlineLink>{' '}
          section of the{' '}
          <InlineLink to="/terms">Website Terms of Use</InlineLink>. More
          regions coming soon.
          <br />
          <br />
          By using Lewis, you hereby agree that such use is subject to the terms
          set forth above as well as{' '}
          <InlineLink to="/terms">Website Terms of Use</InlineLink>.
        </Block>
      </div>
    </HomeLayout>
  )
}

export default ServiceAgreement
