import React from 'react'
import { ReactComponent as CrossIcon } from '../../../assets/static/images/icon-cross-small-no-color.svg'

interface Props {
  onClick: () => void
  className?: string
}

const SurveyCloseButton: React.FC<Props> = ({
  onClick,
  className = '',
  ...props
}) => {
  return (
    <button
      {...props}
      onClick={onClick}
      className={`flex items-center justify-center w-6 h-6 m-4 rounded bg-sensitive-grey-darkest hover:text-rich-blue-darker ${className}`}
    >
      <CrossIcon className="transform stroke-current"></CrossIcon>
    </button>
  )
}

export default SurveyCloseButton
