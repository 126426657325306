import React, { useRef, useState, useEffect } from 'react'

import { Extraction, ExtractionStructure } from '../../../../typings/app/types'

import { useScrollPosition } from '../../../../components/hooks'

import { ExtractionGridItemContainer } from './ExtractionGridItemContainer'

interface Props {
  extractions: Extraction[]
  selectedStructures: ExtractionStructure[]
  selectedIncompleteExtractions: Extraction[]
  selectionChanged: (strucutres: ExtractionStructure[]) => void
  incompleteExtractionSelectionChanged: (extractions: Extraction[]) => void
  onStructureClick: (structure: ExtractionStructure, fileName: string) => void
  requestMoreExtractions: () => void
  searchTerm?: string
  className?: string
  containerRef?: React.RefObject<HTMLElement>
}

const ExtractionGrid: React.FC<Props> = ({
  extractions,
  selectedStructures,
  selectedIncompleteExtractions,
  selectionChanged,
  incompleteExtractionSelectionChanged,
  onStructureClick,
  requestMoreExtractions,
  searchTerm,
  className = '',
  containerRef,
}) => {
  const initialNumberOfStructures = 6

  const scrollRef = useRef(null)
  const [scrollOffset, setScrollOffset] = useState(0)

  const { scrollPosition, scrollRatio } = useScrollPosition(
    scrollRef,
    false,
    100,
    containerRef
  )

  useEffect(() => {
    setScrollOffset(scrollPosition.y)
  }, [scrollPosition])

  // Ignoring requestMoreExtraction as dependency as the effect must only call if the scroll values changed.
  useEffect(() => {
    if (scrollRatio.vertical > 0.9) {
      requestMoreExtractions()
    }
  }, [scrollRatio]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="relative w-full" ref={scrollRef}>
      <div
        className={`sticky top-0 left-0 z-10 w-full h-12 -mx-20 -mb-12 transition-opacity shadow-lg ease-out duration-300 ${
          scrollOffset > 0 ? 'opacity-100' : 'opacity-0'
        }`}
        style={{
          clipPath: 'polygon(-50% 100%, 150% 100%, 150% 300%, -50% 300%)',
        }}
      ></div>
      <div className={`flex flex-col w-full ${className}`}>
        <div data-test="database-grid-container">
          {extractions.map((extraction: Extraction) => (
            <ExtractionGridItemContainer
              key={extraction.jobId}
              className="w-full overflow-anchor-none"
              extraction={extraction}
              selectedStructures={selectedStructures}
              selectedIncompleteExtraction={selectedIncompleteExtractions.includes(
                extraction
              )}
              selectionChanged={selectionChanged}
              incompleteExtractionSelectionChanged={(selected: boolean) => {
                if (selected) {
                  incompleteExtractionSelectionChanged([
                    ...selectedIncompleteExtractions,
                    extraction,
                  ])
                } else {
                  incompleteExtractionSelectionChanged([
                    ...selectedIncompleteExtractions.filter(
                      (x) => x.jobId !== extraction.jobId
                    ),
                  ])
                }
              }}
              onStructureClick={onStructureClick}
              initialNumberOfStructures={initialNumberOfStructures}
              searchTerm={searchTerm}
            ></ExtractionGridItemContainer>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ExtractionGrid
